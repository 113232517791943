import { PartnerApiRepository } from "@/app/infrastructures/repositories/api/PartnerApiRepository";
import { injectable } from "tsyringe";
import { AddPartnerRequestInterface } from "@/data/payload/contracts/PartnerRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  PartnerDetail,
  ConsolidatorByCity,
  PartnerBank,
  ClientNamePOS
} from "@/domain/entities/Partner";
import { PartnerRequestInterface } from "@/data/payload/contracts/PartnerRequest";
import { ClientPartnerOptions } from "@/domain/entities/CnManifest";

@injectable()
export class PartnerPresenter {
  private repository: PartnerApiRepository;

  constructor(repository: PartnerApiRepository) {
    this.repository = repository;
  }

  public getPartnerDetail(id: number, tieringLevel: boolean): Promise<PartnerDetail> {
    return this.repository.getPartnerDetail(id, tieringLevel);
  }

  public addPartner(
    type: string,
    version: string,
    payload: AddPartnerRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.addPartner(type, version, payload);
  }

  public getConsolidatorsByCity(
    search: string,
    page: number,
    limit: number,
    cityCode: string,
    type: string,
    status: string
  ): Promise<ConsolidatorByCity> {
    return this.repository.getConsolidatorsByCity(
      search,
      page,
      limit,
      cityCode,
      type,
      status
    );
  }

  public getListPartnerBank(): Promise<PartnerBank[]> {
    return this.repository.getListPartnerBank();
  }

  public patchPartner(
    request: PartnerRequestInterface,
    type: string,
    version: string
  ): Promise<ResponsePayload> {
    return this.repository.patchPartner(request, type, version);
  }

  public getClientNamePOS(
    search: string,
    page: number,
    limit: number,
    isBranch: boolean
  ): Promise<ClientNamePOS> {
    return this.repository.getClientNamePOS(search, page, limit, isBranch);
  }

  public freezeUnFreezePartner(
    request: PartnerRequestInterface,
    id: number
  ): Promise<ResponsePayload> {
    return this.repository.freezeUnFreezePartner(request, id);
  }

  public getListPartner(
    page: number,
    limit: number,
    search: string,
    type?: string
  ): Promise<ClientPartnerOptions[]> {
    return this.repository.getListPartner(page, limit, search, type);
  }

  public fetchPartnerApi(params: any): Promise<ClientPartnerOptions[]> {
    return this.repository.fetchPartnerApi(params);
  }

  public checkReferralCode(referralCode: string): Promise<ResponsePayload> {
    return this.repository.checkReferralCode(referralCode);
  }
}
