import { ClientPartnerOptions } from "./CnManifest";

export class BankDetail {
  bankId = "";
  bankName = "";
  bankAccount = "";
  bankAccountName = "";
  constructor(fields?: Partial<BankDetail>) {
    Object.assign(this, fields);
  }
}

export class CourierManagementDetail {
  courierId = 0;
  fullname = "";
  phoneNumber = "";
  partnerType = "";
  announcementCourierType = "";
  courierType = "";
  vehicleType = "";
  vehiclePlate = "";
  partner = new ClientPartnerOptions()
  courierStatus = "";
  bank = new BankDetail();
  constructor(fields?: Partial<CourierManagementDetail>) {
    Object.assign(this, fields);
  }
}
