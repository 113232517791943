/* eslint-disable @typescript-eslint/camelcase */
import { Pagination } from "./Pagination";

export class STTPiecesDetail {
  sttNumber: string;
  sttNumberPieces: string;
  destinationCity: string;
  productType: string;
  recentStatus: string;
  pickupPoint: string;
  woodPacking: string;
  commodity: string;
  cod: number;
  piecesNumber: string;
  grossWeight: number;
  volumeWeight: number;
  sttElexysNumber?: string;
  constructor(
    sttNumber: string,
    sttNumberPieces: string,
    destinationCity: string,
    productType: string,
    recentStatus: string,
    pickupPoint: string,
    woodPacking: string,
    commodity: string,
    cod: number,
    piecesNumber: string,
    grossWeight: number,
    volumeWeight: number,
    sttElexysNumber?: string
  ) {
    this.sttNumber = sttNumber;
    this.sttNumberPieces = sttNumberPieces;
    this.destinationCity = destinationCity;
    this.recentStatus = recentStatus;
    this.productType = productType;
    this.pickupPoint = pickupPoint;
    this.woodPacking = woodPacking;
    this.commodity = commodity;
    this.cod = cod;
    this.piecesNumber = piecesNumber;
    this.grossWeight = grossWeight;
    this.volumeWeight = volumeWeight;
    this.sttElexysNumber = sttElexysNumber;
  }
}

export class STTToBePickup {
  sttNumber: string;
  grossWeight: number;
  volumeWeight: number;
  totalPieces: number;
  originId: string;
  destinationId: string;
  sttPieces: STTPiecesDetail[];
  sttElexysNumber?: string;

  constructor(
    sttNumber: string,
    grossWeight: number,
    volumeWeight: number,
    totalPieces: number,
    originId: string,
    destinationId: string,
    sttPieces: STTPiecesDetail[],
    sttElexysNumber?: string
  ) {
    this.sttNumber = sttNumber;
    this.grossWeight = grossWeight;
    this.volumeWeight = volumeWeight;
    this.totalPieces = totalPieces;
    this.sttPieces = sttPieces;
    this.originId = originId;
    this.destinationId = destinationId;
    this.sttElexysNumber = sttElexysNumber;
  }
}

export class STIEntities {
  pagination: Pagination;
  data: STIData[];

  constructor(pagination: Pagination, data: STIData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class STIData {
  stiId: number;
  stiPartnerId: number;
  stiPartnerCode: string;
  stiPartnerName: string;
  stiTotalStt: number;
  stiTotalGrossWeight: number;
  stiTotalVolumeWeight: number;
  stiTotalPieces: number;
  stiCreatedAt: string;
  stiCreatedBy: number;
  stiCreatedName: string;
  stiUpdatedAt: string;
  constructor(
    stiId: number,
    stiPartnerId: number,
    stiPartnerCode: string,
    stiPartnerName: string,
    stiTotalStt: number,
    stiTotalGrossWeight: number,
    stiTotalVolumeWeight: number,
    stiTotalPieces: number,
    stiCreatedAt: string,
    stiCreatedBy: number,
    stiCreatedName: string,
    stiUpdatedAt: string
  ) {
    this.stiId = stiId;
    this.stiPartnerId = stiPartnerId;
    this.stiPartnerCode = stiPartnerCode;
    this.stiPartnerName = stiPartnerName;
    this.stiTotalStt = stiTotalStt;
    this.stiTotalGrossWeight = stiTotalGrossWeight;
    this.stiTotalVolumeWeight = stiTotalVolumeWeight;
    this.stiTotalPieces = stiTotalPieces;
    this.stiCreatedAt = stiCreatedAt;
    this.stiCreatedBy = stiCreatedBy;
    this.stiCreatedName = stiCreatedName;
    this.stiUpdatedAt = stiUpdatedAt;
  }
}

export class DetailSttPiecesSTI {
  totalStt = 0;
  totalPieces = 0;
  totalSuccess = 0;
  totalFailed = 0;
  stiResult = "";
  stts = [] as DetailStt[];

  constructor(fields?: Partial<DetailSttPiecesSTI>) {
    Object.assign(this, fields);
  }
}

export class DetailStt {
  isAllowUpdateStatus = false;
  isPaid = true;
  errorMessage = "";
  sttId = 0;
  sttNo = "";
  sttProductType = "";
  sttTotalPiece = 0;
  sttDestinationCityId = "";
  sttDestinationCityName = "";
  sttOriginCityId = "";
  sttOriginCityName = "";
  sttWoodPacking = false;
  sttCommodityCode = "";
  sttCommodityName = "";
  sttGrossWeight = 0;
  sttVolumeWeight = 0;
  sttChargeableWeight = 0;
  sttLastStatusId = "";
  sttPaymentStatus = "";
  isSti = false;
  isStiDest = false;
  piece = [] as DetailPieces[];
  sttDestinationDistrictUrsacode = "";
  packageNumber = null;
  sttDestinationRegionName = "";
  sttOriginRegionName = "";
  sttNoRef = "";

  constructor(fields?: Partial<DetailStt>) {
    Object.assign(this, fields);
  }
}
export class DetailPieces {
  sttPieceId = 0;
  sttPieceGrossWeight = 0;
  sttPieceVolumeWeight = 0;
  sttPieceNo = 0;
  sttPieceLastStatusId = "";

  constructor(fields?: Partial<DetailPieces>) {
    Object.assign(this, fields);
  }
}

export class StiManifestData {
  stiId = 0;
  consolidatorName = "";
  originConsolidator = "";
  stiDate = "";
  totalStt = 0;
  totalPiece = 0;
  totalGrossWeight = 0;
  totalVolumeWeight = 0;
  createdName = "";
  stt = [] as StiManifestStt[];
  constructor(fields?: Partial<StiManifestData>) {
    Object.assign(this, fields);
  }
}

export class StiManifestStt {
  sttId = 0;
  sttNo = "";
  sttProductType = "";
  sttTotalPiece = 0;
  piece = [] as StiManifestPiece[];
  stiOriginCity = "";
  stiDestCity = "";
  stiOriginRegion = "";
  stiDestRegion = "";
  stiGrossWeight = 0;
  stiVolumeWeight = 0;
  constructor(fields?: Partial<StiManifestStt>) {
    Object.assign(this, fields);
  }
}

export class StiManifestPiece {
  sttPieceId = 0;
  sttPieceNo = 0;
  sttPieceGrossWeight = 0;
  sttPieceVolumeWeight = 0;
  constructor(fields?: Partial<StiManifestPiece>) {
    Object.assign(this, fields);
  }
}
