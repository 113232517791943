import { ClientMapper } from "@/data/persistences/mappers/ClientMapper";
import { ClientRepositoryInterface } from "@/data/persistences/repositories/contracts/ClientRepositoryInterface";
import {
  Actor,
  ClientEntities,
  ClientMappingList,
  ClientParentData,
  ClientPartnerData,
  RequestApiClientMappingList
} from "@/domain/entities/Client";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { EditClientManagementRequestInterface } from "@/data/payload/contracts/ClientManagementRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

export class ClientApiRepository implements ClientRepositoryInterface {
  private service: ApiService;
  private mapper: ClientMapper;
  private endpoints: Endpoints;

  constructor(service: ApiService, mapper: ClientMapper, endpoints: Endpoints) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getList(
    page: number,
    limit: number,
    search: string,
    status: string,
    clientParentId?: number
  ): Promise<ClientEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getClient(page, limit, search, status, clientParentId),
      {}
    );
    return this.mapper.convertClientFromApi(resp);
  }

  public async getListClientPartner(
    page: number,
    limit: number,
    search: string,
    partnerType: string,
    type?: string,
    isBranch?: boolean,
    isParent?: boolean,
    orderBy?: string,
    sortBy?: string
  ): Promise<ClientPartnerData[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListClientPartner(
        page,
        limit,
        search,
        partnerType,
        type,
        isBranch,
        isParent,
        orderBy,
        sortBy
      ),
      {}
    );
    return this.mapper.convertClientPartnerFromApi(resp);
  }

  public async getClientParentList(): Promise<ClientParentData[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListClientParent(),
      {}
    );
    return this.mapper.convertClientParentDataFromApi(resp);
  }

  public async getClientMappingList(
    params: RequestApiClientMappingList
  ): Promise<ClientMappingList[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListClientMapping(params),
      {}
    );
    return this.mapper.convertClientMappingFromApi(resp);
  }

  public async getListOfActor(search: string): Promise<Actor[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListOfActors(search),
      {}
    );
    return this.mapper.convertActorsFromApi(resp);
  }

  public async retryUploadClientRate(
    payload: EditClientManagementRequestInterface
  ): Promise<ResponsePayload> {
    const response = await this.service.invoke(
      "post",
      this.endpoints.retryUploadClientRate(),
      undefined,
      payload
    );
    return this.mapper.convertChangeDataFromApi(response);
  }
}
