import { PartnerMapper } from "@/data/persistences/mappers/PartnerMapper";
import { PartnerRepositoryInterface } from "@/data/persistences/repositories/contracts/PartnerRepositoryInterface";
import {
  PartnerDetail,
  ConsolidatorByCity,
  PartnerBank,
  ClientNamePOS
} from "@/domain/entities/Partner";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  UpdatePartnerApiRequest,
  FreezeUnFreezePartnerApiRequest
} from "@/data/payload/api/PartnerApiRequest";
import { PartnerRequestInterface } from "@/data/payload/contracts/PartnerRequest";
import { AddPartnerRequestInterface } from "@/data/payload/contracts/PartnerRequest";
import { AddPartnerRequest } from "@/data/payload/api/PartnerRequest";
import { ClientPartnerOptions } from "@/domain/entities/CnManifest";

export class PartnerApiRepository implements PartnerRepositoryInterface {
  private service: ApiService;
  private mapper: PartnerMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: PartnerMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async addPartner(
    type: string,
    version: string,
    payload: AddPartnerRequestInterface
  ): Promise<ResponsePayload> {
    const response = await this.service.invoke(
      "post",
      this.endpoints.addPartner(type, version),
      {},
      payload as AddPartnerRequest
    );
    return this.mapper.convertPayloadData(response);
  }

  public async getPartnerDetail(id: number, tieringLevel: boolean): Promise<PartnerDetail> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getPartnerDetail(id, tieringLevel),
      {}
    );
    return this.mapper.convertPartnerDetailFromApi(resp);
  }

  public async getConsolidatorsByCity(
    search: string,
    page: number,
    limit: number,
    cityCode: string,
    type: string,
    status: string
  ): Promise<ConsolidatorByCity> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getConsolidatorsByCity(
        search,
        page,
        limit,
        cityCode,
        type,
        status
      ),
      {}
    );

    return this.mapper.convertConsolidatorByCityFromApi(resp);
  }

  public async getListPartnerBank(): Promise<PartnerBank[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListPartnerBank(),
      {}
    );
    return this.mapper.convertListPartnerBankFromApi(resp);
  }

  public async getListPartner(
    page: number,
    limit: number,
    search: string,
    type?: string
  ): Promise<ClientPartnerOptions[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getPartnerOptions(page, limit, search, type),
      {}
    );
    return this.mapper.convertPartnerOptionsFromApi(resp);
  }

  public async patchPartner(
    request: PartnerRequestInterface,
    type: string,
    version: string
  ): Promise<ResponsePayload> {
    const response = await this.service.invoke(
      "patch",
      this.endpoints.patchPartner(type, version),
      {},
      request as UpdatePartnerApiRequest
    );
    return this.mapper.convertChangeDataFromApi(response);
  }

  public async getClientNamePOS(
    search: string,
    page: number,
    limit: number,
    isBranch: boolean
  ): Promise<ClientNamePOS> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getClientNamePOS(search, page, limit, isBranch),
      {}
    );

    return this.mapper.convertClientNamePOSFromApi(resp);
  }

  public async freezeUnFreezePartner(
    request: PartnerRequestInterface,
    id: number
  ): Promise<ResponsePayload> {
    const response = await this.service.invoke(
      "patch",
      this.endpoints.freezeUnFreezePartner(id),
      {},
      request as FreezeUnFreezePartnerApiRequest
    );
    return this.mapper.convertChangeDataFromApi(response);
  }

  public async fetchPartnerApi(params: any): Promise<ClientPartnerOptions[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.partnerApi(params),
      {}
    );
    return this.mapper.convertPartnerOptionsFromApi(resp);
  }

  public async checkReferralCode(
    referralCode: string
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.checkReferralCode(referralCode),
      {}
    );
    return this.mapper.convertPayloadData(resp);
  }
}
