/* eslint-disable @typescript-eslint/camelcase */
import {
  ClientManagementEntities,
  DetailClientManagement
} from "@/domain/entities/ClientManagement";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import { ClientManagementPresenter } from "../presenters/ClientManagementPresenter";
import { container } from "tsyringe";
import {
  AddRenewRatesRequest,
  EditClientCurrentRatePeriodeRequest,
  EditRenewRatesClientManagementApiRequest,
  ApproveRenewRatesRequest,
  RejectRenewRatesRequest,
  FreezeUnfreezeClient,
  RequestUpdateCurrentRates
} from "@/data/payload/api/ClientManagementApiRequest";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { BalanceController } from "@/app/ui/controllers/BalanceController";

export interface ClientManagementState {
  isLoading: boolean;
  isLoadingDetail: boolean;
  clientManagementData: ClientManagementEntities;
  detailClientManagement: DetailClientManagement;
  isError: boolean;
  isErrorEdit: boolean;
  isErrorCause: string;
  openModal: boolean;
  openModalSuccess: boolean;
}
@Module({ namespaced: true, dynamic: true, store, name: "client_management" })
class ClientManagementStore extends VuexModule
  implements ClientManagementState {
  public isLoading = false;
  public isLoadingDetail = false;
  public clientManagementData = new ClientManagementEntities(
    new Pagination(1, 10),
    []
  );
  public detailClientManagement = new DetailClientManagement();
  public cacheClientManagementDetail = new DetailClientManagement();
  public search = "";
  public searchCode = "";
  public filterStatusRates = "";
  public filterStatusRenewal = "";
  public filterType = "";
  public isError = false;
  public isErrorRenewRates = false;
  public isErrorEdit = false;
  public isErrorCause = "";
  public openModal = false;
  public openModalSuccess = false;
  public firstRequest = true;
  public openSuccess = false;
  public errorCurrentRate = false;
  public successCurrentRate = false;

  @Action
  public _onGetList(params: {
    page: number;
    limit: number;
    search: string;
    searchCode: string;
    statusRates: string;
    statusRenewal: string;
    type: string;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(ClientManagementPresenter);
    presenter
      .getListClientManagement(
        params.page,
        params.limit,
        params.search,
        params.searchCode,
        params.statusRates,
        params.statusRenewal,
        params.type
      )
      .then((res: ClientManagementEntities) => {
        this.setClientManagementData({
          pagination: {
            page: res.pagination.page,
            limit: params.limit
          },
          data: res.data
        });
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public getDetail(id: number) {
    this.setLoading(true);
    const presenter = container.resolve(ClientManagementPresenter);
    return presenter
      .getDetailClientManagement(id)
      .then(async (res: DetailClientManagement) => {
        this.setClientManagementDetail(res);
        this.setCacheClientManagementDetail(res);
        this.setCachePeriodeEnd(
          new Date(res.clientCurrentRate.clientRateVersionEndDate)
        );
        this.setPeriodeStart(
          new Date(res.clientCurrentRate.clientRateversionStartDate)
        );
        this.setPeriodeEnd(
          new Date(res.clientCurrentRate.clientRateVersionEndDate)
        );

        // Renewal Rate
        const renewalRate: any = res.clientRenewalRate;
        this.setRenewalRateStart(
          new Date(renewalRate.clientRateversionStartDate || new Date())
        );
        this.setRenewalRateEnd(
          new Date(renewalRate.clientRateVersionEndDate || new Date())
        );
        this.setError(false);
        this.setErrorCause("");

        // get balance
        await this.getBalanceClient(res);

        return true
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
        return false
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public async editCurrentRatePeriode(params: { id: number; endDate: string }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(ClientManagementPresenter);
    return presenter
      .editCurrentRatePeriode(
        new EditClientCurrentRatePeriodeRequest(params.id, params.endDate)
      )
      .then(() => {
        return true;
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Perubahan Gagal !", () =>
            this.editCurrentRatePeriode(params)
          )
        );
        return false;
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public async editRenewRate(params: {
    id: number;
    companyName: string;
    email: string;
    phone: string;
    address: string;
    taxNumber: string;
    versionBasic: string;
    isCustomRate: boolean;
    discount: any;
    priceRateStartDate: string;
    priceRateEndDate: string;
    versionName: string;
    archiveFile: any;
    isUpdateCustom: boolean;
    rateVersionId: string;
    isCustomRateOnly: boolean;
    onCallback: Function;
  }) {
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    const presenter = container.resolve(ClientManagementPresenter);
    return presenter
      .editRenewRate(
        new EditRenewRatesClientManagementApiRequest(
          params.id,
          params.companyName,
          params.email,
          params.phone,
          params.address,
          params.taxNumber,
          params.versionBasic,
          params.isCustomRate,
          params.discount ? parseFloat(params.discount.replace(",", ".")) : 0,
          params.priceRateStartDate,
          params.priceRateEndDate,
          params.versionName,
          params.archiveFile,
          params.isUpdateCustom,
          params.rateVersionId,
          params.isCustomRateOnly
        )
      )
      .then(() => {
        return true;
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Perubahan Gagal !", undefined, () => {
            params.onCallback();
            MainAppController.closeErrorMessage();
          })
        );
        return false;
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Action
  public async updateCurrentRates(params: {
    data: RequestUpdateCurrentRates;
    onCallback: Function;
  }) {
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    const presenter = container.resolve(ClientManagementPresenter);
    return presenter
      .updateCurrentRates(params.data)
      .then(() => true)
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Perubahan Gagal !", undefined, () => {
            params.onCallback();
            MainAppController.closeErrorMessage();
          })
        );
        return false;
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Action
  public async addRenewRates(params: {
    clientId: number;
    versionBasic: any;
    discount: any;
    isCustomRate: boolean;
    versionName: string;
    archiveFile: any;
    startDate: string;
    endDate: string;
    isCustomRateOnly: boolean;
    onCallback: Function;
  }) {
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    const presenter = container.resolve(ClientManagementPresenter);
    return presenter
      .addRenewRates(
        new AddRenewRatesRequest(
          params.clientId,
          params.versionBasic.rateVersionName || "",
          params.discount ? parseFloat(params.discount.replace(",", ".")) : 0,
          params.isCustomRate,
          params.versionName,
          params.archiveFile,
          params.startDate,
          params.endDate,
          params.isCustomRateOnly
        )
      )
      .then(() => {
        return true;
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Pembaruan Gagal !", undefined, () => {
            params.onCallback();
            MainAppController.closeErrorMessage();
          })
        );
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Action
  public fetchClientManagementList() {
    this.setFirstRequest(false);
    this._onGetList({
      search: this.search,
      searchCode: this.searchCode,
      statusRates: this.filterStatusRates,
      statusRenewal: this.filterStatusRenewal,
      type: this.filterType,
      page: this.clientManagementData.pagination.page,
      limit: this.clientManagementData.pagination.limit
    });
  }

  @Action
  public initClientManagement() {
    this.setFirstPage();
    this.setFirstRequest(true);
    this.setFilterStatusRates("");
    this.setFilterStatusRenewal("");
    this.setFilterType("");
    this.setSearch("");
    this._onGetList({
      search: "",
      searchCode: "",
      statusRates: "",
      statusRenewal: "",
      type: "",
      page: this.clientManagementData.pagination.page,
      limit: this.clientManagementData.pagination.limit
    });
  }

  @Action
  public setFirstPage() {
    this.clientManagementData.pagination.page = 1;
  }

  @Action
  public selectStatusRatesAction(params: { name: string; value: string }) {
    this.setFilterStatusRates(params.value);
    this.setFirstPage();
    this.fetchClientManagementList();
  }

  @Action
  public selectStatusRenewalAction(params: { name: string; value: string }) {
    this.setFilterStatusRenewal(params.value);
    this.setFirstPage();
    this.fetchClientManagementList();
  }

  @Action
  public selectTypeAction(params: { name: string; value: string }) {
    this.setFilterType(params.value);
    this.setFirstPage();
    this.fetchClientManagementList();
  }

  @Action
  public searchAction(value: string) {
    this.setSearch(value);
    this.setFirstPage();
    this.fetchClientManagementList();
  }

  @Action
  public clear() {
    this.searchAction("");
  }

  @Mutation
  private setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  private setClientManagementData(data: ClientManagementEntities) {
    this.clientManagementData = data;
  }

  @Mutation
  private setClientManagementDetail(data: DetailClientManagement) {
    this.detailClientManagement = data;
  }

  @Mutation
  private setSearch(value: string) {
    this.search = value;
  }

  @Mutation
  private setFilterStatusRates(value: string) {
    this.filterStatusRates = value;
  }

  @Mutation
  private setFilterStatusRenewal(value: string) {
    this.filterStatusRenewal = value;
  }

  @Mutation
  private setFilterType(value: string) {
    this.filterType = value;
  }

  @Mutation
  private setError(boolean: boolean) {
    this.isError = boolean;
  }

  @Mutation
  private setErrorApprovedRenewRates(boolean: boolean) {
    this.isErrorRenewRates = boolean;
  }

  @Mutation
  public setErrorEdit(boolean: boolean) {
    this.isErrorEdit = boolean;
  }

  @Mutation
  public setErrorCause(str: string) {
    this.isErrorCause = str;
  }

  @Mutation
  public setOpenModal(value: boolean) {
    this.openModal = value;
  }

  @Mutation
  public setOpenModalSuccess(value: boolean) {
    this.openModalSuccess = value;
  }

  @Mutation
  public setFirstRequest(value: boolean) {
    this.firstRequest = value;
  }

  // Date Picker
  public convertPeriodeStart = new Date();
  public convertPeriodeEnd = new Date();
  public cachePeriodeEnd = new Date();

  @Mutation
  public setPeriodeStart(date: any) {
    this.convertPeriodeStart = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  }
  @Mutation
  public setPeriodeEnd(date: any) {
    this.convertPeriodeEnd = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  }

  @Mutation
  public setCachePeriodeEnd(date: any) {
    this.cachePeriodeEnd = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  }

  // Date Picker
  public convertRenewalRateStart = new Date();
  public convertRenewalRateEnd = new Date();

  @Mutation
  public setRenewalRateStart(date: any) {
    if (date) {
      date = new Date(date);
      this.convertRenewalRateStart = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
    }
  }
  @Mutation
  public setRenewalRateEnd(date: any) {
    if (date) {
      date = new Date(date);
      this.convertRenewalRateEnd = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
    }
  }

  @Action
  public handleError() {
    this.setError(false);
  }

  @Mutation
  public setOpenSuccess(value: boolean) {
    this.openSuccess = value;
  }

  @Mutation
  public setErrorCurrentRate(value: boolean) {
    this.errorCurrentRate = value;
  }

  @Mutation
  public setSuccessCurrentRate(value: boolean) {
    this.successCurrentRate = value;
  }

  // Success add renew rates
  succesAddRenewRates = false;
  errorAddRenewRates = false;
  errorDocument = false;
  @Mutation
  public setsuccesAddRenewRates(val: boolean) {
    this.succesAddRenewRates = val;
  }
  @Mutation
  public setErrorAddRenewRates(val: boolean) {
    this.errorAddRenewRates = val;
  }
  @Mutation
  public setErrorDocument(val: boolean) {
    this.errorDocument = val;
  }

  @Action
  public async approveRenewRates(params: { id: number }) {
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    const presenter = container.resolve(ClientManagementPresenter);
    return presenter
      .approveRenewRates(new ApproveRenewRatesRequest(params.id))
      .then(() => {
        return true;
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Pembaruan Gagal")
        );
        return false;
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Action
  public async rejectRenewRates(params: { id: number; rejectReason: string }) {
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    const presenter = container.resolve(ClientManagementPresenter);
    return presenter
      .rejectRenewRates(
        new RejectRenewRatesRequest(params.id, params.rejectReason)
      )
      .then(() => {
        return true;
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Pembaruan Gagal")
        );
        return false;
      })
      .finally(() => MainAppController.closeLoading());
  }
  // Save to cookies detail data and reset
  @Mutation
  public setCacheClientManagementDetail(data: DetailClientManagement) {
    this.cacheClientManagementDetail = data;
  }

  public resetSelectSearch = false;
  @Mutation
  public setResetClientManagementDetail() {
    this.resetSelectSearch = !this.resetSelectSearch;
    const date = new Date();
    this.convertRenewalRateStart = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + 1
    );
    this.convertRenewalRateEnd = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + 2
    );
    this.detailClientManagement = new DetailClientManagement();
  }

  errorVersionBasic = false;
  @Mutation
  setErrorVersionBasic(val: boolean) {
    this.errorVersionBasic = val;
  }

  @Action
  public async fetchClientManagementApi(params: any) {
    const presenter = container.resolve(ClientManagementPresenter);
    return presenter
      .fetchClientManagementApi(params)
      .then((res: ClientManagementEntities) => {
        return res.data;
      })
      .catch(() => {
        return [];
      });
  }

  // balance
  loadingBalance = false;
  @Mutation
  setLoadingBalance(bool: boolean) {
    this.loadingBalance = bool;
  }
  @Action
  public async getBalanceClient(params: DetailClientManagement) {
    this.setLoadingBalance(true);
    const respBalance = await BalanceController.getBalanceClientPrtner({
      type: "client",
      clientID: params.id
    });
    this.setLoadingBalance(false);
    params.clientBalance = respBalance.walletBalance;
    this.setClientManagementDetail(params);
  }

  @Action
  public async freezeUnfreezeClient(params: {
    id: number;
    type: string;
    reason: string;
  }) {
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(ClientManagementPresenter);
    return presenter
      .freezeUnfreezeClient(
        new FreezeUnfreezeClient(params.id, params.reason),
        params.type
      )
      .then(() => {
        return true;
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(parsingErrorResponse(err));
        return false;
      });
  }
}

export const ClientManagementController = getModule(ClientManagementStore);
