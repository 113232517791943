import {
  GenerateStiDestRequest,
  StiDestListRequest
} from "@/data/payload/api/StiDestApiRequest";
import { ChangeStiDestRequestInterface } from "@/data/payload/contracts/StiDestRequest";
import { StiDestMapper } from "@/data/persistences/mappers/StiDestMapper";
import { StiDestRepositoryInterface } from "@/data/persistences/repositories/contracts/StiDestRepositoryInterface";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  StiDestEntities,
  StiDestManifestData,
  StiDestDetail,
  StiDestSttList
} from "@/domain/entities/StiDest";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { downloadFile } from "../../misc/Utils";

export class StiDestApiRepository implements StiDestRepositoryInterface {
  private service: ApiService;
  private mapper: StiDestMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: StiDestMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getListStiDest(
    params: StiDestListRequest
  ): Promise<StiDestEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListStiDest(params),
      {}
    );
    return this.mapper.convertStiDestDataFromApi(resp);
  }

  public async getDetailStiDest(id: number): Promise<StiDestDetail> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailStiDest(id),
      {}
    );
    return this.mapper.convertDetailStiDestDataFromApi(resp);
  }

  public async getStiDestSttList(bagOrStt: string): Promise<StiDestSttList> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getStiDestSttList(bagOrStt),
      {}
    );
    return this.mapper.convertStiDestSttListFromApi(resp);
  }

  public async getStiDestSttListLilo(bagNo: string): Promise<StiDestSttList> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getStiDestSttListLilo(bagNo),
      {}
    );
    return this.mapper.convertStiDestSttListFromApi(resp);
  }

  public async getStiDestManifestDetail(
    id: number
  ): Promise<StiDestManifestData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getStiDestManifestDetail(id),
      {}
    );
    return this.mapper.convertStiDestManifestDataFromApi(resp);
  }
  public async generateStiDest(
    payload: ChangeStiDestRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.updateStiDest(),
      undefined,
      payload as GenerateStiDestRequest
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async downloadStiDest(id: number): Promise<any> {
    return downloadFile(
      `${Endpoints.baseUrl}${this.endpoints.downloadManifest(
        `sti-dest/excel/${id}`
      )}`,
      `STI_Dest_Manifest_${id}`,
      ".xlsx",
      true
    );
  }
}
