import {
  ROUTE_EMBARGO,
  ROUTE_LEG,
  ROUTE_LOCATION_COVERAGE,
  ROUTE_ROUTE_MANAGEMENT,
  ROUTE_TRANSPORT,
  ROUTE_VENDOR,
  BAGGING_GROUPING,
  CARGO_CONFIGURATION,
  SERVICE_DELAY
} from "@/app/infrastructures/misc/RolePermission";
import RouteManagementModules from "./route-management";
import LocationModules from "./location";
import EmbargoModules from "./embargo";
import VendorModules from "./vendor";
import LegModules from "./leg";
import TransportModules from "./transport";
import CargoConfigurationModules from "./cargo-configuration";
import ServiceDelayModules from "./service-delay";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const RouteManagement = () =>
  import(
    /* webpackChunkName: "Route" */ "@/app/ui/views/route/route-management/index.vue"
  );
const Location = () =>
  import(
    /* webpackChunkName: "Location" */ "@/app/ui/views/route/location/index.vue"
  );
const Embargo = () =>
  import(
    /* webpackChunkName: "Embargo" */ "@/app/ui/views/route/embargo/index.vue"
  );
const Vendor = () =>
  import(
    /* webpackChunkName: "Vendor" */ "@/app/ui/views/route/vendor/index.vue"
  );
const Leg = () =>
  import(
    /* webpackChunkName: "Leg" */ "@/app/ui/views/route/leg-management/index.vue"
  );
const Transport = () =>
  import(
    /* webpackChunkName: "Transport" */ "@/app/ui/views/route/transport-management/index.vue"
  );
const BaggingGrouping = () =>
  import(
    /* webpackChunkName: "BaggingGrouping" */ "@/app/ui/views/route/bagging-grouping/index.vue"
  );
const CargoConfiguration = () =>
  import(
    /* webpackChunkName: "CargoConfiguration" */ "@/app/ui/views/route/cargo-configuration/cargo-configuration-list/index.vue"
  );
const ServiceDelay = () =>
  import(
    /* webpackChunkName: "ServiceDelay" */ "@/app/ui/views/route/service-delay/index.vue"
  );

export default [
  {
    path: "/network",
    component: Layout,
    name: "network",
    redirect: "/network/location",
    meta: {
      icon: "route",
      children: true,
      name: "Network",
      divide: true,
      permission: [
        ROUTE_LOCATION_COVERAGE.ENABLE,
        ROUTE_EMBARGO.ENABLE,
        ROUTE_LEG.ENABLE,
        ROUTE_TRANSPORT.ENABLE,
        ROUTE_ROUTE_MANAGEMENT.ENABLE,
        ROUTE_VENDOR.ENABLE,
        CARGO_CONFIGURATION.ENABLE
      ]
    },
    children: [
      {
        path: "embargo",
        name: "embargo",
        component: Embargo,
        meta: {
          header: true,
          icon: "embargo",
          title: "Embargo",
          name: "Embargo",
          permission: ROUTE_EMBARGO.ENABLE
        }
      },
      {
        path: "location",
        name: "location",
        component: Location,
        meta: {
          header: true,
          icon: "location-coverage",
          title: "Location Coverage",
          name: "Location Coverage",
          permission: ROUTE_LOCATION_COVERAGE.ENABLE
        }
      },
      {
        path: "leg-management",
        name: "leg management",
        component: Leg,
        meta: {
          header: true,
          icon: "leg-management",
          title: "Leg Management",
          name: "Leg Management",
          permission: ROUTE_LEG.ENABLE
        }
      },
      {
        path: "route-management",
        name: "route-management",
        component: RouteManagement,
        meta: {
          header: true,
          icon: "route-management",
          title: "Route Management",
          name: "Route Management",
          permission: ROUTE_ROUTE_MANAGEMENT.ENABLE
        }
      },
      {
        path: "vendor",
        name: "vendor",
        component: Vendor,
        meta: {
          header: true,
          icon: "vendor",
          title: "Vendor Management",
          name: "Vendor Management",
          permission: ROUTE_VENDOR.ENABLE
        }
      },
      {
        path: "transport-management",
        name: "transport-management",
        component: Transport,
        meta: {
          header: true,
          icon: "transport",
          title: "Transport Management",
          name: "Transport Management",
          permission: ROUTE_TRANSPORT.ENABLE
        }
      },
      {
        path: "bagging-grouping",
        name: "bagging-grouping",
        component: BaggingGrouping,
        meta: {
          header: true,
          icon: "outgoing-bagging",
          title: "Bagging Grouping",
          name: "Bagging Grouping",
          permission: BAGGING_GROUPING.ENABLE
        }
      },
      {
        path: "cargo-configuration",
        name: "cargo-configuration",
        component: CargoConfiguration,
        meta: {
          header: true,
          icon: "cargo-configuration",
          title: "Kargo Konfigurasi",
          name: "Kargo Konfigurasi",
          permission: CARGO_CONFIGURATION.ENABLE
        }
      },
      {
        path: "service-delay",
        name: "service-delay",
        component: ServiceDelay,
        meta: {
          header: true,
          icon: "home",
          title: "Daftar Area Keterlambatan",
          name: "Area Keterlambatan",
          permission: SERVICE_DELAY.ENABLE
        }
      }
    ]
  },
  ...RouteManagementModules,
  ...LocationModules,
  ...EmbargoModules,
  ...VendorModules,
  ...LegModules,
  ...TransportModules,
  ...CargoConfigurationModules,
  ...ServiceDelayModules
];
