import "reflect-metadata";
import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "@/app/ui/router/index";
import store from "@/store";
import { AppComponent } from "./app/infrastructures/dependencies/AppComponent";
import "@/app/ui/assets/css/tailwind.css";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import "@/app/ui/assets/css/style.css";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import formatPhoneNumber from "@/app/infrastructures/misc/common-library/FormatPhoneNumber";
import PrimeVueLocaleConfig from "@/app/infrastructures/misc/common-library/PrimeVueLocaleConfig";
import Tooltip from "primevue/tooltip";
import VueGtag from "vue-gtag";
import moment from "moment";
import MedusaComponents from "@/app/ui/components";
import MedusaDirectives from "@/directives";
import { gtmModule } from "./gtm";
import "vue-client-recaptcha/dist/style.css";
import i18n from "@/i18n";
import * as Sentry from "@sentry/vue";
import DOMPurify from "dompurify";
moment.locale("id");
import "@/app/ui/assets/fonts";
import { initRollout } from "@/feature-flags";
import Hotjar from 'vue-hotjar'

export const app = createApp(App);
AppComponent.init();

app.directive("sanitize-html", (el, binding) => {
  el.innerHTML = DOMPurify.sanitize(binding.value);
});

MedusaComponents.register(app);
MedusaDirectives.register(app);
if (process.env.VUE_APP_SENTRY_DSN && process.env.VUE_APP_SENTRY_TARGET) {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration({ router })],
    tracesSampleRate:
      process.env.VUE_APP_ENVIRONMENTS === "production" ? 0.001 : 0.1,
    environment: process.env.VUE_APP_ENVIRONMENTS
  });
}
app
  .use({
    install: el => {
      el.config.globalProperties.$moment = moment;
    }
  })
  .mixin({
    methods: {
      formatDate,
      formatDateWithoutTime,
      formatPhoneNumber
    }
  })
  .directive("tooltip", Tooltip)
  .use(PrimeVue, {
    locale: {
      ...PrimeVueLocaleConfig
    }
  })
  .use(i18n)
  .use(ToastService)
  .use(Hotjar, {
    id: process.env.VUE_APP_HOTJAR_ID,
    isProduction: process.env.VUE_APP_ENVIRONMENTS === 'production' || process.env.VUE_APP_ENVIRONMENTS === 'staging' ,
  })

initRollout().then(() => {
  app
    .use(store)
    .use(router)
    .use(
      VueGtag,
      {
        config: { id: process.env.VUE_APP_GOOGLE_ANALYTCS }
      },
      router,
      gtmModule
    )
    .mount("#app");
});
