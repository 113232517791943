import { ClientManagementMapper } from "@/data/persistences/mappers/ClientManagementMapper";
import { ClientManagementRepositoryInterface } from "@/data/persistences/repositories/contracts/ClientManagementRepositoryInterface";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  EditClientManagementRequestInterface,
  RenewRatesRequestInterface,
  ApproveRenewRatesInterface,
  RejectRenewRatesInterface,
  UpdateCurrentRatesRequestInterface
} from "@/data/payload/contracts/ClientManagementRequest";
import {
  ClientManagementEntities,
  DetailClientManagement
} from "@/domain/entities/ClientManagement";

export class ClientManagementApiRepository
  implements ClientManagementRepositoryInterface {
  private service: ApiService;
  private mapper: ClientManagementMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: ClientManagementMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getListClientManagement(
    page: number,
    limit: number,
    search: string,
    searchCode: string,
    statusRates: string,
    statusRenewal: string,
    type: string
  ): Promise<ClientManagementEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListClientManagement(
        page,
        limit,
        search,
        searchCode,
        statusRates,
        statusRenewal,
        type
      ),
      {}
    );

    return this.mapper.convertClientManagementFromApi(resp);
  }

  public async getDetailClientManagement(
    id: number
  ): Promise<DetailClientManagement> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getClientManagementDetail(id),
      {}
    );
    return this.mapper.convertDetailClientManagementFromApi(resp);
  }

  public async editCurrentRatePeriode(
    payload: EditClientManagementRequestInterface
  ): Promise<ResponsePayload> {
    const response = await this.service.invoke(
      "put",
      this.endpoints.editCurrentRatePeriode(),
      undefined,
      payload
    );
    return this.mapper.convertChangeDataFromApi(response);
  }

  public async addRenewRates(
    payload: RenewRatesRequestInterface
  ): Promise<ResponsePayload> {
    const response = await this.service.invokePostWithFormData(
      "post",
      this.endpoints.addRenewRates(),
      {},
      payload
    );
    return this.mapper.convertChangeDataFromApi(response);
  }

  public async updateCurrentRates(
    payload: UpdateCurrentRatesRequestInterface
  ): Promise<ResponsePayload> {
    const response = await this.service.invokePostWithFormData(
      "post",
      this.endpoints.updateCurrentRates(),
      {},
      payload
    );
    return this.mapper.convertChangeDataFromApi(response);
  }

  public async editRenewRatePeriode(
    payload: RenewRatesRequestInterface
  ): Promise<ResponsePayload> {
    const response = await this.service.invokePostWithFormData(
      "put",
      this.endpoints.editRenewRateClientManagement(),
      undefined,
      payload
    );
    return this.mapper.convertChangeDataFromApi(response);
  }

  public async approveRenewRates(
    payload: ApproveRenewRatesInterface
  ): Promise<ResponsePayload> {
    const response = await this.service.invoke(
      "put",
      this.endpoints.approveRenewRates(),
      undefined,
      payload
    );

    return this.mapper.convertChangeDataFromApi(response);
  }

  public async rejectRenewRates(
    payload: RejectRenewRatesInterface
  ): Promise<ResponsePayload> {
    const response = await this.service.invoke(
      "put",
      this.endpoints.rejectRenewRates(),
      undefined,
      payload
    );

    return this.mapper.convertChangeDataFromApi(response);
  }

  public async fetchClientManagementApi(
    params: any
  ): Promise<ClientManagementEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.clientManagementApi(params),
      {}
    );

    return this.mapper.convertClientManagementFromApi(resp);
  }

  public async freezeUnfreezeClient(
    payload: EditClientManagementRequestInterface,
    type: string
  ): Promise<ResponsePayload> {
    const response = await this.service.invoke(
      "post",
      this.endpoints.freezeUnfreezeClient(type),
      undefined,
      payload
    );
    return this.mapper.convertChangeDataFromApi(response);
  }
}
