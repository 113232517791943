/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsPermissionReport = {
  report_enable: new Rox.Flag(false),
  report_bonus_komisi: new Rox.Flag(false),
};

export function initFlagsPermissionReport() {
  // register flags with namespaces for better grouping
  Rox.register("featureReport", FlagsPermissionReport);
}
