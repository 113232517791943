
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    errorType: {
      default: "server", // internet, server or notfound
      type: String
    },
    imageWidth: {
      default: "36 xxl:w-48",
      type: String
    },
    titleStyles: {
      default: "",
      type: String
    },
    messageStyles: {
      default: "",
      type: String
    },
    modal: {
      default: false,
      type: Boolean
    },
    isLoading: {
      default: false,
      type: Boolean
    },
    onTryAgain: {
      type: Function,
      required: false
    },
    onCancel: {
      type: Function,
      required: false
    },
    isShowTitle: {
      default: true,
      type: Boolean
    },
    errorTitle: {
      type: String,
      default: ""
    },
    errorMessage: {
      type: String,
      default: ""
    },
    errorImage: {
      type: String,
      default: ""
    },
    textButtonSuccess: {
      type: String,
      default: ""
    },
    keywordCase: {
      default: false,
      type: Boolean
    },
    customClass: {
      type: String,
      default: "h-full"
    },
    customClassButton: {
      type: String,
      default: ""
    },
    customWordingButton: {
      type: String,
      default: ""
    }
  },
  computed: {
    error() {
      if (this.errorType === "internet") {
        return {
          image: "no-internet",
          message: this.$t("Silahkan periksa jaringan internet Anda"),
          title: this.$t("Jaringan Internet Terputus")
        };
      }
      if (this.errorType === "notfound") {
        if (this.keywordCase) {
          return {
            image: "data-not-found",
            message: this.$t("errorKeywordCaseNotFoundMessage"),
            title: this.$t("errorKeywordCaseNotFoundTitle")
          };
        } else {
          return {
            image: "data-not-found",
            message: this.$t("errorNotFoundMessage"),
            title: this.$t("errorNotFoundTitle")
          };
        }
      }
      if (this.errorType === "lastPage") {
        return {
          image: "data-not-found",
          message: "",
          title: this.$t("Data tidak ditemukan pada halaman ini")
        };
      }
      return {
        image: this.errorImage || "server-error",
        title: this.errorTitle || "Oops, System Error",
        message:
          this.errorMessage ||
          this.$t(
            "Jangan khawatir ini bukan kesalahan Anda. Terjadi kesalahan pada system, silahkan coba lagi nanti"
          )
      };
    },
    textSuccess() {
      return this.onTryAgain
        ? this.textButtonSuccess || this.$t("modalButtonTryAgainTitle")
        : "";
    },
    textCancel() {
      return this.onCancel ? this.$t("modalButtonBackTitle") : "";
    }
  }
})
export default class ErrorMessage extends Vue {}
