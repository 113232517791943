import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";
import { PaginationV2 } from "@/domain/entities/Pagination";
import {
  BankDetail,
  CourierManagementDetail
} from "@/domain/entities/CourierManagement";
import { ClientPartnerOptions } from "@/domain/entities/CnManifest";

export class CourierManagementMapper {
  public convertListDataFromApi(result: AxiosResponse<any>): ResponsePayloadV2 {
    const { data } = result;

    return new ResponsePayloadV2({
      success: data.success,
      message: data.message,
      pagination: new PaginationV2({
        page: data.meta?.page,
        limit: data.meta?.limit,
        totalData: data.meta?.total_records
      }),
      data: data.data.length
        ? data.data.map(
            (item: any) =>
              new CourierManagementDetail({
                courierId: item.courier_id,
                fullname: item.fullname,
                phoneNumber: item.phone_number,
                partnerType: item.partner_type,
                announcementCourierType: item.announcement_courier_type,
                courierType: item.courier_type,
                vehiclePlate: item.vehicle_plate,
                partner: new ClientPartnerOptions({ name: item.partner_name }),
                courierStatus: item.courier_status
              })
          )
        : []
    });
  }

  public convertDownloadListDataFromApi(
    result: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const { data } = result;

    return new ResponsePayloadV2({
      data
    });
  }
  public convertDetailDataFromApi(
    result: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const {
      data: { data }
    } = result;

    return new ResponsePayloadV2({
      data: new CourierManagementDetail({
        courierId: data.courier_id,
        fullname: data.fullname,
        phoneNumber: data.phone_number,
        partnerType: data.partner_type,
        announcementCourierType: data.announcement_courier_type,
        courierType: data.courier_type,
        vehicleType: data.vehicle_type,
        vehiclePlate: data.vehicle_plate,
        partner: new ClientPartnerOptions({
          name: data.partner_name,
          partnerExternalCode: data.partner_external_code
        }),
        courierStatus: data.courier_status,
        bank: new BankDetail({
          bankId: data.bank.bank_id,
          bankName: data.bank.bank_name,
          bankAccountName: data.bank.bank_account_name,
          bankAccount: data.bank.bank_account
        })
      })
    });
  }
}
