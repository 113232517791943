/* eslint-disable @typescript-eslint/camelcase */
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import { BaseRatesData } from "./BaseRates";
import { Pagination } from "./Pagination";
import convertPhoneNumber from "@/app/infrastructures/misc/common-library/ConvertPhoneNumber";
import { OptionsClass } from "./MainApp";

export class ClientManagementEntities {
  pagination: Pagination;
  data: ClientManagementData[];
  constructor(pagination: Pagination, data: ClientManagementData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class ClientManagementData {
  clientId: number;
  clientCompanyName: string;
  clientCode: string;
  rateVersionStartDate: string;
  rateVersionEndDate: string;
  rateVersionStatus: string;
  rateVersionStatusRenewal: string;
  clientType: string;
  clientStatus: string;
  constructor(
    clientId: number,
    clientCompanyName: string,
    clientCode: string,
    rateVersionStartDate: string,
    rateVersionEndDate: string,
    rateVersionStatus: string,
    rateVersionStatusRenewal: string,
    clientType: string,
    clientStatus: string
  ) {
    this.clientId = clientId;
    this.clientCompanyName = clientCompanyName;
    this.clientCode = clientCode;
    this.rateVersionStartDate = rateVersionStartDate;
    this.rateVersionEndDate = rateVersionEndDate;
    this.rateVersionStatus = rateVersionStatus;
    this.rateVersionStatusRenewal = rateVersionStatusRenewal;
    this.clientType = clientType;
    this.clientStatus = clientStatus;
  }
}

export class ClientCurrentRate {
  clientCustomRateId = 0;
  clientStatusCustomRate = "";
  clientRateversionStartDate = "";
  clientRateVersionEndDate = "";
  clientStatusUpdatedAt = "";
  clientStatusUpdatedBy = "";
  clientStatusUpdatedReason = "";
  clientVersionBaseRate = "";
  clientDiscountBaseRate: any;
  clientFileNameBaseRate = "";
  clientFileBaseRate = "";
  clientVersionCustomRate = "";
  clientFileNameCustomRate = "";
  clientFileCustomRate = "";
  clientErrorNameCustomRate = "";
  clientErrorFileCustomRate = "";
  isBaseRate = false;
  isCustomRate = false;
  isCombine = false;
  constructor(fields?: Partial<ClientCurrentRate>) {
    Object.assign(this, fields);
  }
}
export class ClientRenewalRate {
  clientCustomRateId = 0;
  clientStatusCustomRate = "";
  clientRateversionStartDate = "";
  clientRateVersionEndDate = "";
  clientStatusUpdatedAt = "";
  clientStatusUpdatedBy = "";
  clientStatusUpdatedReason = "";
  clientVersionBaseRate = "";
  clientDiscountBaseRate: any;
  clientFileNameBaseRate = "";
  clientFileBaseRate = "";
  clientVersionCustomRate = "";
  clientFileNameCustomRate = "";
  clientFileCustomRate = "";
  clientErrorNameCustomRate = "";
  clientErrorFileCustomRate = "";
  constructor(fields?: Partial<ClientRenewalRate>) {
    Object.assign(this, fields);
  }
}
export class ClientLocation {
  id = 0;
  code = "";
  name = "";
  cityCode? = "";

  constructor(fields?: Partial<ClientLocation>) {
    Object.assign(this, fields);
  }
}
export class ClientCod {
  clientCodName = "";
  clientCodAddress = "";
  clientCodDistrict: any = "";
  clientCodCity = "";
  clientCodPhone = "";
  clientCodCommission = "";
  clientCodCommissionPercentage = "";

  constructor(fields?: Partial<ClientCod>) {
    Object.assign(this, fields);
  }
}
export class ClientCodAsDefault {
  defaultCodName = "";
  defaultCodAddress = "";
  defaultCodDistrict: any = "";
  defaultCodCity = "";
  defaultCodPhone = "";

  constructor(fields?: Partial<ClientCodAsDefault>) {
    Object.assign(this, fields);
  }
}

export class ClientBilling {
  noWhatsApp = "";
  billingSchedule = "";
  timeRangePayment = "";
  email: Array<string> = [];
  startDate = "";
  endDate = "";
  attachment = "";
  attachmentUrl = "";
  constructor(fields?: Partial<ClientBilling>) {
    Object.assign(this, fields);
  }

  get formattedStartDate() {
    return formatDateWithoutTime(this.startDate);
  }
  get formattedEndDate() {
    return formatDateWithoutTime(this.endDate);
  }
  get formattedEmail() {
    return this.email.join(", ");
  }
  get formattedWhatsappNumber() {
    return `+${convertPhoneNumber(this.noWhatsApp)}`;
  }

  get fileNameAttachment() {
    return this.attachmentUrl.split("/").pop();
  }
}
export class ClientTax {
  NIK = "";
  NIKName = "";
  NIKAddress = "";
  isTaxPayer = false;
  taxNumber = "";
  taxPayerName = "";
  taxPayerAddress = "";
  transactionCode = "05 Besaran Tertentu";
  constructor(fields?: Partial<ClientTax>) {
    Object.assign(this, fields);
  }
}

export class BankDetail {
  bankLabel = "";
  bankName = "";
  accountNo = "";
  accountName = "";
  email = "";
  logo = "";
  constructor(fields?: Partial<BankDetail>) {
    Object.assign(this, fields);
  }
}

export class DetailClientManagement {
  id = 0;
  clientName = "";
  clientType = "";
  clientBalance = 0;
  clientCategory = "";
  clientIsBanned = false;
  clientBannedReason = "";
  clientAccountName = "";
  clientAccountNumber = "";
  clientBankName = "";
  clientBeneficiaryEmail = "";
  clientContactEmail = "";
  clientContactJobTitle = "";
  clientContactName = "";
  clientContactPhone = "";
  clientIsCod = false;
  clientIsDfod = false;
  clientIsDo = false;
  clientParentId = 0;
  clientParentCode = "";
  clientParentName = "";
  clientPartnerCommissionAmount = "";
  clientPartnerCommissionAmountPercentage = "";
  clientPartnerId = 0;
  clientPartnerName = "";
  clientPartnerQuantifierCommission = "";
  clientPartnerNumeratorCommission = "";
  clientCode = "";
  clientElexysCode = "";
  clientPhone = "";
  clientEmail = "";
  clientTaxNumber = "";
  clientStatus = "";
  clientAddress = "";
  clientDistrict = new ClientLocation();
  clientCity = new ClientLocation();
  clientStatusUpdatedAt = "";
  clientStatusUpdatedBy = "";
  clientStatusUpdatedReason = "";
  clientIsCustomRate = false;
  clientIsRenewalCustomRate = false;
  clientCreatedAt = "";
  clientUpdatedAt = "";
  clientCreatedBy = "";
  clientUpdatedBy = "";
  clientCurrentRate = new ClientCurrentRate();
  clientCodReject = new ClientCod();
  clientCodRejectAsDefault = new ClientCodAsDefault();
  dataProcessed = 0;
  totalData = 0;
  clientRenewalRate = new ClientRenewalRate();
  clientOdooId = 0;
  clientCompanySize = "";
  clientTax = new ClientTax();
  clientBilling = new ClientBilling();
  bankDetail = new BankDetail();
  bankSettlement = new BankDetail();
  clientPadFee = 0;
  clientPadFeePercentage = "";
  rateActiveDate = "";
  clientPaymentMethod = new OptionsClass();
  clientCodConfigAmount = new OptionsClass();
  clientCodShipmentDiscount = "";
  clientCodShipmentDiscountPercentage = "";
  clientBranchDoPaymentType = "";
  constructor(fields?: Partial<DetailClientManagement>) {
    Object.assign(this, fields);
    if (!fields?.clientPaymentMethod) {
      this.clientPaymentMethod = new OptionsClass();
    }
    if (!fields?.clientCodConfigAmount) {
      this.clientCodConfigAmount = new OptionsClass();
    }
    if (!fields?.clientCodShipmentDiscount) {
      this.clientCodShipmentDiscount = "0";
    } else {
      this.clientCodShipmentDiscount = String(
        fields?.clientCodShipmentDiscount
      );
    }
  }

  formattedStartDateBilling() {
    return formatDateWithoutTime(this.clientBilling.startDate);
  }
  formattedEndDateBilling() {
    return formatDateWithoutTime(this.clientBilling.endDate);
  }
}

export class File {
  file = "";
  name = "";
  constructor(fields?: Partial<File>) {
    Object.assign(this, fields);
  }
}

export class DetailClientForm {
  id = 0;
  companyName = "";
  email = "";
  phone = "";
  address = "";
  taxNumber = "";
  versionBasic: string | undefined | BaseRatesData = "";
  isCustomRate = "";
  currentDiscount = "";
  discount: any = "";
  versionName = "";
  archiveFile = "";
  rateVersionId = 0;
  fileCustomRate = new File();
  rateActiveDate = "";
  constructor(fields?: Partial<DetailClientForm>) {
    Object.assign(this, fields);
  }
}
