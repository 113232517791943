import { Pagination } from "./Pagination";

export class ClientEntities {
  pagination: Pagination;
  data: ClientData[];
  constructor(pagination: Pagination, data: ClientData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class ClientData {
  id: number;
  code: string;
  companyName: string;
  phone: string;
  address: string;
  balance: number;
  createdAt: string;
  updatedAt: string;
  status?: string;
  constructor(
    id: number,
    code: string,
    companyName: string,
    phone: string,
    address: string,
    balance: number,
    createdAt: string,
    updatedAt: string,
    status?: string
  ) {
    this.id = id;
    this.code = code;
    this.companyName = companyName;
    this.phone = phone;
    this.address = address;
    this.balance = balance;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.status = status;
  }
}

export class ClientPartnerData {
  id: number;
  name: string;
  code: string;
  type: string;
  country: string;
  currency?: string;

  constructor(
    id: number,
    name: string,
    code: string,
    type: string,
    country: string,
    currency?: string
  ) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.type = type;
    this.country = country;
    this.currency = currency;
  }
}

export class ClientParentData {
  id: number;
  name: string;
  code: string;

  constructor(id: number, name: string, code: string) {
    this.id = id;
    this.name = name;
    this.code = code;
  }
}

export class Actor {
  createdId: number;
  createdName: string;

  constructor(createdId: number, createdName: string) {
    this.createdId = createdId;
    this.createdName = createdName;
  }
}

export class RequestApiClientMappingList {
  page = 1;
  limit = 10;
  search = "";
  isBranch = false;
  isParent = false;

  constructor(fields?: Partial<RequestApiClientMappingList>) {
    Object.assign(this, fields);
  }
}

export class ClientMappingList {
  clientId = 0;
  clientCode = "";
  clientName = "";
  clientStatus = "";
  clientIsDo = false;
  clientIsCod = false;
  clientIsMixpack = false;
  clientIsBanned = false;
  clientPartner = null;

  constructor(fields?: Partial<ClientMappingList>) {
    Object.assign(this, fields);
  }
}
