export const playNotification = (type = "success") => {
  let audio = null;
  switch (type) {
    case "success":
      audio = new Audio(
        require("@/app/ui/assets/audio/success_notification.wav")
      );
      break;
    case "error":
      audio = new Audio(
        require("@/app/ui/assets/audio/error_notification.wav")
      );
      break;
    case "success_cargo":
      audio = new Audio(
        require("@/app/ui/assets/audio/success_notif_cargo.wav")
      );
      break;
    case "failed_cargo":
      audio = new Audio(
        require("@/app/ui/assets/audio/failed_notif_cargo.wav")
      );
      break;
    default:
      break;
  }

  if (!audio) return;
  audio.volume = 1;
  audio.play();
  audio.remove();
};
