import { ClientRegistrationApiRepository } from "@/app/infrastructures/repositories/api/ClientRegistrationApiRepository";
import {
  ClientRegistrationEntities,
  ClientRegistrationDetail
} from "@/domain/entities/ClientRegistration";
import { injectable } from "tsyringe";
import {
  ClientRegistrationRequest,
  ClientRegistrationApproveRejectRequest,
  EditClientRegistrationRequest,
  CreateClientBranchRequest,
  EditRenewRatesRequest
} from "@/data/payload/contracts/ClientRegistrationRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { EditClientManagementBasicInformationRequest } from "@/data/payload/api/ClientManagementApiRequest";

@injectable()
export class ClientRegistrationPresenter {
  private repository: ClientRegistrationApiRepository;

  constructor(repository: ClientRegistrationApiRepository) {
    this.repository = repository;
  }

  public getListClientRegistration(
    page: number,
    limit: number,
    search: string,
    status: string,
    type: string
  ): Promise<ClientRegistrationEntities> {
    return this.repository.getListClientRegistration(
      page,
      limit,
      search,
      status,
      type
    );
  }

  public createClientBranchRegistration(
    payload: CreateClientBranchRequest
  ): Promise<ResponsePayload> {
    return this.repository.createClientBranch(payload);
  }

  public getDetailClientRegistration(
    id: number
  ): Promise<ClientRegistrationDetail> {
    return this.repository.getDetailClientRegistration(id);
  }

  public addClientRegistration(
    payload: ClientRegistrationRequest
  ): Promise<ResponsePayload> {
    return this.repository.addClientRegistration(payload);
  }

  public editClientManagemetBasicInformation(
    payload: EditClientManagementBasicInformationRequest
  ): Promise<ResponsePayload> {
    return this.repository.editClientManagemetBasicInformation(payload);
  }

  public approveClientRegistration(
    payload: ClientRegistrationApproveRejectRequest
  ): Promise<ResponsePayload> {
    return this.repository.approveClientRegistration(payload);
  }

  public rejectClientRegistration(
    payload: ClientRegistrationApproveRejectRequest
  ): Promise<ResponsePayload> {
    return this.repository.rejectClientRegistration(payload);
  }

  public editClientRegistration(
    payload: EditClientRegistrationRequest
  ): Promise<ResponsePayload> {
    return this.repository.editClientRegistration(payload);
  }

  public editRenewRates(
    payload: EditRenewRatesRequest
  ): Promise<ResponsePayload> {
    return this.repository.editRenewRates(payload);
  }
}
