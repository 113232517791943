import { InstantBookingApiRepository } from "@/app/infrastructures/repositories/api/InstantBookingApiRepository";
import { CreateInstantBookingSessionRequestInteface } from "@/data/payload/contracts/InstantBookingRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { injectable } from "tsyringe";
import { DetailInstantBookingData } from "@/domain/entities/InstantBooking";
import { InstantBookingRequestInterface } from "@/data/payload/contracts/InstantBookingRequest";

@injectable()
export class InstantBookingPresenter {
  private repository: InstantBookingApiRepository;

  constructor(repo: InstantBookingApiRepository) {
    this.repository = repo;
  }

  public getDetail(): Promise<DetailInstantBookingData> {
    return this.repository.getDetail();
  }

  public async createInstantBookingSession(
    payload: CreateInstantBookingSessionRequestInteface
  ): Promise<ResponsePayload> {
    return await this.repository.createInstantBookingSession(payload);
  }

  public updateSession(
    payload: InstantBookingRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.updateSession(payload);
  }
}
