import { EditClientManagementBasicInformationRequest } from "@/data/payload/api/ClientManagementApiRequest";
import {
  ClientRegistrationApproveRejectRequest,
  ClientRegistrationRequest,
  CreateClientBranchRequest,
  EditClientRegistrationRequest,
  EditRenewRatesRequest
} from "@/data/payload/contracts/ClientRegistrationRequest";
import { ClientRegistrationMapper } from "@/data/persistences/mappers/ClientRegistrationMapper";
import { ClientRegistrationRepositoryInterface } from "@/data/persistences/repositories/contracts/ClientRegistrationRepositoryInterface";
import {
  ClientRegistrationDetail,
  ClientRegistrationEntities
} from "@/domain/entities/ClientRegistration";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";

export class ClientRegistrationApiRepository
  implements ClientRegistrationRepositoryInterface {
  private service: ApiService;
  private mapper: ClientRegistrationMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: ClientRegistrationMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async createClientBranch(
    payload: CreateClientBranchRequest
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokePostWithFormData(
      "post",
      this.endpoints.createClientBranch(),
      {},
      payload
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async getListClientRegistration(
    page: number,
    limit: number,
    search: string,
    status: string,
    type: string
  ): Promise<ClientRegistrationEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListClientRegistration(
        page,
        limit,
        search,
        status,
        type
      ),
      {}
    );
    return this.mapper.convertClientRegistrationFromApi(resp);
  }

  public async getDetailClientRegistration(
    id: number
  ): Promise<ClientRegistrationDetail> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getClientRegistrationDetail(id),
      {}
    );
    return this.mapper.convertDetailClientRegistrationFromApi(resp);
  }

  public async editClientRegistration(
    payload: EditClientRegistrationRequest
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokePostWithFormData(
      "put",
      this.endpoints.editClientRegistration(),
      {},
      payload
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async addClientRegistration(
    payload: ClientRegistrationRequest
  ): Promise<ResponsePayload> {
    const response = await this.service.invokePostWithFormData(
      "post",
      this.endpoints.addClientRegistration(),
      {},
      payload
    );
    return this.mapper.convertChangeDataFromApi(response);
  }

  public async editClientManagemetBasicInformation(
    payload: EditClientManagementBasicInformationRequest
  ): Promise<ResponsePayload> {
    const response = await this.service.invokePrivatePostWithFormData(
      "put",
      this.endpoints.editClientManagemetBasic(),
      undefined,
      payload
    );
    return this.mapper.convertChangeDataFromApi(response);
  }

  public async approveClientRegistration(
    payload: ClientRegistrationApproveRejectRequest
  ): Promise<ResponsePayload> {
    const response = await this.service.invoke(
      "put",
      this.endpoints.approveClientRegistration(),
      undefined,
      payload
    );
    return this.mapper.convertChangeDataFromApi(response);
  }

  public async rejectClientRegistration(
    payload: ClientRegistrationApproveRejectRequest
  ): Promise<ResponsePayload> {
    const response = await this.service.invoke(
      "put",
      this.endpoints.rejectClientRegistration(),
      undefined,
      payload
    );
    return this.mapper.convertChangeDataFromApi(response);
  }

  public async editRenewRates(
    payload: EditRenewRatesRequest
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokePostWithFormData(
      "put",
      this.endpoints.editClientRegistration(),
      {},
      payload
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }
}
