/* eslint-disable @typescript-eslint/camelcase */
import { container } from "tsyringe";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import {
  setAgentCode,
  setUserRolePermission
} from "@/app/infrastructures/misc/Cookies";
import store from "@/store";
import {
  AccountEntities,
  AccountStatus,
  DashboardDeliveryPos,
  ValidationAccountData
} from "@/domain/entities/Account";
import { AccountPresenter } from "../presenters/AccountPresenter";
import { LocationController } from "./LocationController";
import { BalanceController } from "./BalanceController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import {
  DashboardDeliveryRequest,
  EditEmailAccountRequest,
  PairTokenRequest,
  PostCsAccountApiRequest,
  UnpairTokenRequest
} from "@/data/payload/api/AccountRequest";
import formatPrice from "@/app/infrastructures/misc/common-library/FormatPrice";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import rolePermissionForClient from "@/app/infrastructures/misc/common-library/RolePermissionForClient";
import { storeDatatoLocalStorage } from "@/app/infrastructures/misc/LocalStorage";
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Constants";
import { CashbackConfigurationController } from "./CashbackConfigController";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { TopupFeeConfigurationController } from "./TopupFeeConfigurationController";

export interface AccountState {
  accountData: AccountEntities;
  isLoading: boolean;
  isError: boolean;
  isShowBalance: boolean;
  priceView: string;
  errorCause: string;
  districtCode: number;
  isFreeTradeZone: boolean;
}

@Module({ namespaced: true, dynamic: true, store, name: "Account" })
class AccountStore extends VuexModule implements AccountState {
  public accountData = new AccountEntities();
  public isLoading = false;
  public isLoadingDashboard = false;
  public isError = false;
  public isErrorDashboard = false;
  public isShowBalance = false;
  public priceView = "";
  public codIncomePriceView = "";
  public errorCause = "";
  public errorCauseDashboard = "";
  public districtCode = 0;
  public isFreeTradeZone = false;
  public dashboardDeliveryData: Array<any> = [];
  public validationAccount = false;

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool;
  }

  @Mutation
  private setLoadingDashboard(bool: boolean) {
    this.isLoadingDashboard = bool;
  }

  @Mutation
  private setError(bool: boolean) {
    this.isError = bool;
  }

  @Mutation
  private setErrorCause(str: string) {
    this.errorCause = str;
  }

  @Mutation
  private setErrorDashboard(bool: boolean) {
    this.isErrorDashboard = bool;
  }

  @Mutation
  private setErrorDashboardCause(str: string) {
    this.errorCauseDashboard = str;
  }

  @Mutation
  public setAccountData(data: AccountEntities) {
    this.accountData = data;
  }
  @Mutation
  public onResetAccountData() {
    this.accountData = new AccountEntities();
  }
  @Mutation
  public setAccountType(accountType: string) {
    this.accountData.account_type = accountType;
  }
  @Mutation
  public setAccountEmail(email: string) {
    this.accountData.email = email;
  }

  @Mutation
  public setDashboardDeliveryData(data: DashboardDeliveryPos) {
    const type = this.accountData.account_type_detail.type;

    const dashboardData: Array<any> = [
      {
        title: "Total STT DEL",
        description:
          "Total paket yang sedang Anda proses pengantaran ke alamat tujuan oleh kurir.",
        totalStt: data.totalSttDel,
        icon: data.totalSttDel ? "stt-del-active" : "stt-del"
      }
    ];

    const dashboardDataPos: Array<any> = [
      {
        title: "Total STT DEX",
        description:
          "Total paket yang sudah Anda proses pengantaran namun paket tidak terkirim ke alamat tujuan karena alasan tertentu.",
        totalStt: data.totalSttDex,
        icon: data.totalSttDex ? "stt-dex-active" : "stt-dex"
      }
    ];

    const dashboardDataSubConsole: Array<any> = [
      {
        title: "Total STT STI DEST-SC",
        description:
          "Total paket yang sudah Anda terima untuk dilanjutkan ke POS pengantaran alamat tujuan.",
        totalStt: data.totalSttStiDestSc,
        icon: data.totalSttStiDestSc ? "sti-dest-sc-active" : "sti-dest-sc"
      },
      {
        title: "Total STT STI-SC",
        description:
          "Total paket yang sudah Anda terima untuk dilanjutkan ke Consolidator kota asal. ",
        totalStt: data.totalSttStiSc,
        icon: data.totalSttStiSc ? "sti-sc-active" : "sti-sc-gray"
      }
    ];

    if (type === "pos") {
      this.dashboardDeliveryData = [...dashboardData, ...dashboardDataPos];
    } else if (type === "sub-console") {
      this.dashboardDeliveryData = [
        ...dashboardData,
        ...dashboardDataSubConsole
      ];
    } else {
      this.dashboardDeliveryData = [];
    }
  }

  @Mutation
  public onResetDashboardDeliveryData() {
    this.dashboardDeliveryData = [];
  }

  @Mutation
  private setShowBalance(bool: boolean) {
    this.isShowBalance = bool;
  }

  @Mutation
  private setCapitalize(str: string) {
    this.accountData.account_type = str;
  }

  @Mutation
  private setPriceView(str: string) {
    this.priceView = str;
  }

  @Mutation
  private setCodIncomePriceView(str: string) {
    this.codIncomePriceView = str;
  }

  @Mutation
  private setDistrictCode(num: number) {
    this.districtCode = num;
  }

  @Action
  public showBalance() {
    this.setShowBalance(false);
    if (this.accountData.account_type_detail !== null) {
      if (
        (this.accountData.account_type === "partner" &&
          this.accountData.account_type_detail.type === "pos") ||
        this.accountData.account_type === "client" ||
        this.accountData.account_type.toLowerCase() === "customer"
      ) {
        return this.setShowBalance(true);
      }
    }
  }

  @Action
  public convertBalance(acc: AccountEntities) {
    if (acc.account_type_detail !== null) {
      if (
        (acc.account_type === "partner" &&
          acc.account_type_detail.type === "pos") ||
        acc.account_type === "client" ||
        acc.account_type.toLowerCase() === "customer"
      ) {
        BalanceController.getBalance(true).then(res => {
          if (res) {
            this.setBalance(res.walletBalance);
            this.setPriceView(formatPrice(res.walletBalance));
            this.setCodIncomePriceView(formatPrice(res.walletBalanceCod));
          }
        });
      }
    }
  }

  @Mutation
  public setBalance(value: number) {
    this.accountData.account_type_detail.balance = value;
  }

  @Mutation
  public setValidationAccount(res: boolean) {
    this.validationAccount = res;
  }

  @Action
  public async getValidationAccount() {
    this.setLoading(true);
    const presenter = await container.resolve(AccountPresenter);
    return presenter
      .getValidationAccount()
      .then((res: ValidationAccountData) => {
        this.setValidationAccount(res.isAllow);
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public async _getData() {
    try {
      this.setLoading(true);
      const presenter = await container.resolve(AccountPresenter);
      const res: AccountEntities = await presenter.getProfile(true, true);

      if (res.account_type === "customer-service") {
        const accountStatusResponse = await this.getAccountStatus(
          res.account_id
        );
        this.setAccountStatus(accountStatusResponse.data);
      }

      this.setAccountData(res);
      this.setError(false);
      this.setErrorCause("");
      this.showBalance();
      this.convertBalance(res);
      if (!IS_SENDER_ACCOUNT) {
        this.getDistrictCode(res);
        this.setCapitalize(this.accountData.account_type.toLowerCase());
        storeDatatoLocalStorage("FeatureFlag", this.accountData.featureFlag);
        this.getValidationAccount();
        if (res.account_type === "client") {
          setUserRolePermission(
            rolePermissionForClient(
              res.account_type_detail,
              this.accountData.role_permission
            )
          );
        } else {
          setUserRolePermission(this.accountData.role_permission);
        }

        if (
          res.account_type === "partner" &&
          res.account_type_detail.type === "pos"
        ) {
          setAgentCode(
            res.account_type_detail.partnerExternalCode ||
              res.account_type_detail.code
          );
          this.getConfigTopupAndCashbackActive(res);
        }
      }
    } catch (error) {
      this.setError(true);
      this.setErrorCause((error as any)?.response ? "server" : "internet");
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  private async getConfigTopupAndCashbackActive(res: AccountEntities) {
    const activeCashback = await CashbackConfigurationController.getActiveCashback(res.account_type_detail.partnerLocation?.partner_id || res.account_type_detail?.id);
    const activeTopupFee = await TopupFeeConfigurationController.getActiveTopupFeeConfig(res.account_type_detail.partnerLocation?.partner_id || res.account_type_detail?.id)
    this.setActiveCasbackConfig(activeCashback);
    this.setActiveTopupFeeConfig(activeTopupFee);
  } 

  accountStatus = new AccountStatus();
  @Mutation
  public setAccountStatus(value: AccountStatus) {
    this.accountStatus = value;
  }
  @Action
  public getAccountStatus(accountId: number) {
    const presenter = container.resolve(AccountPresenter);
    return presenter.getAccountStatus(accountId);
  }

  @Action
  public postCsAccountLog(payload: PostCsAccountApiRequest) {
    const presenter = container.resolve(AccountPresenter);
    return presenter.postCsAccountLog(payload);
  }

  @Action
  public async _getDashboardDeliveryData(params: DashboardDeliveryRequest) {
    this.setLoadingDashboard(true);
    const presenter = await container.resolve(AccountPresenter);
    presenter
      .getDashboardDelivery(params)
      .then((res: DashboardDeliveryPos) => {
        this.setDashboardDeliveryData(res);
        this.setErrorDashboard(false);
        this.setErrorDashboardCause("");
      })
      .catch((error: any) => {
        console.log(error);

        this.setErrorDashboard(true);
        this.setErrorDashboardCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoadingDashboard(false);
      });
  }

  @Action
  public async checkAccountLocation() {
    const presenter = await container.resolve(AccountPresenter);
    presenter.getProfile(true, true).then((res: AccountEntities) => {
      this.setAccountData(res);
      this.setIsFreeTradeZone(
        this.accountData.account_location.city_free_trade_zone === "yes"
      );
    });
  }

  @Action
  public getDistrictCode(acc: AccountEntities) {
    this.setDistrictCode(0);
    if (acc.account_type_detail.partnerLocation !== undefined) {
      const convert = Number(
        acc.account_type_detail.partnerLocation.district.id // get id
      );
      this.setDistrictCode(convert);
    } else {
      const convert = acc.account_location.district_id;
      this.setDistrictCode(convert);
    }
    if (this.districtCode !== 0) {
      LocationController.getDetailDistrict({ id: this.districtCode });
    }
  }

  @Action
  public handleError() {
    this.setError(false);
  }

  // collapse sidebar
  isCollapseSidebar = false;
  @Mutation
  public setIsCollapseSidebar(val: boolean) {
    this.isCollapseSidebar = val;
  }

  @Mutation
  public setIsFreeTradeZone(bool: boolean) {
    this.isFreeTradeZone = bool;
  }

  // edit email
  @Action
  public async editEmailAccount(params: { email: string }) {
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    const presenter = container.resolve(AccountPresenter);
    return presenter
      .editEmailAccount(new EditEmailAccountRequest(params.email))
      .then(() => {
        return true;
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Perubahan Email Gagal")
        );
        return false;
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public async pairToken(payload: PairTokenRequest) {
    const presenter = container.resolve(AccountPresenter);
    return presenter.pairToken(payload);
  }

  @Action
  public async unpairToken(payload: UnpairTokenRequest) {
    const presenter = container.resolve(AccountPresenter);
    return presenter.unpairToken(payload);
  }

  activeCashback = new ResponsePayloadV2();
  @Mutation
  public setActiveCasbackConfig(res: ResponsePayloadV2) {
    this.activeCashback = res;
  }

  activeTopupFee = new ResponsePayloadV2();
  @Mutation
  public setActiveTopupFeeConfig(res: ResponsePayloadV2) {
    this.activeTopupFee = res;
  }
}

export const AccountController = getModule(AccountStore);
