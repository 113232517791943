/* eslint-disable @typescript-eslint/camelcase */
import { container } from "tsyringe";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import {
  CustomerListEntities,
  ManualBooking,
  EstimationPrice,
  EstimationCodRetail,
  PrintDataEntitas,
  ShipmentIdList,
  CustomerExist,
  ProductDelivery,
  InsuranceTypeDelivery,
  WeightDimensions,
  EstimationServiceLevelAgreement
} from "@/domain/entities/Booking";
import { Pagination } from "@/domain/entities/Pagination";
import { BookingPresenter } from "../presenters/BookingPresenter";
import { ShipmentBookingPresenter } from "@/app/ui/presenters/ShipmentBookingPresenter";
import {
  AddCustomer,
  AddManualBooking,
  GetEstimationPrice,
  CancelBooking,
  EditBooking,
  AddBookingCorporate,
  ValidateBookingPhone,
  RequestEstimationServiceLevelAgreement,
  RequestEstimateSlaDetail,
  SubmitPosterProductApiRequest,
  UploadHydraFileRequest,
  DeleteHydraFileRequest
} from "@/data/payload/api/BookingRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { SttManualValidation } from "@/domain/entities/ShipmentBooking";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ErrorMessageEntities, ModalMessageEntities } from "@/domain/entities/MainApp";
import {
  INTERNET_ERROR,
  INTERNAL_SERVER_ERROR,
  SPECIAL_ERROR,
  IS_HIDE_INSURANCE_PREMIUM,
  FAILED_ERROR
} from "@/app/infrastructures/misc/Constants";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { updateLocalStorageFeatureFlag } from "@/app/infrastructures/misc/LocalStorage";
import checkEnablePaymentQris from "@/app/infrastructures/misc/common-library/CheckEnablePaymentQris";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import { MiddlewarePresenter } from "@/app/ui/presenters/MiddlewarePresenter";
import { PaymentController } from "@/app/ui/controllers/PaymentController";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import moment from "moment";
import { LegPresenter } from "@/app/ui/presenters/LegPresenter";
import maxBy from "lodash/maxBy";
import { RequestListRouteLeg } from "@/data/payload/api/LegApiRequest";
import { 
  responseFailedBookingManual,
  isQrisPayment,
  setErrorInternet,
  SLAFullfilled
} from "./modules/booking-stt";
import { AccountController } from "./AccountController";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";

export interface BookingState {
  isLoading: boolean;
  isLoadingEditBooking: boolean;
  isLoadingPrint: boolean;
  isLoadingDetail: boolean;
  bookingData: Array<any>;
  detailBooking: any;
  isError: boolean;
  isErrorCause: string;
  isErrorPrint: boolean;
  isErrorCausePrint: string;
  isErrorEditBooking: boolean;
  isErrorCauseEditBooking: string;
  search: string;
  manualBooking: ManualBooking;
  messageSuccess: string;
  showModalManualBooking: boolean;
  showModalSuccess: boolean;
  shipmentIdList: ShipmentIdList;
  sttId: number;
  existSender: any;
  existReceiver: any;
  productsDelivery: ProductDelivery;
  errorGetProductsDelivery: boolean;
  errorCauseGetProductsDelivery: string;
  loadingProductsDelivery: boolean;
  loadingInsuranceTypeDelivery: boolean;
  insuranceTypeDelivery: InsuranceTypeDelivery;
  errorGetInsuranceTypeDelivery: boolean;
  errorCauseGetInsuranceTypeDelivery: string;
  errorSpesificByRate: boolean;
  errorSpesificEstByRate: boolean;
  titleErrorspesification: string;
  isErrorEmbargo: boolean;
  isGenerate: boolean;
  weightDimensions: WeightDimensions;
  guardBookingSubmit: boolean;
  estimationCodRetail: EstimationCodRetail;
}

const errorSpesification: any = {
  "Destination Forward Rate price for selected route is not available": {
    headerError: !AccountController.accountData.accountIsForeign ? "Harga Tidak Ditemukan !" : "Shipment Rates Not Found",
    errorMsg: !AccountController.accountData.accountIsForeign ? "Kota tujuan yang Anda pilih belum memiliki harga." : "Shipment rates for the selected route are not available",
    errorDestination: false,
    isErrorEmbargo: false
  },
  "Origin Forward Rate price for selected route is not available": {
    headerError: !AccountController.accountData.accountIsForeign ? "Harga Tidak Ditemukan !" : "Shipment Rates Not Found",
    errorMsg: !AccountController.accountData.accountIsForeign ? "Kota tujuan yang Anda pilih belum memiliki harga." : "Shipment rates for the selected route are not available",
    errorDestination: false,
    isErrorEmbargo: false
  },
  "Shipping Surcharge and Publish Rate price for selected route is not available": {
    headerError: !AccountController.accountData.accountIsForeign ? "Harga Tidak Ditemukan !" : "Shipment Rates Not Found",
    errorMsg: !AccountController.accountData.accountIsForeign ? "Kota tujuan yang Anda pilih belum memiliki harga." : "Shipment rates for the selected route are not available",
    errorDestination: true,
    isErrorEmbargo: false
  },
  "Unacceptable due to an embargo": {
    headerError: !AccountController.accountData.accountIsForeign ? "EMBARGO !" : "Route Under Embargo!",
    errorMsg: !AccountController.accountData.accountIsForeign ? "Sistem tidak dapat memproses permintaan Anda." : "Unable to process your request. Please reset your route",
    errorDestination: false,
    isErrorEmbargo: true
  }
};

@Module({ namespaced: true, dynamic: true, store, name: "shipment_booking" })
class BookingStore extends VuexModule implements BookingState {
  public sttId = 0;
  public isLoading = false;
  public isLoadingEditBooking = false;
  public isLoadingPrint = false;
  public isLoadingDetail = false;
  public showModalManualBooking = false;
  public showModalSuccess = false;
  public bookingData = [];
  public detailBooking = null;
  public isError = false;
  public isErrorCause = "";
  public isErrorPrint = false;
  public isErrorCausePrint = "";
  public isErrorEditBooking = false;
  public isErrorCauseEditBooking = "";
  public search = "";
  public customerList = new CustomerListEntities(new Pagination(1, 10), []);
  public estimationPrice = new EstimationPrice();
  public generatePrintData = new PrintDataEntitas();
  public isLoadingCustomer = false;
  public manualBooking = new ManualBooking();
  public messageSuccess = "";
  public shipmentIdList = new ShipmentIdList({
    pagination: new Pagination(1, 10),
    data: []
  });

  public existSender = false;
  public existReceiver = false;
  public productsDelivery = new ProductDelivery(new Pagination(1, 10), []);
  public errorGetProductsDelivery = false;
  public errorCauseGetProductsDelivery = "";
  public loadingProductsDelivery = false;
  public loadingInsuranceTypeDelivery = false;
  public insuranceTypeDelivery = new InsuranceTypeDelivery([]);
  public errorGetInsuranceTypeDelivery = false;
  public errorCauseGetInsuranceTypeDelivery = "";
  public errorPhoneNumber = "";
  public errorSpesificByRate = false;
  public errorSpesificEstByRate = false;
  public titleErrorspesification = "";
  public errorDestination = false;
  public isErrorEmbargo = false;
  public isVendorNinjaDestination = false;
  public isGenerate = false;
  public weightDimensions = new WeightDimensions([]);
  public guardBookingSubmit = false;
  public allInsuranceTypeDelivery = new InsuranceTypeDelivery([]);
  public estimationCodRetail = new EstimationCodRetail();
  public isSameDayProduct = false;

  @Action
  public getCustomerList(params: { search: string; cache: boolean }) {
    this.setIsLoadingCustomer(true);
    const presenter = container.resolve(BookingPresenter);
    presenter
      .getCustomerList(params.search, params.cache)
      .then((res: CustomerListEntities) => {
        this.setCustomerList(res);
      })
      .catch((error: any) => {
        updateLocalStorageFeatureFlag("customer");
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setIsLoadingCustomer(false);
      });
  }

  @Action
  public async addManualBooking(payload: AddManualBooking) {
    this.initCreateBooking();
    const presenter = container.resolve(BookingPresenter);
    return presenter.addManualBooking(payload)
  }

  @Action 
  public initCreateBooking() {
    this.setShowModalManualBooking(false);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
  }

  @Action 
  public finalCreateBooking() {
    this.setShowModalManualBooking(false);
    MainAppController.closeLoading();
  }

  @Action
  async onSuccessBooking(params: { sttNumber: string; sttId: number }) {
    this.setMessageSuccess(!AccountController.accountData.accountIsForeign 
      ? `Pengiriman berhasil terbooking dengan STT "${params.sttNumber}"`
      : `Shipment successfully booked with STT number "${params.sttNumber}"`
    );
    this.setSTTID(params.sttId);
    this.setError(false);
    this.setErrorCause("");
    const isSttRetail =
      params.sttNumber.startsWith("11LP") ||
      params.sttNumber.startsWith("10LP");

    if (
      isSttRetail &&
      isQrisPayment(checkEnablePaymentQris("booking"), PaymentController.paymentMethod.name !== "cash")
    ) {
      PaymentController.setSttNumber(params.sttNumber);
      PaymentController.setBillAmount(0);
      PaymentController.toggleDialogPayment(true);
    } else {
      this.setShowModalSuccess(true);
    }
  }

  @Action 
  public generateErrorBooking(params: {
    err: any, 
    payload: any, 
    errorFor: string, 
    calllFunctionAgain: Function
  }) {
    if (params.err.response) {
      if (params.err.response.status < 500) {
        const error = {
          errorFor: params.errorFor,
          errorSpecs: errorSpesification,
          errorSystem: params.err
        }
        const funcs = {
          destination: this.setErrorDestination,
          embargo: this.setErrorEmbargo,
          guard: this.setGuardBookingSubmit
        }
        responseFailedBookingManual(error, funcs);
        return false;
      }

      MainAppController.showErrorMessage(
        new ErrorMessageEntities({
          type: INTERNAL_SERVER_ERROR,
          onTryAgain: () => params.calllFunctionAgain(params.payload)
        })
      );
     
      return false;
    }

    setErrorInternet(params.calllFunctionAgain, params.payload);
    return false;
  }

  @Action
  public addCustomer(params: { name: string; phone: string; address: string }) {
    const presenter = container.resolve(BookingPresenter);
    presenter
      .addCustomer(new AddCustomer(params.name, params.phone, params.address))
      .then(() => {
        this.setError(false);
        this.setErrorCause("");
      });
  }

  @Action
  public async getEstimationPrice(payload: GetEstimationPrice) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(BookingPresenter);
    return presenter
      .getEstimationPrice(payload)
      .then((res: EstimationPrice) => {
        this.setEstimationPrice(res);
        return true;
      })
      .catch((err: any) => {
        this.setDefaultEstimationPrice();
        if (!payload.hideError) {
          this.generateErrorBooking({
            err: err, 
            payload: payload, 
            errorFor: "estimation", 
            calllFunctionAgain: this.getEstimationPrice
          });
        }
    
        return false;
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public async generatePrinData(params: { stt: number; accountType: string }) {
    this.setPrintData(new PrintDataEntitas());
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(BookingPresenter);
    return presenter
      .generatePrintData(params.stt, params.accountType)
      .then(async (res: PrintDataEntitas) => {
        const resp = await this.getEstimationServiceLevelAgreement({
          startDate: res.sttCreatedAt,
          estimationSlaFormat: res.estimateSla,
          productType: res.sttProductType?.toUpperCase()
        });
        res.estimateSla = resp.maxDate;
        this.setPrintData(res);
        return true;
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Print Gagal !", () =>
            this.generatePrinData(params)
          )
        );
        return false;
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public generatePrintThermal(params: { stt: number; accountType: string }) {
    this.setIsLoadingPrint(true);
    const presenter = container.resolve(BookingPresenter);
    return presenter
      .generatePrintData(params.stt, params.accountType)
      .then(async (res: PrintDataEntitas) => {
        const resp = await this.getEstimationServiceLevelAgreement({
          startDate: res.sttCreatedAt,
          estimationSlaFormat: res.estimateSla,
          productType: res.sttProductType
        });
        res.estimateSla = resp.maxDate;
        return res;
      })
      .catch((error: any) => {
        this.setErrorPrint(true);
        this.setErrorCausePrint(error.response ? "server" : "internet");
        return false;
      })
      .finally(() => {
        this.setIsLoadingPrint(false);
      });
  }

  @Action
  public getShipmentIdList(params: {
    id: string;
    limit: number;
    page: number;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const posCity = AccountController.accountData.account_type_detail.cityCode
    const presenter = container.resolve(BookingPresenter);
    return presenter
      .getShipmentIdList(params.id, params.limit, params.page)
      .then((res: ShipmentIdList) => {
        const validationOriginDistrict = res.data[0].shipmentOriginCity !== posCity || !res.data[0].shipmentOriginCity
        const validationBukaSend = params.id.startsWith("B1") || params.id.startsWith("B2")
        if (params.id.startsWith("ACR") || params.id.startsWith("CCR")) {
          playNotification("error");
          this.setGenerate(false);
          this.setShipmentIdList(
            new ShipmentIdList({
              pagination: new Pagination(1, 10),
              data: []
            })
          );
          MainAppController.showErrorMessage(
            new ErrorMessageEntities({
              title: "Shipment ID CODREJ",
              message: `Silahkan update STT pengiriman ke <strong>Custom Status</strong>
              <strong>RTS/RTSHQ</strong> melalui akun <strong>konsolidator/sub 
              konsolidator</strong> untuk generate STT pengembalian`,
              type: SPECIAL_ERROR
            })
          );
        } 
        else if (validationBukaSend && validationOriginDistrict) {
          playNotification("error");
          this.setGenerate(false);
          this.setShipmentIdList(
            new ShipmentIdList({
              pagination: new Pagination(1, 10),
              data: []
            })
          );
          MainAppController.showErrorMessage(
            new ErrorMessageEntities({
              title: "Booking Gagal",
              message: `Kota asal shipment id harus sesuai dengan 
              wilayah kota POS Anda.`,
              type: FAILED_ERROR
            })
          );
        }
        else {
          const newData = res.data.map(item => ({
            ...item,
            shipmentSenderPhone: item.shipmentSenderPhone.replace(/\+/g, ""),
            shipmentRecipientPhone: item.shipmentRecipientPhone.replace(
              /\+/g,
              ""
            ),
            shipmentProductTemp: item.shipmentProduct
          }))
          const isIncludesJumbopack = res.data.filter((key: any)=> key.shipmentProduct.toLowerCase() === "jumbopack")
          if (isIncludesJumbopack.length && !this.dataProfile.isPartnerPcu) {
            this.showValidationJumbopack(newData);
            return;
          }
          playNotification("success");
          res = {
            ...res,
            data: newData
          };
          const isIncludesSameday = res.data.filter((key: any) => key.shipmentProduct.toLowerCase() === "sameday").length > 0 && ((res.data[0]?.shipmentId.startsWith('AG') ||
          res.data[0]?.shipmentId.startsWith('AD')) ||
          (res.data[0]?.shipmentAlgoId.startsWith('AG') ||
            res.data[0]?.shipmentAlgoId.startsWith('AD')))
          this.setIsSameDayProduct(isIncludesSameday)
          this.setShipmentIdList(res);
          this.setGenerate(true);

          if (FlagsMedusa.flag_booking_woodpacking_enable.isEnabled()) {
            this.manualBooking.isWoodpack = res.data[0].shipmentIsWoodpacking;
          }
        }
        return true;
      })
      .catch((error: any) => {
        playNotification("error");
        this.setGenerate(false);
        this.setShipmentIdList(
          new ShipmentIdList({
            pagination: new Pagination(1, 10),
            data: []
          })
        );
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Penambahan Shipment ID Gagal !")
        );
        return false;
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public showValidationJumbopack(res: any) {
      playNotification("error");
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: "Penambahan Shipment ID Gagal !",
          message: `Shipment ID <strong>“${res[0].shipmentId || res[0].shipmentAlgoId || res[0].shipmentPacketId}”</strong> hanya bisa diproses oleh POS PCU karena pengiriman menggunakan product Jumbopack. Silahkan hubungi pengirim ${res[0].shipmentSenderName}, ${res[0].shipmentSenderPhoneOriginal} untuk melakukan pengembalian.`,
          textSuccess: "OK",
          onSubmit: () => MainAppController.closeMessageModal(),
          image: "circle-error",
          idButtonSubmit: "add_shipment_pop_up_button_error_partner_not_pcu"
        })
      );
  }
  
  @Mutation
  public setIsSameDayProduct(bool: boolean) {
    this.isSameDayProduct = bool;
  }

  @Action
  public async getShipmentDetailFromMiddleware(params: { ticketCode: string }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(MiddlewarePresenter);
    return presenter
      .getShipmentDetailMiddleWare(params.ticketCode)
      .then((res: ShipmentIdList) => {
        const newData = res.data.map(item => ({
          ...item,
          shipmentSenderPhone: item.shipmentSenderPhone.replace(/\+/g, ""),
          shipmentRecipientPhone: item.shipmentRecipientPhone.replace(
            /\+/g,
            ""
          )
        }))
        const isIncludesJumbopack = res.data.filter((key: any)=> key.shipmentProduct.toLowerCase() === "jumbopack")
        if (isIncludesJumbopack.length && !this.dataProfile.isPartnerPcu) {
          this.showValidationJumbopack(newData);
          return;
        }
        res = {
          ...res,
          data: newData
        };
        this.setShipmentIdList(res);
        this.setGenerate(true);
        return true;
      })
      .catch((error: any) => {
        playNotification("error");
        this.setGenerate(false);
        this.setShipmentIdList(
          new ShipmentIdList({
            pagination: new Pagination(1, 10),
            data: []
          })
        );
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Generate Gagal !", () =>
            this.getShipmentDetailFromMiddleware(params)
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public async cancelBooking(params: CancelBooking) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(BookingPresenter);
    return presenter
      .cancelBooking(params)
      .then((resp: ResponsePayload) => {
        return resp;
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Pembatalan Gagal !", () =>
            this.cancelBooking(params)
          )
        );
        return new ResponsePayload();
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public async editBooking(payload: EditBooking) {
    this.initCreateBooking();
    const presenter = container.resolve(BookingPresenter);
    return presenter
      .editBooking(
        payload.id,
        payload
      )
      .then((res: ResponsePayload) => {
        this.setMessageSuccess(!AccountController.accountData.accountIsForeign 
          ? `Pengiriman berhasil terbooking dengan STT "${res.sttNumber}"`
          : `Shipment successfully booked with STT number "${res.sttNumber}"`
        );
        this.setShowModalSuccess(true);
        this.setErrorEditBooking(false);
        this.setErrorCauseEditBooking("");
        return res;
      })
      .catch(async (err: any) => {
        this.generateErrorBooking({
          err: err, 
          payload: payload, 
          errorFor: "book", 
          calllFunctionAgain: this.editBooking
        });
        return false;
      })
      .finally(() => {
        this.finalCreateBooking();
      });
  }

  @Action
  public async editBookingThenPrint(payload: EditBooking) {
    this.initCreateBooking();
    const presenter = container.resolve(BookingPresenter);
    return presenter
      .editBooking(
        payload.id,
        payload
      )
      .then((res: ResponsePayload) => {
        this.setMessageSuccess(!AccountController.accountData.accountIsForeign 
          ? `Pengiriman berhasil terbooking dengan STT "${res.sttNumber}"`
          : `Shipment successfully booked with STT number "${res.sttNumber}"`
        );
        this.setSTTID(res.sttId);
        this.setShowModalSuccess(true);
        this.setErrorEditBooking(false);
        this.setErrorCauseEditBooking("");
        return res;
      })
      .catch(async (err: any) => {
        this.generateErrorBooking({
          err: err, 
          payload: payload, 
          errorFor: "book", 
          calllFunctionAgain: this.editBookingThenPrint
        });
        return false;
      })
      .finally(() => {
        this.finalCreateBooking();
      });
  }

  @Action 
  public getExistCustomer(params: {
    customer: string;
    phone: string;
    address: string;
  }) {
    const presenter = container.resolve(BookingPresenter);
    return presenter.getExistCustomer(params.customer, params.phone, params.address);
  }

  @Action
  public getExistSender(params: {
    customer: string;
    phone: string;
    address: string;
  }) {
    const presenterSender = this.getExistCustomer(params);
      presenterSender.then(() => {
        this.setExistSender({ existSender: true });
      }).catch((err: any) => {
        this.setExistSender({ existSender: false });
      })
  }

  @Action
  public getExistReceiver(params: {
    customer: string;
    phone: string;
    address: string;
  }) {
    const presenterReceiver = this.getExistCustomer(params);
      presenterReceiver.then(() => {
        this.setExistReceiver({ existReceiver: true });
      })
      .catch(() => {
        this.setExistReceiver({ existReceiver: false });
      })
  }

  @Action
  public getProductsDelivery(params: {
    search: string;
    page: number;
    limit: number;
    status: string;
  }) {
    this.setLoadingProductsDelivery(true);
    const presenter = container.resolve(BookingPresenter);
    presenter
      .getProductsDelivery(
        params.search,
        params.page,
        params.limit,
        params.status
      )
      .then((res: ProductDelivery) => {
        this.setProductsDelivery(res);
        this.setErrorGetProductsDelivery(false);
        this.setErrorCauseGetProductsDelivery("");
      })
      .catch((error: any) => {
        this.setErrorGetProductsDelivery(true);
        this.setErrorCauseGetProductsDelivery(
          error.response ? "server" : "internet"
        );
      })
      .finally(() => {
        this.setLoadingProductsDelivery(false);
      });
  }

  @Action
  public async getInsuranceTypesDelivery(params: { insuranceConfig: number }) {
    MainAppController.showLoading();
    const presenter = container.resolve(BookingPresenter);
    return presenter
      .getInsuranceTypesDelivery(params.insuranceConfig)
      .then((res: InsuranceTypeDelivery) => {
        this.setAllInsuranceTypeDelivery(res);
        this.setInsuranceTypeDelivery(res);
        this.setErrorGetInsuranceTypeDelivery(false);
        this.setErrorCauseGetInsuranceTypeDelivery("");
        return res.data;
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Gagal mendapatkan data asuransi!", () =>
            this.getInsuranceTypesDelivery(params)
          )
        );
        return [];
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public async validateSttManual(params: {
    clientId: number;
    sttManual: string;
    bookingType: string;
    productType: string;
  }) {
    const presenter = container.resolve(ShipmentBookingPresenter);
    return presenter
      .validateSttManual(
        params.clientId,
        params.sttManual,
        params.bookingType,
        params.productType
      )
      .then((res: SttManualValidation) => {
        return res;
      })
      .catch((err: any) => {
        return new SttManualValidation(
          false,
          err.response.data.message.id || "Nomor STT tidak ditemukan"
        );
      });
  }

  @Action
  public async addBookingCorporate(payload: AddBookingCorporate) {
    this.initCreateBooking();
    const presenter = container.resolve(BookingPresenter);
    return presenter
      .addBookingCorporate(payload)
      .then(async (res: ResponsePayload) => {
        await this.onSuccessBooking({
          sttNumber: res.sttNumber,
          sttId: res.sttId
        });
        return true;
      })
      .catch(async (err: any) => {
        this.generateErrorBooking({
          err: err, 
          payload: payload, 
          errorFor: "book", 
          calllFunctionAgain: this.addBookingCorporate
        });
        return false;
      })
      .finally(() => {
        this.finalCreateBooking();
      });
  }

  @Action
  async validatePhone(params: {
    shipmentId: string;
    phoneSender: string;
    phoneReceiver: string;
  }) {
    this.setErrorPhoneNumber("");
    const presenter = container.resolve(BookingPresenter);
    return presenter
      .validateBookinbPhone(
        new ValidateBookingPhone(
          params.shipmentId,
          params.phoneSender,
          params.phoneReceiver
        )
      )
      .then(() => this.setErrorPhoneNumber(""))
      .catch(err =>
        this.setErrorPhoneNumber(
          err.response ? err.response.data.message.id : ""
        )
      );
  }

  @Action
  public async generatePrinDataBookingInstant(params: {
    stt: number;
    accountType: string;
  }) {
    this.setPrintData(new PrintDataEntitas());
    const presenter = container.resolve(BookingPresenter);
    return presenter
      .generatePrintData(params.stt, params.accountType)
      .then(async (res: PrintDataEntitas) => {
        const resp = await this.getEstimationServiceLevelAgreement({
          startDate: res.sttCreatedAt,
          estimationSlaFormat: res.estimateSla,
          productType: res.sttProductType
        });
        res.estimateSla = resp.maxDate;
        this.setPrintData(res);
        return true;
      })
      .catch((error: any) => {
        return false;
      });
  }

  @Action
  public async getShipmentIdBookingInstanList(params: {
    id: string;
    limit: number;
    page: number;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(BookingPresenter);
    return presenter
      .getShipmentIdList(params.id, params.limit, params.page)
      .then((res: ShipmentIdList) => {
        playNotification("success");
        res = {
          ...res,
          data: res.data.map(item => ({
            ...item,
            shipmentSenderPhone: item.shipmentSenderPhone.replace(/\+/g, ""),
            shipmentRecipientPhone: item.shipmentRecipientPhone.replace(
              /\+/g,
              ""
            )
          }))
        };
        this.setShipmentIdList(res);
        this.setGenerate(true);
        return true;
      })
      .catch((err: any) => {
        playNotification("error");
        this.setGenerate(false);
        this.setShipmentIdList(
          new ShipmentIdList({
            pagination: new Pagination(1, 10),
            data: []
          })
        );
        if (err.response) {
          if (err.response.status < 500) {
            MainAppController.showErrorMessage(
              new ErrorMessageEntities({
                image: "circle-error",
                title: "Penambahan Shipment ID Gagal !",
                message: err.response.data.message.id,
                onTryAgain: () => this.getShipmentIdBookingInstanList(params)
              })
            );

            return false;
          }
          MainAppController.showErrorMessage(
            new ErrorMessageEntities({
              type: INTERNAL_SERVER_ERROR,
              onTryAgain: () => this.getShipmentIdBookingInstanList(params)
            })
          );
          return false;
        }
        MainAppController.showErrorMessage(
          new ErrorMessageEntities({
            type: INTERNET_ERROR,
            onTryAgain: () => this.getShipmentIdBookingInstanList(params)
          })
        );

        return false;
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public async addBookingCorporateInstantBooking(payload: AddBookingCorporate) {
    const presenter = container.resolve(BookingPresenter);
    return presenter.addBookingCorporate(payload);
  }

  @Action
  public async getEstimationPriceCodRetail(payload: GetEstimationPrice) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(BookingPresenter);
    return presenter
      .getEstimationPriceCodRetail(payload)
      .then((res: EstimationCodRetail) => {
        this.setEstimationPriceCod(res);
        return true;
      })
      .catch((err: any) => {
        this.setDefaultEstimationPrice();
        if (!payload.hideError) {
          this.generateErrorBooking({
            err: err, 
            payload: payload, 
            errorFor: "estimation", 
            calllFunctionAgain: this.getEstimationPriceCodRetail
          });
        }
        
        return false;
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public async postToGetPosterProduct(payload: SubmitPosterProductApiRequest) {
    const presenter = container.resolve(BookingPresenter);
    return presenter.postToGetPosterProduct(payload);
  }

  @Action
  public uploadHydraFile(payload: UploadHydraFileRequest) {
    const presenter = container.resolve(BookingPresenter);
    return presenter.uploadHydraFile(payload);
  }

  @Action
  public deleteHydraFile(payload: DeleteHydraFileRequest) {
    const presenter = container.resolve(BookingPresenter);
    return presenter.deleteHydraFile(payload);
  }
  
  get dataProfile() {
    return AccountController.accountData;
  }

  forceRouter = false;

  @Mutation
  setForceRouter(bool: boolean) {
    this.forceRouter = bool;
  }

  @Mutation
  public setErrorDestination(val: boolean) {
    this.errorDestination = val;
  }

  @Mutation
  public setErrorPhoneNumber(val: string) {
    this.errorPhoneNumber = val;
  }

  @Mutation
  public setIsVendorNinjaDestination(val: boolean) {
    this.isVendorNinjaDestination = val;
  }

  @Mutation
  public setLoadingProductsDelivery(val: boolean) {
    this.loadingProductsDelivery = val;
  }

  @Mutation
  public setLoadingInsuranceTypeDelivery(val: boolean) {
    this.loadingInsuranceTypeDelivery = val;
  }

  @Mutation
  public setProductsDelivery(data: ProductDelivery) {
    this.productsDelivery = data;
  }

  @Mutation
  public setInsuranceTypeDelivery(data: InsuranceTypeDelivery) {
    const insuranceFiltered = data.data.filter(item => {
      if (IS_HIDE_INSURANCE_PREMIUM) {
        return item.value !== "premium";
      }
      return true;
    });
    this.insuranceTypeDelivery.data = insuranceFiltered;
  }

  @Mutation
  public setAllInsuranceTypeDelivery(data: InsuranceTypeDelivery) {
    this.allInsuranceTypeDelivery.data = data.data;
  }

  @Mutation
  public setErrorGetProductsDelivery(val: boolean) {
    this.errorGetProductsDelivery = val;
  }

  @Mutation
  public setErrorGetInsuranceTypeDelivery(val: boolean) {
    this.errorGetInsuranceTypeDelivery = val;
  }

  @Mutation
  public setErrorCauseGetProductsDelivery(val: string) {
    this.errorCauseGetProductsDelivery = val;
  }

  @Mutation
  public setErrorCauseGetInsuranceTypeDelivery(val: string) {
    this.errorCauseGetInsuranceTypeDelivery = val;
  }

  @Mutation
  setShipmentIdList(data: ShipmentIdList) {
    this.shipmentIdList = data;
  }

  @Mutation
  resetShipmentIdList() {
    this.shipmentIdList = new ShipmentIdList({
      pagination: new Pagination(1, 10),
      data: []
    });
  }

  @Mutation
  private setCustomerList(data: CustomerListEntities) {
    this.customerList = data;
  }

  @Mutation
  public setNameSender(value: string) {
    this.manualBooking.sender = value;
  }

  @Mutation
  public setManualBooking(value: ManualBooking) {
    this.manualBooking = value;
  }

  @Mutation
  public setNameReceiver(value: string) {
    this.manualBooking.receiver = value;
  }

  @Mutation
  public setEmptyDestination(value: string) {
    this.manualBooking.destinationCity = value;
  }

  @Mutation
  public setDestinationCity(value: any) {
    this.manualBooking.destinationCity = value;
  }

  @Mutation
  public setCommodity(value: any) {
    this.manualBooking.commodityCode = value;
  }

  @Mutation
  public setDataSender(item: any) {
    this.manualBooking.sender = item.customerName;
    this.manualBooking.addressSender = item.customerAddress;
    this.manualBooking.phoneCodeSender = item.customerPhoneCode;
    this.manualBooking.phoneFlagSender = item.customerPhoneflag;
    this.manualBooking.phoneSender = item.customerPhoneNumber;
  }

  @Mutation
  public setDataReceiver(item: any) {
    this.manualBooking.receiver = item.customerName;
    this.manualBooking.addressReceiver = item.customerAddress;
    this.manualBooking.phoneCodeReceiver = item.customerPhoneCode;
    this.manualBooking.phoneFlagReceiver = item.customerPhoneflag;
    this.manualBooking.phoneReceiver = item.customerPhoneNumber;
  }

  @Mutation
  public setStatusGoods(key: string) {
    this.manualBooking.statusGoods = key;
  }

  @Mutation
  public setProductName(key: string) {
    this.manualBooking.productName = key;
  }

  @Mutation
  public setPhoneSender(key: string) {
    this.manualBooking.phoneSender = key;
  }

  @Mutation
  public setPhoneCodeSender(key: string) {
    this.manualBooking.phoneCodeSender = key;
  }

  @Mutation
  public setPhoneFlagSender(key: string) {
    this.manualBooking.phoneFlagSender = key;
  }

  @Mutation
  public setPhoneReceiver(key: string) {
    this.manualBooking.phoneReceiver = key;
  }

  @Mutation
  public setPhoneCodeReceiver(key: string) {
    this.manualBooking.phoneCodeReceiver = key;
  }

  @Mutation
  public setPhoneFlagReceiver(key: string) {
    this.manualBooking.phoneFlagReceiver = key;
  }

  @Mutation
  public setCOD(key: boolean) {
    this.manualBooking.isCOD = key;
  }

  @Mutation
  public setDfod(key: boolean) {
    this.manualBooking.isDFOD = key;
  }

  @Mutation
  public setCODAmount(key: number) {
    this.manualBooking.codAmount = key;
  }

  @Mutation
  public setLoading(key: boolean) {
    this.isLoading = key;
  }

  @Mutation
  public setLoadingEditBooking(key: boolean) {
    this.isLoadingEditBooking = key;
  }

  @Mutation
  private setMessageSuccess(key: string) {
    this.messageSuccess = key;
  }

  @Mutation
  public setError(val: boolean) {
    this.isError = val;
  }

  @Mutation
  public setErrorEditBooking(val: boolean) {
    this.isErrorEditBooking = val;
  }

  @Mutation
  public setErrorCause(val: string) {
    this.isErrorCause = val;
  }

  @Mutation
  public setErrorCauseEditBooking(val: string) {
    this.isErrorCauseEditBooking = val;
  }

  @Mutation
  public setErrorPrint(val: boolean) {
    this.isErrorPrint = val;
  }

  @Mutation
  public setErrorCausePrint(val: string) {
    this.isErrorCausePrint = val;
  }

  @Mutation
  public setIsLoadingPrint(val: boolean) {
    this.isLoadingPrint = val;
  }

  @Mutation
  public setIsLoadingCustomer(val: boolean) {
    this.isLoadingCustomer = val;
  }

  @Mutation
  private setBookingList(data: any) {
    this.bookingData = data;
  }

  @Mutation
  public setEstimationPrice(data: EstimationPrice) {
    this.estimationPrice = data;
  }

  @Mutation
  public setEstimationPriceCod(data: EstimationCodRetail) {
    this.estimationCodRetail = data;
  }

  @Mutation
  public setDefaultEstimationPrice() {
    this.estimationPrice = new EstimationPrice();
  }

  @Mutation
  public setPrintData(data: PrintDataEntitas) {
    this.generatePrintData = data;
  }

  @Mutation
  public setExistSender(val: any) {
    this.existSender = val;
  }

  @Mutation
  public setExistReceiver(val: any) {
    this.existReceiver = val;
  }

  @Action
  public searchAction(value: string) {
    this.setSearch(value);
    // to do logic search
  }

  @Action
  public clear() {
    this.searchAction("");
  }

  @Mutation
  private setSearch(value: string) {
    this.search = value;
  }

  @Mutation
  public setSTTID(value: number) {
    this.sttId = value;
  }

  @Mutation
  public setShowModalManualBooking(value: boolean) {
    this.showModalManualBooking = value;
  }

  @Mutation
  public setShowModalSuccess(value: boolean) {
    this.showModalSuccess = value;
  }

  @Mutation
  public setDefaultManualBooking() {
    this.manualBooking = new ManualBooking();
  }

  public reRenderManualBooking = 0;

  @Mutation
  setIncrementManualBooking() {
    this.reRenderManualBooking = this.reRenderManualBooking + 1;
  }

  @Mutation
  public setErrorSpesificRate(status: boolean) {
    this.errorSpesificByRate = status;
  }

  @Mutation
  public setErrorEstSpesificRate(status: boolean) {
    this.errorSpesificEstByRate = status;
  }

  @Mutation
  public setTitleErrorspesification(val: string) {
    this.titleErrorspesification = val;
  }

  @Mutation
  public setErrorEmbargo(status: boolean) {
    this.isErrorEmbargo = status;
  }

  @Mutation
  public setGenerate(status: boolean) {
    this.isGenerate = status;
  }

  @Mutation
  public setWeightDimensions(data: any) {
    this.weightDimensions.data.push(data);
  }

  @Mutation
  public removeWeightDimensions() {
    this.weightDimensions.data = [];
  }

  @Mutation
  public setGuardBookingSubmit(status: boolean) {
    this.guardBookingSubmit = status;
  }

  // request estimation service level agreement
  @Action
  async getEstimationServiceLevelAgreement(params: {
    startDate: any;
    estimationSlaFormat: string;
    productType: string;
  }) {
    const split = params.estimationSlaFormat.split(" ");
    const minSla = Number(split[0]);
    const maxSla = Number(split[2]);
    const presenter = container.resolve(BookingPresenter);
    return presenter
      .getEstimationServiceLevel(
        new RequestEstimationServiceLevelAgreement({
          cache: true,
          startDate: params.startDate,
          min: minSla,
          max: maxSla,
          productType: params.productType
        })
      )
      .then((resp: EstimationServiceLevelAgreement) => {
        return resp;
      })
      .catch(() => {
        return new EstimationServiceLevelAgreement({
          minDate: formatDateWithoutTime(
            moment(params.startDate).add(minSla, "days")
          ),
          maxDate: formatDateWithoutTime(
            moment(params.startDate).add(maxSla, "days")
          )
        });
      });
  }

  // postal code
  @Mutation
  setPostalCodeDestination(item: any) {
    if (typeof item === "object") {
      this.manualBooking.postalCodeDestination = {
        id: item.id,
        name: item.name
      }
    } else {
      this.manualBooking.postalCodeDestination = "";
    }
  }

  @Action
  checkTransportAndEstimateSlaBooking(params: {
    originCity: string;
    destinationCity: string;
    originDistrict: string;
    destinationDistrict: string;
    product: string;
  }): Promise<{
    slaMin: number;
    slaMax: number;
    transport: string;
  }> {
    if (SLAFullfilled(params)) {
      try {
        const bookingPresenter = container.resolve(BookingPresenter);
        const legPresenter = container.resolve(LegPresenter);
        const requestEstimateSla = bookingPresenter.getEstimateSlaDetail(
          new RequestEstimateSlaDetail({
            cache: true,
            originId: params.originDistrict,
            destinationId: params.destinationDistrict,
            product: params.product,
            slaType: "district-district"
          })
        );
        const requestLeg = legPresenter.getListRouteLeg(
          new RequestListRouteLeg({
            cache: true,
            originId: params.originCity,
            destinationId: params.destinationCity
          })
        );
        return Promise.all([requestEstimateSla, requestLeg]).then(responses => {
          const filteredLegData = responses[1].filter(
            item => item.status === "active"
          );
          const defaultLegData = maxBy(filteredLegData, item => {
            return item.id;
          });
          return {
            slaMin: Number(responses[0].estimateSlaMin),
            slaMax: Number(responses[0].estimateSlaMax),
            transport: defaultLegData?.transport || ""
          };
        });
      } catch (e) {
        return new Promise<{
          slaMin: number;
          slaMax: number;
          transport: string;
        }>(resolve => {
          return resolve({
            slaMax: 0,
            slaMin: 0,
            transport: ""
          });
        });
      }
    }
    return new Promise<{ slaMin: number; slaMax: number; transport: string }>(
      resolve => {
        return resolve({
          slaMax: 0,
          slaMin: 0,
          transport: ""
        });
      }
    );
  }
}

export const BookingController = getModule(BookingStore);
