import { AxiosResponse } from "axios";
import {
  RefundConfigurationData,
  ConfigurableRefund,
  RefundOriginCityExcludeDetail
} from "@/domain/entities/RefundConfiguration";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

export class RefundConfigurationMapper {
  public convertDetailRefundConfigurationFromApi(
    res: AxiosResponse<any>
  ): RefundConfigurationData {
    const {
      data: { data }
    } = res;
    return new RefundConfigurationData({
      configurablePriceId: data.configurable_price_id,
      configurablePriceName: data.configurable_price_name,
      configurablePriceType: data.configurable_price_type,
      configurablePriceDescription: data.configurable_price_description,
      configurablePriceStatus: data.configurable_price_status,
      configurablePriceCreatedAt: data.configurable_price_created_at,
      configurablePriceCreatedBy: data.configurable_price_created_by,
      configurablePriceUpdatedAt: data.configurable_price_updated_at,
      configurablePriceUpdatedBy: data.configurable_price_updated_by,
      configurableRefund: data.configurable_refund.length
        ? data.configurable_refund.map(
            (item: any) =>
              new ConfigurableRefund({
                refundId: item.refund_id,
                refundConfigurablePriceId: item.refund_configurable_price_id,
                refundSttLastStatus: item.refund_stt_last_status,
                refundAmount: item.refund_amount.toLocaleString("id"),
                refundPenalty: item.refund_penalty.toLocaleString("id"),
                refundQuantifier: item.refund_quantifier,
                refundAmountInsurance: item.refund_amount_insurance,
                refundOriginCityExclude: item.refund_origin_city_exclude,
                refundOriginCityExcludeDetail: item
                  .refund_origin_city_exclude_detail.length
                  ? item.refund_origin_city_exclude_detail.map(
                      (item: any) =>
                        new RefundOriginCityExcludeDetail({
                          name: item.name,
                          code: item.code,
                          freeTradeZone: item.free_trade_zone
                        })
                    )
                  : []
              })
          )
        : []
    });
  }

  public convertPayloadData(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
