import { STIMapper } from "@/data/persistences/mappers/STIMapper";
import { STIRepositoryInterface } from "@/data/persistences/repositories/contracts/STIRepositoryInterface";
import {
  DetailSttPiecesSTI,
  STIEntities,
  StiManifestData
} from "@/domain/entities/STI";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { StiRequestInterface } from "@/data/payload/contracts/StiApiRequest";
import { GenerateStiRequest } from "@/data/payload/api/StiApiRequest";
import { downloadFile } from "../../misc/Utils";
import { StiListRequest } from "@/data/payload/api/StiApiRequest";

export class STIApiRepository implements STIRepositoryInterface {
  private service: ApiService;
  private mapper: STIMapper;
  private endpoints: Endpoints;

  constructor(service: ApiService, mapper: STIMapper, endpoints: Endpoints) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async generateSti(
    payload: StiRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.generateSti(),
      undefined,
      payload as GenerateStiRequest
    );
    return this.mapper.convertResponsePayload(resp);
  }

  public async getDetailSttPieces(
    sttNumber: string
  ): Promise<DetailSttPiecesSTI> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailSttPiecesSTI(sttNumber),
      {}
    );
    return this.mapper.convertSttPiecesStiDetailFromApi(resp);
  }

  public async getDetailSttPiecesLilo(
    bagNo: string
  ): Promise<DetailSttPiecesSTI> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailSttPiecesSTILilo(bagNo),
      {}
    );
    return this.mapper.convertSttPiecesStiDetailFromApi(resp);
  }

  public async getListSTI(params: StiListRequest): Promise<STIEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getSTIList(params),
      {}
    );
    return this.mapper.convertSTIDataFromApi(resp);
  }

  public async validationScanSTTPiece(
    sttPiece: string
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.validationScanSTISTTPiece(sttPiece),
      {}
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async getStiManifestData(id: number): Promise<StiManifestData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getStiManifestData(id),
      {}
    );
    return this.mapper.convertStiManifestDataFromApi(resp);
  }

  public async downloadSti(id: number): Promise<any> {
    return downloadFile(
      `${Endpoints.baseUrl}${this.endpoints.downloadManifest(
        `sti/excel/${id}`
      )}`,
      `STI_Manifest_${id}`,
      ".xlsx",
      true
    );
  }
}
