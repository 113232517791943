import ApiService from "@/app/infrastructures/services/ApiService";
import { LocationDataMapper } from "@/data/persistences/mappers/LocationDataMapper";
import { LocationRepositoryInterface } from "@/data/persistences/repositories/contracts/LocationRepositoryInterface";
import {
  CityEntities,
  CityData,
  CountryEntities,
  CountryData,
  DistrictEntities,
  DistrictData,
  UrsaCodeData,
  DistrictClient,
  RegionEntities
} from "@/domain/entities/Location";
import { Endpoints } from "@/app/infrastructures/misc/Endpoints";
import {
  EditRequestInterface,
  EditCountryRequestInterface
} from "@/data/payload/contracts/LocationRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  EditCityApiRequest,
  EditCountryApiRequest,
  EditDistrictApiRequest,
  DistrictClientRequest,
  RegionListRequest
} from "@/data/payload/api/LocationRequest";
import { ApiRequestList } from "@/domain/entities/MainApp";
export class LocationApiRepository implements LocationRepositoryInterface {
  private service: ApiService;
  private mapper: LocationDataMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: LocationDataMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getDetailDistrictByDistrictCode(
    str: string
  ): Promise<DistrictData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailDistrictByDistrictCode(str),
      {}
    );
    return this.mapper.convertDetailDistrictDataFromApi(resp);
  }

  public async getCityList(params: ApiRequestList): Promise<CityEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListCityV2(params),
      {}
    );
    return this.mapper.convertCityDataFromApi(resp);
  }

  public async getDetailCity(id: any): Promise<CityData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailCity(id),
      {}
    );
    return this.mapper.convertDetailCityDataFromApi(resp);
  }

  public async getCountryList(
    search: string,
    status: string,
    page: number,
    limit: number
  ): Promise<CountryEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListCountry(search, status, page, limit),
      {}
    );
    return this.mapper.convertCountryDataFromApi(resp);
  }

  public async getDetailCountry(id: any): Promise<CountryData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailCountry(id),
      {}
    );
    return this.mapper.convertDetailCountryDataFromApi(resp);
  }

  public async getDistrictList(
    search: string,
    status: string,
    page: number,
    limit: number,
    excludeCountry: string,
    type: string,
    cache: boolean,
    cityCode?: string
  ): Promise<DistrictEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListDistrict(
        search,
        status,
        page,
        limit,
        excludeCountry,
        type,
        cityCode,
        cache
      ),
      {}
    );
    return this.mapper.convertDistrictDataFromApi(resp);
  }

  public async getDetailDistrict(id: any): Promise<DistrictData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailDistrict(id),
      {}
    );
    return this.mapper.convertDetailDistrictDataAccountFromApi(resp);
  }

  public async getRegionList(
    search: string,
    status: string,
    page: number,
    limit: number
  ): Promise<RegionEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListRegion(
        new RegionListRequest({
          page,
          limit,
          status,
          search
        })
      ),
      {}
    );
    return this.mapper.convertListRegionFromApi(resp);
  }

  public async editCity(
    id: number,
    payload: EditRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "PATCH",
      this.endpoints.editCity(id),
      undefined,
      payload as EditCityApiRequest
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async editCountry(
    id: number,
    payload: EditCountryRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokePostWithFormData(
      "PUT",
      this.endpoints.editCountry(id),
      undefined,
      payload as EditCountryApiRequest
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async getUrsaCode(ursaCode: string): Promise<UrsaCodeData> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getUrsaCode(ursaCode),
      {}
    );
    return this.mapper.convertUrsaCodeDataFromApi(resp);
  }

  public async refreshES(): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getRefreshES(),
      {}
    );
    return this.mapper.convertRefreshESFromApi(resp);
  }

  public async editDistrict(
    id: number,
    payload: EditRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invoke(
      "PATCH",
      this.endpoints.editDistrict(id),
      undefined,
      payload as EditDistrictApiRequest
    );
    return this.mapper.convertChangeDataFromApi(resp);
  }

  public async getDistrictClient(params: {
    id: number | string;
    args: DistrictClientRequest;
  }): Promise<DistrictClient> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDistrictClient(params),
      {}
    );
    return this.mapper.convertDistrictClientFromApi(resp);
  }
}
