import {
  ComodityTypeDetail,
  DestinationDetail,
  EmbargoData,
  EmbargoDetail,
  EmbargoEntities,
  OriginDetail,
  ProductTypeDetail
} from "@/domain/entities/Embargo";
import { Pagination } from "@/domain/entities/Pagination";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";

export class EmbargoDataMapper {
  public convertEmbargoFromApi(result: AxiosResponse<any>): EmbargoEntities {
    const { data } = result;
    const res: Array<EmbargoData> = [];
    if (!data)
      return new EmbargoEntities(false, 0, "", new Pagination(0, 0), []);
    else {
      data.data.map((item: any) => {
        res.push(
          new EmbargoData(
            item.embargo_id,
            item.embargo_name,
            item.embargo_origin_id,
            item.embargo_destination_id,
            item.embargo_product_type,
            item.embargo_commodity_type,
            item.embargo_max_weight,
            item.embargo_min_weight,
            item.embargo_start_date_time,
            item.embargo_end_datetime,
            item.embargo_created_at,
            item.embargo_created_by,
            item.embargo_updated_at,
            item.embargo_updated_by,
            item.status,
            item.service_type
          )
        );
      });
      return new EmbargoEntities(
        data.success,
        data.code,
        data.message,
        new Pagination(data.meta.page, data.meta.limit),
        res
      );
    }
  }

  public convertDetailEmbargoDataFromApi(
    result: AxiosResponse<any>
  ): EmbargoDetail {
    const {
      data: { data }
    } = result;

    return new EmbargoDetail(
      data.embargo_id,
      data.embargo_name,
      data.max_weight,
      data.start_date_time,
      data.end_date_time,
      data.status,
      data.product_type,
      data.product_type_detail
        ? data.product_type_detail.map((item: ProductTypeDetail) => {
            return new ProductTypeDetail(
              item.product_type_id,
              item.product_type_code,
              item.product_type_description
            );
          })
        : [],
      data.origin_id,
      data.origin_id.toLowerCase() === "all"
        ? [new OriginDetail(0, "ALL", "ALL")]
        : data.origin_detail
        ? data.origin_detail.map((item: OriginDetail) => {
            return new OriginDetail(item.id, item.name, item.code);
          })
        : [],
      data.destination_id,
      data.destination_id.toLowerCase() === "all"
        ? [new OriginDetail(0, "ALL", "ALL")]
        : data.destination_detail
        ? data.destination_detail.map((item: DestinationDetail) => {
            return new DestinationDetail(item.id, item.name, item.code);
          })
        : [],
      data.commodity_type,
      data.commodity_type.toLowerCase() === "all"
        ? [new ComodityTypeDetail("ALL", "ALL", "ALL")]
        : data.commodity_type_detail
        ? data.commodity_type_detail.map((item: ComodityTypeDetail) => {
            return new ComodityTypeDetail(
              item.commodity_id,
              item.commodity_code,
              item.commodity_name
            );
          })
        : [],
      data.created_at,
      data.updated_at,
      data.created_by,
      data.updated_by,
      data.embargo_delivery_type,
      data.service_type
    );
  }

  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
