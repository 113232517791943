import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "loader rounded-full border-2 border-t-2 h-4 w-4 mx-auto"
}
const _hoisted_2 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DownloadDocument = _resolveComponent("DownloadDocument")!

  return (_openBlock(), _createBlock("button", {
    id: _ctx.id,
    type: "submit",
    class: ["flex flex-row items-center gap-x-2 justify-center text-12px xxl:text-14px border border-red-lp-100 text-red-lp-100 py-1.5 xxl:py-3 font-semibold rounded-md focus:outline-none", _ctx.custom],
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClick())),
    disabled: _ctx.isDisabled
  }, [
    (_ctx.iconLeft)
      ? (_openBlock(), _createBlock("img", {
          key: 0,
          src: require(`@/app/ui/assets/svg/${_ctx.iconLeft}.svg`),
          class: "w-5",
          alt: _ctx.iconLeft
        }, null, 8, ["src", "alt"]))
      : _createCommentVNode("", true),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    (!_ctx.isLoading)
      ? (_openBlock(), _createBlock("span", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    (_ctx.icon === 'document-download')
      ? (_openBlock(), _createBlock(_component_DownloadDocument, {
          key: 3,
          class: "w-3 ml-2"
        }))
      : (_ctx.icon)
        ? (_openBlock(), _createBlock("img", {
            key: 4,
            src: require(`@/app/ui/assets/svg/${_ctx.icon}.svg`),
            class: "w-5",
            alt: _ctx.icon
          }, null, 8, ["src", "alt"]))
        : _createCommentVNode("", true)
  ], 10, ["id", "disabled"]))
}