import { injectable } from "tsyringe";
import { LocationApiRepository } from "@/app/infrastructures/repositories/api/LocationApiRepository";
import {
  CityEntities,
  CityData,
  CountryEntities,
  CountryData,
  DistrictEntities,
  DistrictData,
  UrsaCodeData,
  DistrictClient,
  RegionEntities
} from "@/domain/entities/Location";
import {
  EditRequestInterface,
  EditCountryRequestInterface
} from "@/data/payload/contracts/LocationRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { DistrictClientRequest } from "@/data/payload/api/LocationRequest";
import { ApiRequestList } from "@/domain/entities/MainApp";

@injectable()
export class LocationPresenter {
  private repository: LocationApiRepository;

  constructor(repository: LocationApiRepository) {
    this.repository = repository;
  }

  public getCityList(params: ApiRequestList): Promise<CityEntities> {
    return this.repository.getCityList(params);
  }

  public getDetailCity(id: any): Promise<CityData> {
    return this.repository.getDetailCity(id);
  }

  public getCountryList(
    search: string,
    status: string,
    page: number,
    limit: number
  ): Promise<CountryEntities> {
    return this.repository.getCountryList(search, status, page, limit);
  }

  public getDetailCountry(id: any): Promise<CountryData> {
    return this.repository.getDetailCountry(id);
  }

  public getDistrictList(
    search: string,
    status: string,
    page: number,
    limit: number,
    excludeCountry: string,
    type: string,
    cache: boolean,
    cityCode?: string
  ): Promise<DistrictEntities> {
    return this.repository.getDistrictList(
      search,
      status,
      page,
      limit,
      excludeCountry,
      type,
      cache,
      cityCode
    );
  }

  public getDetailDistrict(id: any): Promise<DistrictData> {
    return this.repository.getDetailDistrict(id);
  }

  public getDetailDistrictByDistrictCode(str: string): Promise<DistrictData> {
    return this.repository.getDetailDistrictByDistrictCode(str);
  }

  public getRegionList(
    search: string,
    status: string,
    page: number,
    limit: number
  ): Promise<RegionEntities> {
    return this.repository.getRegionList(search, status, page, limit);
  }

  public editCity(
    id: number,
    payload: EditRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.editCity(id, payload);
  }

  public editCountry(
    id: number,
    payload: EditCountryRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.editCountry(id, payload);
  }

  public getUrsaCode(ursaCode: string): Promise<UrsaCodeData> {
    return this.repository.getUrsaCode(ursaCode);
  }

  public refreshES(): Promise<ResponsePayload> {
    return this.repository.refreshES();
  }

  public editDistrict(
    id: number,
    payload: EditRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.editDistrict(id, payload);
  }

  public getDistrictClient(params: {
    id: number | string;
    args: DistrictClientRequest;
  }): Promise<DistrictClient> {
    return this.repository.getDistrictClient(params);
  }
}
