import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import {
  BaggingEntities,
  BaggingDataDetail,
  BaggingSttList,
  SttResult,
  PayloadCreateBagging,
  PayloadUpdateReadyToCargo
} from "@/domain/entities/Bagging";
import { Pagination } from "@/domain/entities/Pagination";
import { container } from "tsyringe";
import { BaggingPresenter } from "../presenters/BaggingPresenter";
import {
  BaggingListRequest,
  BaggingToReadyToCargoRequest,
  CreateApiRequest,
  UpdateBaggingRequest
} from "@/data/payload/api/BaggingApiRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { MainAppController } from "./MainAppController";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import { getHubData } from "@/app/infrastructures/misc/Cookies";
import { AccountController } from "./AccountController";
export interface BaggingState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
  baggingList: BaggingEntities;
  isLoadingUpdate: boolean;
  isErrorUpdate: boolean;
  isErrorCauseUpdate: string;
  baggingId: number;
  baggingDetail: BaggingDataDetail;
  loadingGetDetailSttNo: boolean;
  errorDetailSttNo: boolean;
  detailSttno: BaggingSttList;
  errorCauseDetailSttNo: string;
}

@Module({ namespaced: true, dynamic: true, store, name: "bagging" })
class BaggingStore extends VuexModule implements BaggingState {
  public isLoading = false;
  public isLoadingDetail = false;
  public isError = false;
  public errorCause = "";
  public filter: any = {
    startDate: "",
    endDate: "",
    search: ""
  };
  public periodeStart: any = "";
  public periodeEnd: any = "";
  public baggingList = new BaggingEntities(new Pagination(1, 10), []);
  public baggingDetail = new BaggingDataDetail();
  public isOpenSuccess = false;
  public isFailed = false;
  public isLoadingUpdate = false;
  public isErrorUpdate = false;
  public isErrorCauseUpdate = "";
  public baggingId = 0;
  public responseCreate = new ResponsePayload();
  public isNotification = false;
  public sttFailedUpdate: Array<any> = [];
  public loadingGetDetailSttNo = false;
  public errorDetailSttNo = false;
  public detailSttno = new BaggingSttList([]);
  public errorCauseDetailSttNo = "";
  public errorMessageBaggingRTC: any = {};

  @Action
  public getBaggingList(params: BaggingListRequest) {
    const requestParams = new BaggingListRequest({
      ...params,
      hubId: Number(getHubData()?.hubId || 0),
    });
    this.setIsLoading(true);
    const presenter = container.resolve(BaggingPresenter);
    return presenter
      .getListBagging(requestParams)
      .then((res: BaggingEntities) => {
        this.setBaggingList(res);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setErrorCause(err.response ? "server" : "internet");
        this.setBaggingList(new BaggingEntities(new Pagination(0, 0), []));
      })
      .finally(() => this.setIsLoading(false));
  }

  @Action
  public fetchBaggingList() {
    this.getBaggingList(
      new BaggingListRequest({
        page: this.baggingList.pagination.page,
        limit: this.baggingList.pagination.limit,
        search: encodeURIComponent(this.filter.search),
        startDate: this.periodeStart.toISOString().slice(0, 10),
        endDate: this.periodeEnd.toISOString().slice(0, 10)
      })
    );
  }

  @Action
  public getDetailBagging(id: number) {
    this.setIsLoadingDetail(true);
    const presenter = container.resolve(BaggingPresenter);
    return presenter
      .getDetailBagging(id)
      .then((res: BaggingDataDetail) => {
        this.setBaggingDetail(res);
        this.sortBagging(this.baggingDetail);
        return true;
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
        return false;
      })
      .finally(() => {
        this.setIsLoadingDetail(false);
      });
  }

  @Action
  private sortBagging(data: BaggingDataDetail) {
    const sortBySameDestination: any = [];
    const sortByOtherDestination: any = [];
    this.baggingDetail.sttResult.forEach((key: SttResult) => {
      key.sttDestinationCityId === data.destinationCity.split(" ")[0]
        ? sortBySameDestination.push(key)
        : sortByOtherDestination.push(key);
    });
    if (sortByOtherDestination.length) {
      sortByOtherDestination.sort((obj: SttResult, secondObj: SttResult) => {
        if (obj.sttDestinationCityId < secondObj.sttDestinationCityId) {
          return -1;
        }
        if (obj.sttDestinationCityId > secondObj.sttDestinationCityId) {
          return 1;
        }
        return 0;
      });
    }
    this.setSTTResult([...sortBySameDestination, ...sortByOtherDestination]);
  }

  @Action
  public getDetailSttNumber(params: {
    sttNumber: string;
    partnerId?: number;
    callback: any;
    noRef?: string;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(BaggingPresenter);
    return presenter
      .getDetailSttNumber(params.sttNumber, params.partnerId)
      .then((res: BaggingSttList) => {
        this.setDetailSttNo(res);
        params.callback(res.data, params.noRef);
        return res.data;
      })
      .catch((err: any) => {
        playNotification("error");
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, this.isAccountOutsideID ? "Failed Added STT" : "Tambah No. STT Gagal!", () =>
            this.getDetailSttNumber(params)
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  get isAccountOutsideID() {
    return AccountController.accountData.accountIsForeign;
  }

  @Action
  public async onGenerate(params: PayloadCreateBagging) {
    params.hubId = Number(getHubData()?.hubId || 0);
    params.hubName = getHubData()?.hubName || "";
    params.hubDistrictCode = getHubData()?.hubDistrictCode || "";
    params.hubOriginCity = getHubData()?.originCity || "";
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(BaggingPresenter);
    return presenter
      .generateBagging(
        new CreateApiRequest(
          params
        )
      )
      .then((res: ResponsePayload) => {
        this.setCreateBagging(res);
        this.setError(false);
        this.setErrorCause("");
        return res;
      })
      .catch(err => {
        if (err.response) {
          this.setFailed(true);
        } else {
          MainAppController.showErrorMessage(
            parsingErrorResponse(err, "Pembuatan Bagging Gagal!", () =>
              this.onGenerate(params)
            )
          );
        }
        this.setOpenSuccess(false);
        this.setNotification(false);
        return new ResponsePayload();
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public updateBagging(params: {
    bagId: number;
    customGrossWeight: number;
    customLength: number;
    customWidth: number;
    customHeight: number;
    bagSttNo: Array<any>;
    partnerId?: number;
  }) {
    this.setLoadingUpdate(true);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(BaggingPresenter);
    return presenter
      .updateBagging(
        new UpdateBaggingRequest(
          params.bagId,
          params.customGrossWeight,
          params.customLength,
          params.customWidth,
          params.customHeight,
          params.bagSttNo,
          params.partnerId
        )
      )
      .then((res: ResponsePayload) => {
        this.setBaggingingId(res.data.bagId);
        this.setErrorUpdate(false);
        this.setErrorCauseUpdate("");
        return res.data;
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Perubahan Bagging Gagal!", () =>
            this.updateBagging(params)
          )
        );
        return false;
      })
      .finally(() => {
        this.setLoadingUpdate(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public downloadBagging(id: number) {
    MainAppController.showLoading();
    const presenter = container.resolve(BaggingPresenter);
    presenter
      .downloadBagging(id)
      .catch(err =>
        MainAppController.showErrorMessage(parsingErrorResponse(err))
      )
      .finally(() => MainAppController.closeLoading());
  }

  @Action
  public getRegionBaggingGrouping(code: string) {
    const presenter = container.resolve(BaggingPresenter);
    return presenter.getRegionBaggingGrouping(code);
  }

  @Action
  public updateToRTCBagging(payload: PayloadUpdateReadyToCargo) {
    MainAppController.showLoading();
    const presenter = container.resolve(BaggingPresenter);
    return presenter
      .updateBaggingToReadyToCargo(new BaggingToReadyToCargoRequest(payload))
      .then((res: any) => {
        return true;
      })
      .catch((error: any) => {
        this.setErrorMessageBaggingRTC(error);
        return false;
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Mutation
  public setLoadingGetDetailSttNo(val: boolean) {
    this.loadingGetDetailSttNo = val;
  }

  @Mutation
  public setErrorDetailSttNo(val: boolean) {
    this.errorDetailSttNo = val;
  }

  @Mutation
  public setErrorMessageBaggingRTC(error: any) {
    this.errorMessageBaggingRTC = parsingErrorResponse(error);
  }

  @Mutation
  public setDetailSttNo(data: BaggingSttList) {
    this.detailSttno = data;
  }

  @Mutation
  public setErrorCauseDetailSttNo(err: string) {
    this.errorCauseDetailSttNo = err;
  }

  @Mutation
  private setCreateBagging(data: ResponsePayload) {
    this.responseCreate = data;
  }

  @Mutation
  public setResetCreateBagging() {
    this.responseCreate = new ResponsePayload();
  }

  @Mutation
  public setNotification(value: boolean) {
    this.isNotification = value;
  }

  @Mutation
  public setOpenSuccess(value: boolean) {
    this.isOpenSuccess = value;
  }

  @Mutation
  public setFailed(value: boolean) {
    this.isFailed = value;
  }

  @Mutation
  public setSttFailedUpdate(value: Array<any>) {
    this.sttFailedUpdate = value;
  }

  @Mutation
  private setBaggingList(data: BaggingEntities) {
    this.baggingList = data;
  }

  @Mutation
  private setBaggingDetail(data: BaggingDataDetail) {
    this.baggingDetail = data;
  }

  @Mutation
  public setSTTResult(data: any) {
    this.baggingDetail.sttResult = data;
  }

  @Mutation
  public setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  public setLoadingUpdate(value: boolean) {
    this.isLoadingUpdate = value;
  }

  @Mutation
  public setIsLoadingDetail(value: boolean) {
    this.isLoadingDetail = value;
  }

  @Mutation
  public setSearch(value: string) {
    this.filter.search = value;
    this.baggingList.pagination.page = 1;
  }

  @Mutation
  public setPeriodeStart(value: any) {
    this.periodeStart = value;
  }

  @Mutation
  public setPeriodeEnd(value: any) {
    this.periodeEnd = value;
  }

  @Mutation
  public setError(value: boolean) {
    this.isError = value;
  }

  @Mutation
  public setErrorUpdate(value: boolean) {
    this.isErrorUpdate = value;
  }

  @Mutation
  public setErrorCause(value: string) {
    this.errorCause = value;
  }

  @Mutation
  public setErrorCauseUpdate(value: string) {
    this.isErrorCauseUpdate = value;
  }

  @Mutation
  public setBaggingingId(value: number) {
    this.baggingId = value;
  }
}

export const BaggingController = getModule(BaggingStore);
