export const configDates = (timezone: string): any => {
  switch (timezone) {
    case "+00:00":
    case "GMT+0000":
    case "GMT+0:00":
      return { id: "UTC" };
    case "+07:00":
    case "GMT+0700":
    case "GMT+7:00":
      return { id: "WIB" };
    case "+08:00":
    case "GMT+0800":
    case "GMT+8:00":
      return { id: "WITA", my: "MYT" };
    case "+09:00":
    case "GMT+0900":
    case "GMT+9:00":
      return { id: "WIT" };

    default:
      return { id: "", my: "" };
  }
};
