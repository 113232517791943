import { App, defineAsyncComponent } from "vue";

const LpInput = defineAsyncComponent({
  loader: () => import("@/app/ui/components/lp-input/index.vue")
});
const Table = defineAsyncComponent({
  loader: () => import("@/app/ui/components/table-v2/index.vue")
});
const Breadcrumb = defineAsyncComponent({
  loader: () => import("@/app/ui/components/breadcrumb/index.vue")
});
const Chips = defineAsyncComponent({
  loader: () => import("@/app/ui/components/chips/index.vue")
});
const ChipsV2 = defineAsyncComponent({
  loader: () => import("@/app/ui/components/chips-v2/index.vue")
});
const Card = defineAsyncComponent({
  loader: () => import("@/app/ui/components/card/index.vue")
});
const DataWrapper = defineAsyncComponent({
  loader: () => import("@/app/ui/components/data-wrapper/index.vue")
});
const LpDatePickerV2 = defineAsyncComponent({
  loader: () => import("@/app/ui/components/date-picker-v2/index.vue")
});
const DownloadAttachment = defineAsyncComponent({
  loader: () => import("@/app/ui/components/download-attachment/index.vue")
});
const Header = defineAsyncComponent({
  loader: () => import("@/app/ui/components/header/index.vue")
});
const HeaderV2 = defineAsyncComponent({
  loader: () => import("@/app/ui/components/header-v2/index.vue")
});
const Select = defineAsyncComponent({
  loader: () => import("@/app/ui/components/select/index.vue")
});
const CustomDropdown = defineAsyncComponent({
  loader: () => import("@/app/ui/components/custom-dropdown/index.vue")
});
const Search = defineAsyncComponent({
  loader: () => import("@/app/ui/components/search/index.vue")
});
const Pagination = defineAsyncComponent({
  loader: () => import("@/app/ui/components/pagination/index.vue")
});
const SelectSearch = defineAsyncComponent({
  loader: () => import("@/app/ui/components/select-search/index.vue")
});
const MultipleSelectSearch = defineAsyncComponent({
  loader: () => import("@/app/ui/components/multiple-select-v2/index.vue")
});
const Dialog = defineAsyncComponent({
  loader: () => import("@/app/ui/components/dialog/index.vue")
});
const ModalV2 = defineAsyncComponent({
  loader: () => import("@/app/ui/components/modal-v2/index.vue")
});
const TimeRangePicker = defineAsyncComponent({
  loader: () => import("@/app/ui/components/time-range-picker/index.vue")
});
const AdvancedFilter = defineAsyncComponent({
  loader: () => import("@/app/ui/components/advanced-filter/index.vue")
});
const Icons = defineAsyncComponent({
  loader: () => import("@/app/ui/components/lp-icon/index.vue")
});
const TooltipV2 = defineAsyncComponent({
  loader: () => import("@/app/ui/components/tooltip-v2/index.vue")
});
const Tabs = defineAsyncComponent({
  loader: () => import("@/app/ui/components/tabs/index.vue")
});
const Skeleton = defineAsyncComponent({
  loader: () => import("@/app/ui/components/skeleton/index.vue")
});
const Spinner = defineAsyncComponent({
  loader: () => import("@/app/ui/components/loading/spinner.vue")
});
const Snackbar = defineAsyncComponent({
  loader: () => import("@/app/ui/components/snackbar/index.vue")
});
const SnackbarCopy = defineAsyncComponent({
  loader: () => import("@/app/ui/components/snackbar-copy/index.vue")
});
const MultipleImage = defineAsyncComponent({
  loader: () => import("@/app/ui/components/upload-multiple-image/index.vue")
});
const MultipleFile = defineAsyncComponent({
  loader: () => import("@/app/ui/components/multiple-upload-file/index.vue")
});
const ImagePreview = defineAsyncComponent({
  loader: () => import("@/app/ui/components/image-preview/index.vue")
});
const Title = defineAsyncComponent({
  loader: () => import("@/app/ui/components/data-wrapper/title.vue")
});
const EmptyState = defineAsyncComponent({
  loader: () => import("@/app/ui/components/empty-list/index.vue")
});
const InputDimension = defineAsyncComponent({
  loader: () => import("@/app/ui/components/input-dimension/index.vue")
});
const Notification = defineAsyncComponent({
  loader: () => import("@/app/ui/components/notification/index.vue")
});
const HorizontalScrollContainer = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/components/horizontal-scroll-container/index.vue")
});
const FloatingBooking = defineAsyncComponent({
  loader: () => import("@/app/ui/components/floating-booking/index.vue")
});
const CheckboxV2 = defineAsyncComponent({
  loader: () => import("@/app/ui/components/check-box-v2/index.vue")
});
const WidgetDownload = defineAsyncComponent(() =>
  import("@/app/ui/components/widget-download-request/index.vue")
);
const DetailLayout = defineAsyncComponent(() =>
  import("@/app/ui/layout/detail-layout.vue")
);
const CreateUpdate = defineAsyncComponent(() =>
  import("@/app/ui/components/data-wrapper/create-update.vue")
);
const SelectCity = defineAsyncComponent(() =>
  import("@/app/ui/components/module-genesis/select-city.vue")
);
const SelectDistrict = defineAsyncComponent(() =>
  import("@/app/ui/components/module-genesis/select-district.vue")
);
const SelectVendor = defineAsyncComponent(() =>
  import("@/app/ui/components/module-genesis/select-vendor.vue")
);
const MultipleSelectCommodity = defineAsyncComponent(() =>
  import("@/app/ui/components/module-genesis/multiple-select-commodity.vue")
);
const MultipleSelectProduct = defineAsyncComponent(() =>
  import("@/app/ui/components/module-genesis/multiple-select-product.vue")
);
const AddressTypeSelect = defineAsyncComponent(() =>
  import("@/app/ui/components/module-genesis/address-type.vue")
);
const Toggle = defineAsyncComponent(() =>
  import("@/app/ui/components/toggle-v2/index.vue")
);
const UploadImageWide = defineAsyncComponent(() =>
  import("@/app/ui/components/upload-image-wide/index.vue")
);
const UploadFileForum = defineAsyncComponent(() =>
  import("@/app/ui/components/upload-file-forum/index.vue")
);
const ImagePreviewForum = defineAsyncComponent(() =>
  import("@/app/ui/components/image-preview-forum/index.vue")
);
const FilePreviewForum = defineAsyncComponent(() =>
  import("@/app/ui/components/file-preview-forum/index.vue")
);
const UploadImageTiny = defineAsyncComponent(() =>
  import("@/app/ui/components/upload-image-tiny/index.vue")
);
const Captcha = defineAsyncComponent(() =>
  import("@/app/ui/components/captcha/index.vue")
);
const PvDropdown = defineAsyncComponent(() => import("primevue/dropdown"));
const PageDetailLazyLoad = defineAsyncComponent(() =>
  import("@/app/ui/components/lazy-load/page-detail-lazy-load.vue")
);
const TableLazyLoad = defineAsyncComponent(() =>
  import("@/app/ui/components/lazy-load/table-lazy-load.vue")
);
const NestedCheckbox = defineAsyncComponent(() =>
  import("@/app/ui/components/nested-checkbox/index.vue")
);
const LpRadioButton = defineAsyncComponent(() =>
  import("@/app/ui/components/lp-radio-button/index.vue")
);
const ProgressBar = defineAsyncComponent(() =>
  import("@/app/ui/components/progress-bar/index.vue")
);
const DonutChart = defineAsyncComponent(() =>
  import("@/app/ui/components/donut-chart/index.vue")
);
const BulletPoint = defineAsyncComponent(() =>
  import("@/app/ui/components/icons/modules/bullet-point.vue")
);
const BarCharts = defineAsyncComponent(() =>
  import("@/app/ui/components/chart/bar-charts/index.vue")
);
const ColumnCharts = defineAsyncComponent(() =>
  import("@/app/ui/components/chart/column-bar/index.vue")
);
const MultipleSelectCity = defineAsyncComponent(() =>
  import("@/app/ui/components/module-genesis/multiple-select-city.vue")
);
const SelectReason = defineAsyncComponent(() =>
  import("@/app/ui/components/module-genesis/select-reason.vue")
);
const AreaChart = defineAsyncComponent(() =>
  import("@/app/ui/components/chart/area-bar/index.vue")
);

import OverlayPanel from "primevue/overlaypanel";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import Modal from "@/app/ui/components/modal/index.vue";
import LpButton from "@/app/ui/components/lp-button/index.vue";
import ButtonFill from "@/app/ui/components/button-fill/index.vue";
import ButtonOutline from "@/app/ui/components/button-outline/index.vue";
import ErrorMessage from "@/app/ui/components/error-message/index.vue";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import VueClientRecaptcha from "vue-client-recaptcha";

const register = (app: App<Element>): void => {
  app
    .component("LpInput", LpInput)
    .component("LpButton", LpButton)
    .component("Table", Table)
    .component("TableV2", Table)
    .component("ButtonFill", ButtonFill)
    .component("ButtonOutline", ButtonOutline)
    .component("Breadcrumb", Breadcrumb)
    .component("Chips", Chips)
    .component("ChipsV2", ChipsV2)
    .component("Card", Card)
    .component("DataWrapper", DataWrapper)
    .component("DatePicker", LpDatePickerV2)
    .component("DatePickerV2", LpDatePickerV2)
    .component("Pickers", LpDatePickerV2)
    .component("Datepicker", LpDatePickerV2)
    .component("DatePickers", LpDatePickerV2)
    .component("DownloadAttachment", DownloadAttachment)
    .component("ErrorMessage", ErrorMessage)
    .component("Header", Header)
    .component("Select", Select)
    .component("CustomDropdown", CustomDropdown)
    .component("PvDropdown", PvDropdown)
    .component("Search", Search)
    .component("Pagination", Pagination)
    .component("SelectSearch", SelectSearch)
    .component("MultipleSelectSearch", MultipleSelectSearch)
    .component("Dialog", Dialog)
    .component("Modal", Modal)
    .component("ModalV2", ModalV2)
    .component("TimeRangePicker", TimeRangePicker)
    .component("AdvancedFilter", AdvancedFilter)
    .component("Splide", Splide)
    .component("SplideSlide", SplideSlide)
    .component("Icons", Icons)
    .component("OverlayPanel", OverlayPanel)
    .component("TooltipV2", TooltipV2)
    .component("Tabs", Tabs)
    .component("Skeleton", Skeleton)
    .component("Spinner", Spinner)
    .component("Snackbar", Snackbar)
    .component("SnackbarCopy", SnackbarCopy)
    .component("MultipleImage", MultipleImage)
    .component("MultipleFile", MultipleFile)
    .component("ImagePreview", ImagePreview)
    .component("Title", Title)
    .component("EmptyState", EmptyState)
    .component("InputDimension", InputDimension)
    .component("Notification", Notification)
    .component("HorizontalScrollContainer", HorizontalScrollContainer)
    .component("FloatingBooking", FloatingBooking)
    .component("CheckboxV2", CheckboxV2)
    .component("HeaderV2", HeaderV2)
    .component("WidgetDownload", WidgetDownload)
    .component("SelectCity", SelectCity)
    .component("SelectDistrict", SelectDistrict)
    .component("SelectVendor", SelectVendor)
    .component("MultipleSelectCommodity", MultipleSelectCommodity)
    .component("MultipleSelectProduct", MultipleSelectProduct)
    .component("DetailLayout", DetailLayout)
    .component("VueClientRecaptcha", VueClientRecaptcha)
    .component("AddressTypeSelect", AddressTypeSelect)
    .component("CreateUpdate", CreateUpdate)
    .component("Toggle", Toggle)
    .component("UploadImageWide", UploadImageWide)
    .component("UploadImageTiny", UploadImageTiny)
    .component("PageDetailLazyLoad", PageDetailLazyLoad)
    .component("TableLazyLoad", TableLazyLoad)
    .component("Captcha", Captcha)
    .component("ImagePreviewForum", ImagePreviewForum)
    .component("FilePreviewForum", FilePreviewForum)
    .component("UploadFileForum", UploadFileForum)
    .component("NestedCheckbox", NestedCheckbox)
    .component("LpRadioButton", LpRadioButton)
    .component("ProgressBar", ProgressBar)
    .component("DonutChart", DonutChart)
    .component("BulletPoint", BulletPoint)
    .component("BarCharts", BarCharts)
    .component("ColumnCharts", ColumnCharts)
    .component("MultipleSelectCity", MultipleSelectCity)
    .component("SelectReason", SelectReason)
    .component("AreaChart", AreaChart);
};

export default {
  register
};
