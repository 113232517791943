import { Pagination } from "@/domain/entities/Pagination";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import convertPhoneNumber from "@/app/infrastructures/misc/common-library/ConvertPhoneNumber";
import { OptionsClass } from "./MainApp";

export class ClientRegistrationEntities {
  pagination: Pagination;
  data: ClientRegistrationData[];

  constructor(pagination: Pagination, data: ClientRegistrationData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class ClientRegistrationData {
  id: number;
  clientName: string;
  clientCode: string;
  clientStartDate: string;
  clientEndDate: string;
  clientStatus: string;
  clientType: string;

  constructor(
    id: number,
    clientName: string,
    clientCode: string,
    clientStarDate: string,
    clientEndDate: string,
    clientStatus: string,
    clientType: string
  ) {
    this.id = id;
    this.clientName = clientName;
    this.clientCode = clientCode;
    this.clientStartDate = clientStarDate;
    this.clientEndDate = clientEndDate;
    this.clientStatus = clientStatus;
    this.clientType = clientType;
  }
}

export class ClientContact {
  clientContactName = "";
  clientContactJobTitle = "";
  clientContactPhone = "";
  clientContactEmail = "";

  constructor(fields?: Partial<ClientContact>) {
    Object.assign(this, fields);
  }
}
export class ClientLocation {
  id = 0;
  code = "";
  name = "";
  cityCode = "";

  constructor(fields?: Partial<ClientLocation>) {
    Object.assign(this, fields);
  }
}
export class ClientCod {
  clientCodName = "";
  clientCodAddress = "";
  clientCodDistrict = new ClientLocation();
  clientCodPhone = "";
  clientCodCommission = "";

  constructor(fields?: Partial<ClientCod>) {
    Object.assign(this, fields);
  }
}
export class ClientCodAsDefault {
  defaultCodName = "";
  defaultCodAddress = "";
  defaultCodDistrict = new ClientLocation();
  defaultCodPhone = "";

  constructor(fields?: Partial<ClientCodAsDefault>) {
    Object.assign(this, fields);
  }
}
export class BankDetail {
  bankLabel = "";
  bankName = "";
  accountNo = "";
  accountName = "";
  email = "";
  logo = "";
  constructor(fields?: Partial<BankDetail>) {
    Object.assign(this, fields);
  }
}
export class ClientCurrentRate {
  clientCustomRateId = 0;
  clientStatusCustomRate = "";
  clientRateVersionStartDate = "";
  clientRateVersionEndate = "";
  clientStatusUpdatedAt = "";
  clientStatusUpdatedBy = "";
  clientStatusUpdatedReason = "";
  clientVersionBaseRate = "";
  clientDiscountBaseRate = 0;
  clientFileNameBaseRate = "";
  clientFileBaseRate = "";
  clientVersionCustomRate = "";
  clientFileNameCustomRate = "";
  clientFileCustomRate = "";
  clientErrorNameCustomRate = "";
  clientErrorFileCustomRate = "";
  clientStatusBulk = "";

  constructor(fields?: Partial<ClientCurrentRate>) {
    Object.assign(this, fields);
  }
}
export class ClientPartner {
  clientPartnerId = 0;
  clientPartnerName = "";
  clientPartnerQuantifierCommission = "";
  clientPartnerCommissionAmount = 0;
  clientPartnerNumeratorCommision = "";

  constructor(fields?: Partial<ClientPartner>) {
    Object.assign(this, fields);
  }
}
export class ClientBilling {
  noWhatsApp = "";
  billingSchedule = "";
  timeRangePayment = "";
  email: Array<string> = [];
  startDate = "";
  endDate = "";
  attachment = "";
  attachmentUrl = "";
  constructor(fields?: Partial<ClientBilling>) {
    Object.assign(this, fields);
  }

  get formattedStartDate() {
    return formatDateWithoutTime(this.startDate);
  }
  get formattedEndDate() {
    return formatDateWithoutTime(this.endDate);
  }
  get formattedEmail() {
    return this.email.join(", ");
  }
  get formattedWhatsappNumber() {
    return `+${convertPhoneNumber(this.noWhatsApp)}`;
  }

  get fileNameAttachment() {
    return this.attachmentUrl.split("/").pop();
  }
}
export class ClientTax {
  NIK = "";
  NIKName = "";
  NIKAddress = "";
  isTaxPayer = false;
  taxNumber = "";
  taxPayerName = "";
  taxPayerAddress = "";
  transactionCode = "05 Besaran Tertentu";
  constructor(fields?: Partial<ClientTax>) {
    Object.assign(this, fields);
  }
}
export class ClientRegistrationDetail {
  id = 0;
  clientCode = "";
  clientCodeElexys = "";
  clientName = "";
  clientPhone = "";
  clientEmail = "";
  clientType = "";
  clientCategory = "";
  clientTaxNumber = "";
  clientStatus = "";
  clientAddress = "";
  clientDistrict = new ClientLocation();
  clientCity = new ClientLocation();
  clientStatusUpdatedAt = "";
  clientStatusUpdatedBy = "";
  clientStatusUpdatedReason = "";
  clientParentId = 0;
  clientParentName = "";
  clientPartner = new ClientPartner();
  clientContact = new ClientContact();
  clientIsCustomRate: any = "";
  clientIsRenewalCustomRate = false;
  clientIsCod = false;
  clientIsMixpack = false;
  clientIsDo = false;
  clientBranchDoPaymentType = "";
  clientCreatedAt = "";
  clientUpdatedAt = "";
  clientCreatedBy = "";
  clientUpdatedBy = "";
  clientCurrentRate = new ClientCurrentRate();
  clientRenewalRate: any = "";
  clientCodReject = new ClientCod();
  clientCodRejectAsDefault = new ClientCodAsDefault();
  clientBankName = "";
  clientBankNumber = "";
  clientBankEmail = "";
  clientBankLabel = "";
  dataProcessed = 0;
  totalData = 0;
  clientCompanySize = "";
  clientTax = new ClientTax();
  clientBilling = new ClientBilling();
  bankDetail = new BankDetail();
  bankSettlement = new BankDetail();
  clientPadFee = 0;
  clientPaymentMethod = new OptionsClass();
  clientCodConfigAmount = new OptionsClass();
  clientCodShipmentDiscount = "";
  clientIsDfod = false;
  constructor(fields?: Partial<ClientRegistrationDetail>) {
    Object.assign(this, fields);
    if (!fields?.clientPaymentMethod) {
      this.clientPaymentMethod = new OptionsClass();
    }
    if (!fields?.clientCodConfigAmount) {
      this.clientCodConfigAmount = new OptionsClass();
    }
    if (!fields?.clientCodShipmentDiscount) {
      this.clientCodShipmentDiscount = "0";
    } else {
      this.clientCodShipmentDiscount = String(
        fields?.clientCodShipmentDiscount
      );
    }
  }
}

export const clientPaymentMethodData: OptionsClass[] = [
  new OptionsClass({
    name: "Invoice",
    value: "invoice"
  }),
  new OptionsClass({
    name: "Split Bill",
    value: "split_bill"
  })
];

export const clientCodConfigAmountData: OptionsClass[] = [
  new OptionsClass({
    name: "Harga Barang",
    value: "goods_price"
  }),
  new OptionsClass({
    name: "Harga Barang + Ongkir Klien",
    value: "goods_price,total_tarif"
  })
];
