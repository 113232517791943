import { unparse } from "papaparse";
import downloadFromBlob from "@/app/infrastructures/misc/common-library/DownloadFromBlob";

const downloadCsv = (params: {
  fileName: string;
  fields: Array<string>;
  listStt: Array<any>;
}) => {
  const csvContent = unparse({
    fields: params.fields,
    data: params.listStt
  });
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  downloadFromBlob(blob, params.fileName);
};
export default downloadCsv;
