import { AccountController } from "@/app/ui/controllers/AccountController";
import { Pagination } from "@/domain/entities/Pagination";
import {
  CheckPaymentStatus,
  PodDexData,
  ReasonData
} from "@/domain/entities/PodDex";
import {
  Entities,
  ResponsePayload,
  ResponsePayloadV2
} from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";

export class PodDexMapper {
  public convertListReasonDataFromApi(res: AxiosResponse<any>): ReasonData[] {
    const {
      data: { data }
    } = res;

    return data.length
      ? data.map(
          (item: any) =>
            new ReasonData({
              reasonId: item.reason_id,
              reasonCode: item.reason_code,
              reasonExternalCode: item.reason_external_code,
              reasonDescription:
                AccountController.accountData.account_type_detail.countryCode.toLowerCase() ===
                "my"
                  ? item.reason_description_en
                  : item.reason_description,
              reasonStatus: item.reason_status,
              reasonAbleGenerateNewSTT: item.reason_can_generate_stt
            })
        )
      : [];
  }

  public convertPayloadData(res: AxiosResponse<any>): ResponsePayload {
    const { data } = res;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertDownloadData(res: AxiosResponse<any>): ResponsePayloadV2 {
    const { data } = res;
    return new ResponsePayloadV2({
      success: data.success,
      message: data.message
    });
  }

  public convertPayloadCheckPaymentStatusData(
    res: AxiosResponse<any>
  ): CheckPaymentStatus {
    const { data } = res;
    return new CheckPaymentStatus(data.success, data.code, data.message);
  }

  public convertPodDexListDataFromApi(res: AxiosResponse<any>): Entities {
    const { data } = res;

    const paymentMethod: any = {
      QRIS: "QRIS",
      CASH: "Tunai"
    };

    if (data.data.length === 0)
      return new Entities(
        false,
        200,
        data.message,
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    else
      return new Entities(
        true,
        200,
        data.message,
        new Pagination(data.meta.page, data.meta.limit),
        data.data.map(
          (item: any) =>
            new PodDexData({
              deliveryId: item.delivery_id,
              sttNo: item.stt_no,
              sttNoRefExternal: item.stt_no_ref_external,
              totalPiece: item.total_piece,
              chargeableWeight: item.chargeable_weight,
              productName: item.product_name,
              lastStatus: item.finished_status,
              commodityName: item.commodity_name,
              codAmount: item.cod_amount,
              deliveryDate: item.delivery_date,
              courierName: item.courier_name,
              courierPhoneNumber: item.courier_phone_number,
              deliveredBy: item.delivered_by,
              deliveredByType: item.delivered_by_type,
              sttDeliveryAttempt: item.stt_delivery_attempt,
              recipientName: item.recipient_name,
              recipientAddress: item.recipient_address,
              recipientPhoneNumber: item.recipient_phone_number,
              proofFile: item.proof_of_file || [],
              sttIsCod: item.stt_is_cod,
              sttIsDfod: item.stt_is_dfod,
              paymentMethod: paymentMethod[item.payment_method] ?? "-"
            })
        )
      );
  }
}
