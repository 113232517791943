import { CLIENT } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");

const Client = () =>
  import(
    /* webpackChunkName: "Client" */ "@/app/ui/views/sales/clients/index.vue"
  );
const ClientRegistration = () =>
  import(
    /* webpackChunkName: "Client" */ "@/app/ui/views/sales/clients/client-registration/index.vue"
  );
const ClientManagement = () =>
  import(
    /* webpackChunkName: "Client" */ "@/app/ui/views/sales/clients/client-management/index.vue"
  );
const AddClient = () =>
  import(
    /* webpackChunkName: "Client" */ "@/app/ui/views/sales/clients/client-registration/component/add.vue"
  );
const DetailClient = () =>
  import(
    /* webpackChunkName: "Client" */ "@/app/ui/views/sales/clients/client-registration/detail/index.vue"
  );
const ClientManagementDetail = () =>
  import(
    /* webpackChunkName: "Client" */ "@/app/ui/views/sales/clients/client-management/detail/index.vue"
  );

export default [
  {
    path: "/client",
    component: Layout,
    name: "client",
    meta: {
      children: false,
      icon: "client-registration",
      title: "Client Registration & Management",
      name: "Client Registration & Management",
      isFreeze: true,
      permission: CLIENT.ENABLE
    },
    children: [
      {
        path: "",
        name: "client",
        redirect: "/client/client-registration",
        component: Client,
        meta: {
          header: true,
          icon: "client-registration",
          title: "Client",
          name: "Client",
          permission: CLIENT.ENABLE
        },
        children: [
          {
            path: "client-registration",
            name: "client-registration",
            component: ClientRegistration
          },
          {
            path: "client-management",
            name: "client-management",
            component: ClientManagement
          }
        ]
      }
    ]
  },
  {
    path: "/client/client-registration",
    component: Layout,
    name: "add-client-management-parent",
    meta: {
      hidden: true,
      parent: "Client Registration"
    },
    children: [
      {
        path: "add-client-parent",
        name: "add-client-registration-parent",
        component: AddClient,
        meta: {
          header: true,
          permission: CLIENT.REGISTRATION_CREATE,
          title: "Client | Create",
          name: "Buat Induk Client",
          form: "parent",
          before: "/client/client-registration"
        }
      }
    ]
  },
  {
    path: "/client/client-registration",
    component: Layout,
    name: "add-client-management-branch",
    meta: {
      hidden: true,
      parent: "Client Registration"
    },
    children: [
      {
        path: "add-client-branch",
        name: "add-client-registration-branch",
        component: AddClient,
        meta: {
          header: true,
          permission: CLIENT.REGISTRATION_CREATE,
          title: "Client | Create",
          name: "Buat Cabang Client",
          form: "branch",
          before: "/client/client-registration"
        }
      }
    ]
  },
  {
    path: "/client/client-registration",
    component: Layout,
    name: "detail-client-registration",
    meta: {
      hidden: true,
      parent: "Client Registration"
    },
    children: [
      {
        path: ":id",
        name: "detail-client-registration",
        component: DetailClient,
        meta: {
          title: "Client | Detail",
          header: true,
          permission: CLIENT.REGISTRATION_DETAIL
        }
      }
    ]
  },
  {
    path: "/client/client-management",
    component: Layout,
    name: "detail-client-management",
    meta: {
      hidden: true,
      parent: "Client Management",
      isFreeze: true
    },
    children: [
      {
        path: ":id",
        name: "detail-client-management",
        component: ClientManagementDetail,
        meta: {
          header: true,
          permission: CLIENT.MANAGEMENT_DETAIL,
          title: "Client Management | Detail"
        }
      }
    ]
  }
];
