import { ALLOWED, CLAIM } from "@/app/infrastructures/misc/RolePermission";
import { FlagsPermissionShopify } from "@/feature-flags/flags-shopify";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const Shopify = () =>
  import(/* webpackChunkName: "Shopify" */ "@/app/ui/views/shopify/index.vue");

export default [
  {
    path: "/shopify",
    component: Layout,
    redirect: "/shopify",
    name: "shopify",
    meta: {
      icon: "shopify",
      spesific: true,
      children: false,
      title: "Shopify",
      name: "Shopify",
      permission: FlagsPermissionShopify.form_enable
    },
    children: [
      {
        path: "/shopify",
        name: "shopify",
        component: Shopify,
        meta: {
          header: false
        }
      }
    ]
  }
];
