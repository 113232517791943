/* eslint-disable @typescript-eslint/camelcase */

import * as Rox from "rox-browser";

export const FlagsMedusa = {
  banner_bni_kur_enable: new Rox.Flag(false),
  config_cod_ongkir_enable: new Rox.Flag(false),
  config_new_cod_booking_enable: new Rox.Flag(false),
  config_modify_courier_enable: new Rox.Flag(false),
  config_timeout_ngen_booking: new Rox.RoxNumber(22000),
  config_max_address: new Rox.RoxNumber(200),
  config_new_receipt: new Rox.Flag(false),
  config_extra_receipt: new Rox.Flag(false),
  config_max_generate_manual_stt: new Rox.RoxNumber(5000),
  config_commodity_general_others_id: new Rox.RoxNumber(499),
  config_commodity_general_others_code: new Rox.RoxString("GEN"),
  config_commodity_general_others_name: new Rox.RoxString(
    "GENERAL OTHERS - GENERAL LAINNYA"
  ),
  config_volumetric_weight_sikat: new Rox.RoxNumber(3),
  config_pos_affiliate_banner: new Rox.Flag(false),
  config_claim_riwayat_pengiriman: new Rox.Flag(false),
  config_claim_pengiriman_aktif: new Rox.Flag(false),
  config_pos_performance_dashboard: new Rox.RoxString(""),
  config_id_whitelist_payment_qris_booking: new Rox.RoxString("ALL"),
  config_id_whitelist_payment_qris_tagihan: new Rox.RoxString("ALL"),
  config_enable_payment_qris: new Rox.Flag(false),
  config_loan_ocbc: new Rox.Flag(false),
  client_id_courrier_recommendation_config: new Rox.RoxNumber(0),
  all_prefix_shipments: new Rox.RoxString(""),
  prefix_shipment_dimension_disable: new Rox.RoxString(""),
  config_allowance_adjustment_weight_tklp: new Rox.RoxString(""),
  flag_release_interpack_additional_info: new Rox.Flag(false),
  custom_status_halcd: new Rox.Flag(false),
  config_volumetric_by_product: new Rox.RoxString("{}"),
  cod_dfod_pos_favorite: new Rox.Flag(false),
  user_whitelist_for_edit_shipment_c1: new Rox.RoxString(""),
  flag_booking_woodpacking_enable: new Rox.Flag(false),
  config_version_endpoint_withdraw_pos: new Rox.RoxString("v1"),
  permission_woodpacking_shipment: new Rox.Flag(false),
};

export function initFlagsMedusa() {
  // register flags with namespaces for better grouping
  Rox.register("medusa", FlagsMedusa);
}
