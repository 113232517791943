/* eslint-disable @typescript-eslint/camelcase */
import { ShipmentIdList, ShipmentId } from "@/domain/entities/Booking";
import { Pagination } from "@/domain/entities/Pagination";
import { AxiosResponse } from "axios";
import removeNewLineWhitespace from "@/app/infrastructures/misc/common-library/RemoveNewLineWhitespace";
import { codePhoneCountry } from "@/app/ui/views/shipment/booking/booking-utils";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import { RequestMiddlewareList } from "@/data/payload/api/MiddlewareApiRequest";
import { MiddlewareList, MiddlewareData, MiddlewareDetail } from "@/domain/entities/Middleware";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { AuthEntities } from "@/domain/entities/Auth";
export class MiddlewareMapper {
  public convertShipmentDetailFromMiddleware(
    res: AxiosResponse<any>
  ): ShipmentIdList {
    const { data } = res;
    if (data.packets.length === 0) {
      return new ShipmentIdList({
        pagination: new Pagination(1, 10),
        data: []
      });
    }
    const shipmentIdList: ShipmentId[] = [];
    data.packets.map((item: any) => {
      const senderPhone = item.sender?.phone || "";
      const recipientPhone = item.recipient?.phone || "";
      const sender = codePhoneCountry(senderPhone.replaceAll("+", ""));
      const recipient = codePhoneCountry(recipientPhone.replaceAll("+", ""));
      shipmentIdList.push(
        new ShipmentId({
          shipmentAlgoId: item.ticket_code,
          shipmentProduct: item.product,
          shipmentOriginCity: "",
          shipmentOriginDistrict: "",
          shipmentDestinationCity: "",
          shipmentDestinationDistrict: "",
          shipmentSenderName: item.sender?.name,
          shipmentSenderAddress: removeNewLineWhitespace(
            item.sender?.address,
            " "
          ),
          shipmentSenderPhone: sender.number.replace(/[^0-9]+/g, ""),
          shipmentSenderPhoneOriginal: senderPhone,
          shipmentSenderPhoneCode: sender.code,
          shipmentSenderPhoneFlag: sender.flag,
          shipmentRecipientName: item.recipient?.name,
          shipmentRecipientAddress: removeNewLineWhitespace(
            item.recipient?.address,
            " "
          ),
          shipmentRecipientPhone: recipient.number.replace(/[^0-9]+/g, ""),
          shipmentRecipientPhoneCode: recipient.code,
          shipmentRecipientPhoneFlag: recipient.flag,
          shipmentCommodityId: FlagsMedusa.config_commodity_general_others_id.getValue(),
          shipmentCommodityCode:
          FlagsMedusa.config_commodity_general_others_code.getValue(),
          shipmentCommodityName:
          FlagsMedusa.config_commodity_general_others_name.getValue(),
          shipmentGoodsStatus: "ecommerce",
          shipmentInsurance: item.is_insurance ? "basic" : "free",
          shipmentGoodsPriceEstimation: item.goods_value,
          shipmentNpwpNumber: "",
          shipmentGoodDetail: item.items.map((e: any) => {
            return {
              shipment_goods_weight: e.weight / 1000,
              shipment_goods_height: e.height,
              shipment_goods_width: e.width,
              shipment_goods_length: e.length
            };
          }),
          shipmentIsCod: item.is_cod,
          shipmentAbleToCod: true,
          shipmentIsWoodpacking: item.is_wood_packing,
          shipmentAbleToWoodpacking: true,
          shipmentPacketId: item.ticket_code,
          shipmentChargeableWeight: 0,
          customerName: item.customer_code,
          isAdjustable: false,
          maxAdjustable: false,
          STT: null,
          isDO: false,
          piecePerPack: "",
          nextCommodity: "",
          codAmount: item.cod_value,
          codFee: 0,
          dataRaw: item,
          shipmentDestinationCityKR: item.recipient.destination,
          senderPhoneInstantBooking: item.sender?.phone.replaceAll("+", "") || "",
          recipientPhoneInstantBooking: item.recipient?.phone.replaceAll("+", "") || "",
        })
      );
    });
    return new ShipmentIdList({
      pagination: new Pagination(1, 99),
      data: shipmentIdList
    });
  }

  public convertListFromMiddleware(
    type: string,
    res: AxiosResponse<any>
  ): MiddlewareList {
    const { data } = res;
    let list = []
    if (type === "mapping-code") {
      list = data.data.map(
        (item: any) =>
          new MiddlewareData({
            id: item.id,
            userName: item.username,
            clientBranchName: item.company,
            externalCodeClientBranch: item.client_code,
            clientBranchCode: item.client_code_genesis,
            clientCity: item.city,
            clientCrossDock: item.is_client_crossdocking ? "Ya" : "Tidak"
          })
      )
    } else {
      list = data.data.map(
        (item: any) =>
          new MiddlewareData({
            id: item.id,
            userName: item.username,
            clientParentCode: item.client_id,
            clientPhone: item.client_contact,
            token: item.elexys_token
          })
      )
    }

    return new MiddlewareList({
      pagination: new Pagination(
        data.meta.page,
        data.meta.limit,
        data.meta.total_records
      ),
      data: data.data.length
        ? list
        : []
    });
  }

  public convertCreateMiddlewareFromApi(result: AxiosResponse): ResponsePayloadV2 {
    const { data } = result;
    return new ResponsePayloadV2({
      success: data.success,
      message: data.message
    });
  }

  public convertDetailMiddlewareFromApi(
    result: AxiosResponse
  ): MiddlewareDetail {
    const {
      data: { data }
    } = result;

    return new MiddlewareDetail({
      id: data.id,
      username: data.username,
      password: data.password,
      clientCode: data.client_code,
      clientCodeGenesis: data.client_code_genesis,
      clientId: data.client_id,
      company: data.company,
      webhook: data.webhook,
      webhookToken: data.webhook_token || data.elexys_token,
      city: data.city,
      isClientCrossdocking: data.is_client_crossdocking,
      client3lc: data.client_3lc,
      clientIdGenesis: data.client_id_genesis,
      clientPhone: data.client_contact,
      createdAt: data.created_at,
      updatedAt: data.updated_at,
      createdBy: data.created_by,
      updatedBy: data.updated_by
    });
  }

  public convertGetTokenMiddlewareFromApi(result: AxiosResponse): AuthEntities {
    const { data } = result;
    return new AuthEntities({
      token: data.data.token,
    });
  }
}
