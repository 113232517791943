import { STIApiRepository } from "@/app/infrastructures/repositories/api/STIApiRepository";
import { injectable } from "tsyringe";
import {
  DetailSttPiecesSTI,
  STIEntities,
  StiManifestData
} from "@/domain/entities/STI";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { StiRequestInterface } from "@/data/payload/contracts/StiApiRequest";
import { StiListRequest } from "@/data/payload/api/StiApiRequest";

@injectable()
export class STIPresenter {
  private repository: STIApiRepository;

  constructor(repository: STIApiRepository) {
    this.repository = repository;
  }

  public getListSTI(params: StiListRequest): Promise<STIEntities> {
    return this.repository.getListSTI(params);
  }

  public validationScanSTTPiece(sttPiece: string): Promise<ResponsePayload> {
    return this.repository.validationScanSTTPiece(sttPiece);
  }

  public getDetailSttPieceNumberSti(
    sttNumber: string
  ): Promise<DetailSttPiecesSTI> {
    return this.repository.getDetailSttPieces(sttNumber);
  }

  public getDetailSttPieceNumberStiLilo(
    bagNo: string
  ): Promise<DetailSttPiecesSTI> {
    return this.repository.getDetailSttPiecesLilo(bagNo);
  }

  public async generateSti(
    payload: StiRequestInterface
  ): Promise<ResponsePayload> {
    return await this.repository.generateSti(payload);
  }

  public getStiManifestData(id: number): Promise<StiManifestData> {
    return this.repository.getStiManifestData(id);
  }

  public async downloadSti(id: number): Promise<any> {
    return this.repository.downloadSti(id);
  }
}
