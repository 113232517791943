import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";
import { Pagination } from "@/domain/entities/Pagination";
import {
  STIEntities,
  STIData,
  DetailSttPiecesSTI,
  DetailPieces,
  StiManifestData,
  StiManifestStt,
  StiManifestPiece,
  DetailStt
} from "@/domain/entities/STI";
import { LocationController } from "@/app/ui/controllers/LocationController";

export class STIMapper {
  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  public convertSTIDataFromApi(res: AxiosResponse<any>): STIEntities {
    const { data } = res;

    const STI: STIData[] = [];
    if (data.data.length === 0) {
      return new STIEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      data.data.map((item: any) => {
        STI.push(
          new STIData(
            item.sti_id,
            item.sti_partner_id,
            item.sti_partner_code,
            item.sti_partner_name,
            item.sti_total_stt,
            item.sti_total_gross_weight,
            item.sti_total_volume_weight,
            item.sti_total_pieces,
            item.sti_created_at,
            item.sti_created_by,
            item.sti_created_name,
            item.sti_updated_at
          )
        );
      });
    }
    return new STIEntities(
      new Pagination(data.meta.page, data.meta.limit),
      STI
    );
  }

  public convertSttPiecesStiDetailFromApi(
    res: AxiosResponse<any>
  ): DetailSttPiecesSTI {
    const {
      data: { data }
    } = res;

    const cityCodeLocation = LocationController.districtDetail.cityCode;

    return new DetailSttPiecesSTI({
      totalStt: data.total_stt,
      totalPieces: data.total_koli,
      totalSuccess: data.total_stt_success,
      totalFailed: data.total_stt_failed,
      stiResult: data?.sti_result,
      stts: data?.stts?.length
        ? data.stts.map(
            (item: any) =>
              new DetailStt({
                isAllowUpdateStatus: item.is_allow_update_status,
                isPaid: item.is_paid,
                errorMessage: item.error_message,
                sttId: item?.stt?.stt_id,
                sttNo: item?.stt?.stt_no,
                sttProductType: item?.stt?.stt_product_type,
                sttTotalPiece: item?.stt?.stt_total_piece,
                sttDestinationDistrictUrsacode: item?.stt?.stt_destination_district_ursacode,
                packageNumber: item?.stt?.package_number,
                sttDestinationRegionName: item?.stt?.stt_destination_region_name,
                sttOriginRegionName: item?.stt?.stt_origin_region_name,
                sttDestinationCityId: item?.stt?.stt_destination_city_id,
                sttDestinationCityName: item?.stt?.stt_destination_city_name,
                sttOriginCityId: item?.stt?.stt_origin_city_id,
                sttOriginCityName: item?.stt?.stt_origin_city_name,
                sttWoodPacking:
                  item?.stt?.stt_wood_packing?.toLowerCase() === "yes",
                sttCommodityCode: item?.stt?.stt_commodity_code,
                sttCommodityName: item?.stt?.stt_commodity_name,
                sttGrossWeight: item?.stt?.stt_gross_weight,
                sttVolumeWeight: item?.stt?.stt_volume_weight,
                sttChargeableWeight: item?.stt?.stt_chargeable_weight,
                sttLastStatusId: item?.stt?.stt_last_status_id,
                sttPaymentStatus: item?.stt?.stt_payment_status,
                isSti: true,
                isStiDest:
                  cityCodeLocation === item?.stt?.stt_destination_city_id,
                piece: item?.stt?.piece?.length
                  ? item?.stt?.piece?.map(
                      (piece: any) =>
                        new DetailPieces({
                          sttPieceId: piece?.stt_piece_id,
                          sttPieceGrossWeight: piece?.stt_piece_gross_weight,
                          sttPieceVolumeWeight: piece?.stt_piece_volume_weight,
                          sttPieceNo: piece?.stt_piece_no,
                          sttPieceLastStatusId: piece?.stt_piece_last_status_id
                        })
                    )
                  : []
              })
          )
        : []
    });
  }

  public convertStiManifestDataFromApi(
    res: AxiosResponse<any>
  ): StiManifestData {
    const {
      data: { data }
    } = res;
    if (data === null) {
      return new StiManifestData();
    } else {
      return new StiManifestData({
        stiId: data.sti_id,
        consolidatorName: data.consolidator_name,
        originConsolidator: data.origin_consolidator,
        stiDate: data.sti_date,
        totalStt: data.total_stt,
        totalPiece: data.total_piece,
        totalGrossWeight: data.total_gross_weight,
        totalVolumeWeight: data.total_volume_weight,
        createdName: data.created_name,
        stt: data.stt.map(
          (item: any) =>
            new StiManifestStt({
              sttId: item.stt_id,
              sttNo: item.stt_no,
              sttProductType: item.stt_product_type,
              sttTotalPiece: item.stt_total_piece,
              stiOriginCity: item.stt_origin_city_id,
              stiDestCity: item.stt_destination_city_id,
              stiOriginRegion: item.stt_origin_region_name,
              stiDestRegion: item.stt_destination_region_name,
              stiGrossWeight: item.stt_gross_weight,
              stiVolumeWeight: item.stt_volume_weight,
              piece: item.piece.map(
                (piece: any) =>
                  new StiManifestPiece({
                    sttPieceId: piece.stt_piece_id,
                    sttPieceNo: piece.stt_piece_no,
                    sttPieceGrossWeight: piece.stt_piece_gross_weight,
                    sttPieceVolumeWeight: piece.stt_piece_volume_weight
                  })
              )
            })
        )
      });
    }
  }

  public convertResponsePayload(res: AxiosResponse<any>): ResponsePayload {
    const { data } = res;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
      sttId: data.sti_id,
      data: data.data
    });
  }
}
