
import { Options, Vue, prop } from "vue-class-component";
import DownloadDocument from "@/app/ui/components/icons/modules/download-document.vue";

class Props {
  id = prop<string>({
    default: "",
    type: String
  });
  title = prop<string>({
    default: "",
    type: String
  });
  icon = prop<string>({
    default: "",
    type: String
  });
  iconLeft = prop<string>({
    default: "",
    type: String
  });
  custom = prop<string>({
    default: "w-full bg-white",
    type: String
  });
  onClick = prop<Function>({
    default: null,
    type: Function
  });
  isLoading = prop<boolean>({
    default: false,
    type: Boolean
  });
  isDisabled = prop<boolean>({
    default: false,
    type: Boolean
  });
}
@Options({
  components: {
    DownloadDocument
  }
})
export default class ButtonOutline extends Vue.with(Props) {}
