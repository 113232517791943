/* eslint-disable @typescript-eslint/camelcase */
import * as Rox from "rox-browser";
import { AccountController } from "@/app/ui/controllers/AccountController";

// import module flags permission
import { initFlagsPermissionSaldo } from "@/feature-flags/flags-permission-saldo";
import { initFlagsPermissionTopUpAndCashback } from "./flags-permission-topup-and-cashback";
import { initFlagsDashboardCod } from "@/feature-flags/dashboard/cod";
import { initFlagsDashboardDeliveryCOD } from "./flags-dashboard-delivery-cod";
import { initFlagsPermissionCodBalance } from "@/feature-flags/flags-permission-cod-balance";
import { initFlagsPromoDiscount } from "@/feature-flags/flags-promo-discount";
import { initConfigBucketStorage } from "@/feature-flags/flags-config-bucket-storage";
import { initFlagsPermissionCodReconcile } from "@/feature-flags/cod-reconcile";
import { initFlagsPermissionTopUpManual } from "@/feature-flags/flags-permission-topup-manual";
import { initFlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import { initFlagsPermissionBulkUpload } from "@/feature-flags/bulk-upload";
import { initFlagsPermissionTopUpApproval } from "@/feature-flags/flags-permission-topup-approval";
import { initFlagsPermissionTopUpVerification } from "@/feature-flags/flags-permission-topup-verification";
import { initFlagsPermissionBooking } from "@/feature-flags/flag-booking";
import { initFlagsPermissionReport } from "@/feature-flags/flag-report";
import { initFlagsFeatureShipmentC1 } from "@/feature-flags/flags-shipment-c1";
import { initFlagsPermissionMiddleware } from "@/feature-flags/flags-middleware";
import { initFlagsPermissionFlagsPermissionConfigFakeDexIndication } from "@/feature-flags/flags-permission-config-fake-dex-indication";
import { initFlagsPermissionFlagsPermissionConfigQuotaTicket } from "./flags-permission-config-quota-ticket";
import { initFlagsPermissionDexAssessment } from "./flags-permission-dex-assessment";
import { initFlagsGamificationRTC } from "./flag-gamification-rtc";
import { initFlagsPermissionOutgoingShipmentClearance } from "./flags-outgoing-shipment-clearance";
import { initFlagsPermissionOutgoingShipmentRelease } from "./flags-out-going-shipment-release";
import { initFlagsPermissionShipmentRates } from "./flags-permission-check-tariff";
import { initFlagsPermissionOnProcessstt } from "./flags-permission-on-process-stt";
import { initFlagsPermissionPaidUnpaid } from "./flags-paid-unpaid";
import { initFlagsPermissionShortland } from "./flags-permission-shortland";
import { initFlagsPermissionHandover } from "./flags-permission-handover";
import { initFlagsPermissionDelivery } from "./flags-permission-delivery";
import { initFlagsPermissionPodDex } from "./flags-pod-dex";
import { initFlagsPermissionDashboard } from "./flags-dashboard";
import { initFlagsPermissionSTTTracking } from "@/feature-flags/flags-stt-tracking";
import { initFlagsPermissionCargoTracking } from "@/feature-flags/flags-cargo-tracking";
import { initFlagsPermissionBalanceTransaction } from "./flags-permission-balance-transaction";
import { initFlagsPermissionShopify } from "./flags-shopify";
/* please do not add more flags in here, if you want to add more flags, please create a new folder with new namespace
in the feature-flags folder or add to the flags-misc-medusa folder
*/
export const flags = {
  feature_ppob_enabled: new Rox.Flag(false),
  flag_max_photo_for_weight_adjustment: new Rox.RoxNumber(0),
  feature_rtc_bagging_enabled: new Rox.Flag(false),
  feature_pdc_list_shipment: new Rox.RoxString(""),
  feature_show_hide_hub_list: new Rox.Flag(false),
  feature_api_version_dashboard_delivery_detail_courier_cod: new Rox.RoxString(
    "v2"
  ),
  feature_api_version_dashboard_delivery_summary_courier_cod: new Rox.RoxString(
    "v2"
  ),
  feature_show_product_onepack: new Rox.Flag(false),
  feature_dimension_plane_only: new Rox.Flag(false),
  feature_hide_dimension_koli: new Rox.Flag(false),
  cargo_config_flight_treshold_default_value: new Rox.RoxNumber(0),
  feature_is_booking_cargo_v4: new Rox.Flag(false),
  show_jumbopack_on_booking_page: new Rox.Flag(false),
  flag_default_path_genesis: new Rox.RoxString("/dashboard"),
  flag_console_select_hub: new Rox.Flag(true),
  permission_single_menu_cargo_enable: new Rox.Flag(false),
  flag_minimum_top_up_fee: new Rox.RoxNumber(0)
};

export const configurationFetchedHandler = (fetcherResults: any) => {
  //todo: add logic to handle the fetcher results
};

export async function initRollout() {
  const options: any = {
    configurationFetchedHandler: configurationFetchedHandler
  };

  if (process.env.VUE_APP_ROLLOUT_PROXY) {
    options.proxy = {
      protocol: "https",
      host: process.env.VUE_APP_ROLLOUT_PROXY
    };
  }

  // Register the flags with Rollout
  initFlagsPromoDiscount();
  initFlagsPermissionSaldo();
  initFlagsPermissionTopUpAndCashback();
  initFlagsDashboardCod();
  initFlagsDashboardDeliveryCOD();
  initFlagsPermissionCodBalance();
  initFlagsPermissionBalanceTransaction();
  initConfigBucketStorage();
  initFlagsPermissionCodReconcile();
  initFlagsPermissionTopUpManual();
  initFlagsPermissionTopUpApproval();
  initFlagsMedusa();
  initFlagsPermissionBulkUpload();
  initFlagsPermissionTopUpVerification();
  initFlagsPermissionBooking();
  initFlagsPermissionReport();
  initFlagsFeatureShipmentC1();
  initFlagsPermissionMiddleware();
  initFlagsPermissionFlagsPermissionConfigFakeDexIndication();
  initFlagsPermissionFlagsPermissionConfigQuotaTicket();
  initFlagsPermissionDexAssessment();
  initFlagsGamificationRTC();
  initFlagsPermissionOutgoingShipmentClearance();
  initFlagsPermissionOutgoingShipmentRelease();
  initFlagsPermissionShipmentRates();
  initFlagsPermissionOnProcessstt();
  initFlagsPermissionPaidUnpaid();
  initFlagsPermissionShortland();
  initFlagsPermissionHandover();
  initFlagsPermissionDelivery();
  initFlagsPermissionPodDex();
  initFlagsPermissionDashboard();
  initFlagsPermissionSTTTracking();
  initFlagsPermissionCargoTracking();
  initFlagsPermissionShopify();
  Rox.register("", flags);

  // Setup the Rollout key
  await Rox.setup(process.env.VUE_APP_ROLLOUT_KEY || "", options);

  // retrieving user email via a function
  Rox.setCustomStringProperty("email", function() {
    return AccountController.accountData.email;
  });
  // retrieving user role via a function
  Rox.setCustomStringProperty("role type", function() {
    return AccountController.accountData.role_type;
  });
  // retrieving user account type via a function
  Rox.setCustomStringProperty("account type", function() {
    return AccountController.accountData.account_type;
  });
  // retrieving user account type detail via a function
  Rox.setCustomStringProperty("account type detail", function() {
    return AccountController.accountData.account_type_detail.type;
  });
  // retrieving user parent or child via a function
  Rox.setCustomBooleanProperty("is account parent", function() {
    if (AccountController.accountData.isPosAccount) {
      return !AccountController.accountData.account_type_detail.posParentId;
    }
    return !AccountController.accountData.account_type_detail.parentId;
  });
  // retrieving user account location via a function
  Rox.setCustomStringProperty("account location", function() {
    return AccountController.accountData.account_location?.city_code;
  });
  // retrieving is user account pos able to cod
  Rox.setCustomBooleanProperty("is cod delivery", function() {
    return AccountController.accountData.account_type_detail.isCodDelivery;
  });
  Rox.setCustomStringProperty("randomize property", function() {
    return new Date().toLocaleString();
  });

  /*Rox.setCustomStringProperty("prefix stt detail", function() {
    return ShipmentBookingController.shipmentBookingDetail.sttShipmentId;
  });
  Rox.setCustomStringProperty("status stt detail", function() {
    return ShipmentBookingController.shipmentBookingDetail.sttLastStatus;
  });*/
  Rox.setCustomStringProperty("account country", function() {
    return AccountController.accountData.account_type_detail?.countryCode;
  });
}
