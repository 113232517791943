/* eslint-disable @typescript-eslint/camelcase */
import { ShipmentBookingApiRepository } from "@/app/infrastructures/repositories/api/ShipmentBookingApiRepository";
import {
  ShipmentBookingDetail,
  ShipmentBookingEntities,
  ShipmentPrefix,
  SttManualValidation
} from "@/domain/entities/ShipmentBooking";
import { injectable } from "tsyringe";
import { AddShipmentBookingRequestInterface } from "@/data/payload/contracts/ShipmentBookingRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { RequestListOnProcessStt } from "@/data/payload/api/OnProcessApiRequest";
import { RequestListBooking } from "@/data/payload/api/BookingRequest";

@injectable()
export class ShipmentBookingPresenter {
  private repository: ShipmentBookingApiRepository;

  constructor(repository: ShipmentBookingApiRepository) {
    this.repository = repository;
  }

  public getBookingList(
    params: RequestListBooking
  ): Promise<ShipmentBookingEntities> {
    return this.repository.getBookingList(params);
  }

  public getOnProcessSttList(
    params: RequestListOnProcessStt
  ): Promise<ShipmentBookingEntities> {
    return this.repository.getOnProcessSttList(params);
  }

  public getBookingDetail(
    id: number,
    isWithEligibility: boolean,
    isAuth: boolean
  ): Promise<ShipmentBookingDetail> {
    return this.repository.getBookingDetail(id, isWithEligibility, isAuth);
  }

  public async addShipmentBooking(
    payload: AddShipmentBookingRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.addBookingShipment(payload);
  }

  public async addInstantBooking(
    payload: AddShipmentBookingRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.addInstantBooking(payload);
  }

  public async validateSttManual(
    clientId: number,
    sttManual: string,
    bookingType: string,
    productType: string
  ): Promise<SttManualValidation> {
    return this.repository.validateSttManual(
      clientId,
      sttManual,
      bookingType,
      productType
    );
  }

  public async getListShipmentPrefix(): Promise<ShipmentPrefix[]> {
    return this.repository.getListShipmentPrefix();
  }
}
