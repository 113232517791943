import { EmbargoApiRepository } from "@/app/infrastructures/repositories/api/EmbargoApiRepository";
import { ChangeEmbargoRequestInterface } from "@/data/payload/contracts/EmbargoRequest";
import { EmbargoDetail, EmbargoEntities } from "@/domain/entities/Embargo";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { injectable } from "tsyringe";

@injectable()
export class EmbargoPresenter {
  private repository: EmbargoApiRepository;

  constructor(repository: EmbargoApiRepository) {
    this.repository = repository;
  }

  public getListEmbargo(
    page: number,
    limit: number,
    search: string,
    status: string
  ): Promise<EmbargoEntities> {
    return this.repository.getList(page, limit, search, status);
  }

  public getDetailEmbargo(id: number): Promise<EmbargoDetail> {
    return this.repository.getDetail(id);
  }

  public addEmbargo(
    payload: ChangeEmbargoRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.addEmbargo(payload);
  }

  public editEmbargo(
    payload: ChangeEmbargoRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.editEmbargo(payload);
  }

  public stopEmbargo(id: number): Promise<ResponsePayload> {
    return this.repository.stopEmbargo(id);
  }
}
