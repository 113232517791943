import ApiService from "@/app/infrastructures/services/ApiService";
import { BulkDataMapper } from "@/data/persistences/mappers/BulkDataMapper";
import { BulkRepositoryInterface } from "@/data/persistences/repositories/contracts/BulkRepositoryInterface";
import {
  BulkDetail,
  BulkEntities,
  VersionNameData
} from "@/domain/entities/Bulk";
import { Endpoints } from "@/app/infrastructures/misc/Endpoints";
import {
  BulkUploadRequestInterface,
  BulkUploadRetailPriceRequestInterface
} from "@/data/payload/contracts/BulkRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AccountController } from "@/app/ui/controllers/AccountController";

export class BulkApiRepository implements BulkRepositoryInterface {
  private service: ApiService;
  private mapper: BulkDataMapper;
  private endpoints: Endpoints;

  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }

  // get account type
  get isInternal() {
    return this.dataProfile.account_type === "internal";
  }

  constructor(
    service: ApiService,
    mapper: BulkDataMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getBulkList(
    activityName: string,
    archiveType: string,
    status: string,
    page: number,
    limit: number
  ): Promise<BulkEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListBulk(
        activityName,
        archiveType,
        status,
        page,
        limit
      ),
      {}
    );
    return this.mapper.convertBulkDataFromApi(resp);
  }

  public async bulkUpload(
    payload: BulkUploadRequestInterface
  ): Promise<ResponsePayload> {
    if (!this.isInternal && (payload as any).archive_type === "account") {
      const response = await this.service.invokePostWithFormData(
        "post",
        this.endpoints.bulkUpload(),
        {},
        payload
      );
      return this.mapper.convertBulkUploadDataFromApi(response);
    }
    const response = await this.service.invokePrivatePostWithFormData(
      "post",
      this.endpoints.bulkUpload(),
      {},
      payload
    );
    return this.mapper.convertBulkUploadDataFromApi(response);
  }

  public async getDetail(id: number): Promise<BulkDetail> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailBulk(id),
      {}
    );
    return this.mapper.convertDetailBulkDataFromApi(resp);
  }

  public async getVersionName(search: string): Promise<VersionNameData[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getVersionName(search),
      {}
    );
    return this.mapper.convertVersionNameDataFromApi(resp);
  }

  public async addBulkUploadRetailPrice(
    payload: BulkUploadRetailPriceRequestInterface
  ): Promise<ResponsePayload> {
    const response = await this.service.invokePrivatePostWithFormData(
      "post",
      this.endpoints.addBulkUploadRetailPrice(),
      {},
      payload
    );
    return this.mapper.convertBulkUploadDataFromApi(response);
  }
}
