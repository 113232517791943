import { ChangeEmbargoRequestInterface } from "@/data/payload/contracts/EmbargoRequest";
import { EmbargoDataMapper } from "@/data/persistences/mappers/EmbargoMapper";
import { EmbargoRepositoryInterface } from "@/data/persistences/repositories/contracts/EmbargoRepositoryInterface";
import { EmbargoDetail, EmbargoEntities } from "@/domain/entities/Embargo";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import {
  AddEmbargoApiRequest,
  EditEmbargoApiRequest
} from "@/data/payload/api/EmbargoApiRequest";

export class EmbargoApiRepository implements EmbargoRepositoryInterface {
  private service: ApiService;
  private mapper: EmbargoDataMapper;
  private endpoints: Endpoints;

  constructor(
    service: ApiService,
    mapper: EmbargoDataMapper,
    endpoints: Endpoints
  ) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }

  public async getList(
    page: number,
    limit: number,
    search: string,
    status: string
  ): Promise<EmbargoEntities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getEmbargo(page, limit, search, status),
      {}
    );
    return this.mapper.convertEmbargoFromApi(resp);
  }

  public async getDetail(id: number): Promise<EmbargoDetail> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getDetailEmbargo(id),
      {}
    );
    return this.mapper.convertDetailEmbargoDataFromApi(resp);
  }

  public async addEmbargo(
    payload: ChangeEmbargoRequestInterface
  ): Promise<ResponsePayload> {
    const response = await this.service.invoke(
      "post",
      this.endpoints.addEmbargo(),
      {},
      payload as AddEmbargoApiRequest
    );
    return this.mapper.convertChangeDataFromApi(response);
  }

  public async editEmbargo(
    payload: ChangeEmbargoRequestInterface
  ): Promise<ResponsePayload> {
    const response = await this.service.invoke(
      "patch",
      this.endpoints.editEmbargo(),
      {},
      payload as EditEmbargoApiRequest
    );
    return this.mapper.convertChangeDataFromApi(response);
  }

  public async stopEmbargo(id: number): Promise<ResponsePayload> {
    const response = await this.service.invoke(
      "post",
      this.endpoints.stopEmbargo(id),
      {}
    );
    return this.mapper.convertChangeDataFromApi(response);
  }
}
