import { Pagination } from "@/domain/entities/Pagination";

export class CityEntities {
  pagination: Pagination;
  cityData: Array<CityData>;
  constructor(pagination: Pagination, cityData: Array<CityData>) {
    this.pagination = pagination;
    this.cityData = cityData;
  }
}

export class CityData {
  id = 0;
  name = "";
  isActive = "";
  code = "";
  timezone = "";
  freeTradeZone = "";
  countryId = 0;
  originTransit = "";
  destinationTransit = "";
  createdBy = "";
  updatedBy = "";
  createdAt = "";
  updateAt = "";
  originCodeTransit? = "";
  destinationCodeTransit? = "";
  minimalSla? = 0;
  maximalSla? = 0;
  sundayDelivery? = "";
  codeNameCity = "";
  constructor(fields?: Partial<CityData>) {
    Object.assign(this, fields);
    this.codeNameCity = `${this.code} - ${this.name}`;
  }
}

export class CountryEntities {
  pagination: Pagination;
  countryData: Array<CountryData>;
  constructor(pagination: Pagination, countryData: Array<CountryData>) {
    this.pagination = pagination;
    this.countryData = countryData;
  }
}

export class CountryData {
  id: number;
  code: string;
  name: string;
  currency: string;
  status: string;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updateAt: string;
  constructor(
    id: number,
    code: string,
    name: string,
    currency: string,
    status: string,
    createdBy: string,
    updatedBy: string,
    createdAt: string,
    updateAt: string
  ) {
    this.id = id;
    this.code = code;
    this.name = name;
    this.currency = currency;
    this.status = status;
    this.createdBy = createdBy;
    this.updatedBy = updatedBy;
    this.createdAt = createdAt;
    this.updateAt = updateAt;
  }
}

export class DistrictEntities {
  pagination = new Pagination(1, 10);
  districtData: Array<DistrictData> = [];
  constructor(fields?: Partial<DistrictEntities>) {
    Object.assign(this, fields);
  }
}

export class DistrictData {
  id = 0;
  code = "";
  name = "";
  type = "";
  cityCode = "";
  cityId = 0;
  cityName = "";
  ursaCode = "";
  originZipCode = "";
  destinationZipCode = "";
  vendorCode = "";
  status = "";
  isCod = "";
  isPtPos = "";
  createdBy: undefined | string = undefined;
  updatedBy: undefined | string = undefined;
  createdAt: undefined | string = undefined;
  updateAt: undefined | string = undefined;
  isFtz = "";
  slaOrigin = 0;
  slaDestination = 0;
  minSlaOrigin = 0;
  maxSlaOrigin = 0;
  minSlaDestination = 0;
  maxSlaDestination = 0;
  originListZipCode: string[] = [];
  districtNameCityName = "";
  sddId = 0;
  countryCode = "";
  constructor(fields?: Partial<DistrictData>) {
    Object.assign(this, fields);
    this.districtNameCityName = `${this.name}, ${this.cityName}`;
  }
}

export class UrsaCodeData {
  code: number;
  message: string;
  constructor(code: number, message: string) {
    this.code = code;
    this.message = message;
  }
}

export class DistrictClient {
  code = "";
  id = "";
  isCod = "";
  name = "";
  cityName = "";
  cityCode = "";
  status = "";
  type = "";
  ursaCode = "";
  vendorCode = "";
  constructor(fields?: Partial<DistrictClient>) {
    Object.assign(this, fields);
  }
}

export class RegionEntities {
  pagination = new Pagination(1, 10);
  regionData: Array<RegionData> = [];
  constructor(fields?: Partial<RegionEntities>) {
    Object.assign(this, fields);
  }
}

export class RegionData {
  regionId = 0;
  regionCode = "";
  regionName = "";
  regionStatus = "";
  regionCreatedAt = "";
  regionUpdatedAt = "";
  regionCities = [] as Array<RegionCity>;
  constructor(fields?: Partial<RegionData>) {
    Object.assign(this, fields);
  }
}

export class RegionCity {
  regionCityId = 0;
  regionCityRegionCode = "";
  regionCityCityId = 0;
  regionCityStatus = "";
  regionCityCreatedAt = "";
  regionCityUpdatedAt = "";
  city = {
    id: 0,
    name: "",
    isActive: "",
    isSundayDelivery: "",
    code: "",
    timezone: "",
    freeTradeZone: "",
    countryId: 0,
    originTransit: "",
    destinationTransit: "",
    districtDefault: "",
    createdBy: 0,
    updatedBy: 0,
    createdAt: "",
    updatedAt: ""
  };
  constructor(fields?: Partial<RegionCity>) {
    Object.assign(this, fields);
  }
}
